
import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';

import useNavigation from '@/composables/navigation';

export default defineComponent({
    name: 'NavbarView',
    props: {
        centerLogo: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
        hideMenu: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
    },
    setup() {
        const { locale: currentLocale, t } = useI18n();
        const { toggleMenu } = useNavigation();

        return {
            currentLocale,
            t,
            toggleMenu,
        };
    },
});
