
import { defineComponent } from 'vue';

import Timeline from '@/components/Timeline.vue';

export default defineComponent({
    name: 'TimelinePage',
    components: {
        Timeline,
    },
});
