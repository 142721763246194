import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5e259902"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href", "xlink:href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    class: _normalizeClass(`icon icon-${_ctx.name}`)
  }, [
    _createElementVNode("use", {
      href: _ctx.svgUrl,
      "xlink:href": _ctx.svgUrl
    }, null, 8, _hoisted_1)
  ], 2))
}