import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mb-3 text-white" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_default_layout = _resolveComponent("default-layout")!

  return (_openBlock(), _createBlock(_component_default_layout, null, {
    default: _withCtx(() => [
      _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.t('about')), 1),
      _createElementVNode("p", {
        innerHTML: _ctx.t('about_text')
      }, null, 8, _hoisted_2)
    ]),
    _: 1
  }))
}