import { ref, Ref } from 'vue';

interface IReturnProps {
    modalName: Ref<string>;
    setModal: (name: string) => void;
}

const modalName = ref('' as string);

const useModal = (): IReturnProps => {
    const setModal = (name: string): void => {
        modalName.value = name;
    };

    return {
        setModal,
        modalName,
    };
};

export default useModal;
