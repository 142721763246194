export default {
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Houdijk"])},
  "about_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Houdijk Holland turns a hundred years old in 2022. On 1 April 1922, Wijnand Houdijk sr. establishes the 'Repair Facility and Machine Factory W. Houdijk' in Vlaardingen. In its early years, Houdijk mainly focuses on the repair of fishing vessels and machines. About twenty-five years after it was founded, the three sons of Wijnand sr. - Wijnand jr., Joop and Aad - join the family business. Under their leadership, Houdijk developed into an all-around machine factory, where a machine was built for every automation request. In the 1960s, the company took its first steps in biscuit handling and Houdijk's machines could be found all over Europe. <br><br>At the end of the 1980s, Bart Houdijk took over from his father Joop and specialized the company in the engineering of handling systems for manufacturers of biscuits and crackers from all over the world. One hundred years after its foundation, Houdijk Holland has grown into the worldwide market leader in biscuit handling systems. Despite this growth, Houdijk still cherishes the informal atmosphere of the family business. For more information, visit <br><br><a href='https://houdijk.com' target='_blank' rel='nofollow noreferrer noopener' class='underline'>Houdijk.com</a>"])},
  "back_to_timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to timeline"])},
  "back_to_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to start"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disclaimer"])},
  "disclaimer_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Legal information</strong><br>The Houdijk 100 website is owned by Houdijk Holland. The entire content of the Houdijk 100 website is subject to copyright with all rights reserved. You may download or print out a hard copy of individual pages and/or sections of the site, provided that you do not remove any copyright or other proprietary notices. Any downloading or otherwise copying from the site will not transfer title to any software or material to you. You may not reproduce (in whole or in part), transmit (by electronic means or otherwise), modify, link into, or use for any public or commercial purpose the site or its content without the prior written permission of Houdijk Holland. The information and opinions contained on the Houdijk Holland website are provided without any warranty of any kind, either expressed or implied. For questions and suggestions, please contact our webmaster <a href=\"mailto:info&#64;houdijk.com\">info&#64;houdijk.com</a>."])},
  "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by theme"])},
  "filtered_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Filtered by: ", _interpolate(_named("category"))])},
  "memories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memories"])},
  "memories_navigate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read employees memories"])},
  "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
  "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])},
  "privacy_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Houdijk Holland website does not collect any personal data. If you supply data to Houdijk Holland, they will be processed in accordance with the General Data Protection Regulation.<br>Houdijk Holland uses collected data only for the specific purposes for which you supply the data. Your data will be kept only for as long as necessary for the purpose for which they have been collected. Houdijk Holland will not make your data available to third parties unless there is a legal obligation to do so, unless they are processors working for Houdijk Holland, or before you have been specifically informed and have granted the appropriate consent when supplying your data. For further details, see the specification below. If you want to see what information we have about you, you can send us a written request to that effect. If some data prove not to be correct, we can correct them for you. You can also ask us to limit the processing of your data or to delete your data. You can request data withdrawal at any time."])},
  "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more"])},
  "scan_qrcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan the QR-code with your mobile phone to control the timeline."])},
  "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show"])},
  "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeline"])},
  "categories": [
    {
      "id": null,
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])}
    },
    {
      "id": 1,
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100 year family business"])}
    },
    {
      "id": 2,
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100 year on the shop floor"])}
    },
    {
      "id": 3,
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100 year innovations"])}
    },
    {
      "id": 4,
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100 year corporate direction"])}
    }
  ],
  "items": [
    {
      "id": 1,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/1.1.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The founding"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With a starting capital of 345 guilders, borrowed from his wife Johanna, Wijnand Houdijk founded the 'Machinefabriek en Reparatie-inrichting W. Houdijk' (Repair Facility and Machine Factory W. Houdijk) on 1 April 1922. Wijnand, born in 1890 in Oudewater, already had extensive experience as a technician and manager when he became his own boss at the age of 32. From apprentice fitter in a machine factory, he was promoted to workshop manager of steel construction company Hollandia. After working as a manager at shipyard 'Scheepswerf Van Dam' for some time, he decided to start his own business."])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1922"])},
      "category": 1,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/1.1.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The workers of the Repair Facility and Machine Factory W. Houdijk on the Maasstraat in Vlaardingen. From left to right: Daan Hartman, Wijnand Houdijk sr., Jan de Graaf, Jan Versteege, Henk Henneveld, Nardus Versteege and Willem van der Schee. 1922."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/1.1.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With a starting capital of 345 guilders, borrowed from his wife Johanna, Wijnand Houdijk founded the 'Machinefabriek en Reparatie-inrichting W. Houdijk' (Repair Facility and Machine Factory W. Houdijk) on 1 April 1922. Wijnand, born in 1890 in Oudewater, already had extensive experience as a technician and manager when he became his own boss at the age of 32. From apprentice fitter in a machine factory, he was promoted to workshop manager of steel construction company Hollandia. After working as a manager at shipyard 'Scheepswerf Van Dam' for some time, he decided to start his own business."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijnand set up a workshop in Jan Hoogendorp's cooperage in the Maasstraat in Vlaardingen. At 7 by 4 metres and with an attic for storage, it was not very spacious. Together with his first employee Jan Versteege - nicknamed Fat Jan - he installed several turning lathes and planing machines in the workshop. They were off to a great start. So much so, in fact, that after a week Wijnand also hired Jan's younger brother Bernardus Versteege, followed shortly afterwards by Willem van der Schee. They would remain committed to Houdijk for the rest of their working lives. Within a year, Wijnand managed to repay the loan to his wife."])}
        }
      ]
    },
    {
      "id": 2,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/2.1.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moving to the Van Leyden Gaelstraat"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Three years after its foundation, the Houdijk's machine factory was already growing out of its seams. In 1925, Wijnand Houdijk exchanged the workshop on the Maasstraat for a former coal storage with two floors on the 1st van Leyden Gaelstraat, a few hundred metres away."])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1925"])},
      "category": 3,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/2.1.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Three years after its foundation, the Houdijk's machine factory was already growing out of its seams. In 1925, Wijnand Houdijk exchanged the workshop on the Maasstraat for a former coal storage with two floors on the 1st van Leyden Gaelstraat, a few hundred metres away."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Machine Factory W. Houdijk on the 1st van Leyden Gaelstraat. 1930's."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/2.1.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the ground floor, Wijnand and his employees placed the turning lathes and planing machines, the plate mill and the forge. Upstairs were the offices, canteen and later the drawing office and kitchen. Steel sheets and pipes for the machines were stored in a shed in the backyard."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A turning lathe for the Machine Factory is delivered on a Ford TT. 1925."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/2.2.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In contrast to what today's zoning plans would allow, the same street also contained residential buildings at the time. According to former employees, the smoke and factory fumes could be smelled from a distance."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The forge on the ground floor of the Machine Factory. 1939"])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/2.3.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        }
      ]
    },
    {
      "id": 3,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/3.1.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good reputation in times of crisis"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When Wijnand started his machine factory in 1922, it was the fondant and confectionery manufacturer De Valk and the bakery Simon Niemandsverdriet from 1st van Leyden Gaelstraat who first knocked on his door for repair work. Shortly afterwards, fishermen from Scheveningen and Katwijk also knew where to find the repair services of 'Wijnand de Maasstraat', as the company was known among them. Whenever boatmen had a breakdown or a technical problem on their vessels, they contacted Wijnand and the Houdijk crew would go on board to carry out the repairs. Wijnand, however, realised that the fishing industry was slowly but surely moving out of Vlaardingen and he would have to look for other markets."])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ca. 1930"])},
      "category": 4,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/3.1.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When Wijnand started his machine factory in 1922, it was the fondant and confectionery manufacturer De Valk and the bakery Simon Niemandsverdriet from 1st van Leyden Gaelstraat who first knocked on his door for repair work. Shortly afterwards, fishermen from Scheveningen and Katwijk also knew where to find the repair services of 'Wijnand de Maasstraat', as the company was known among them. Whenever boatmen had a breakdown or a technical problem on their vessels, they contacted Wijnand and the Houdijk crew would go on board to carry out the repairs. Wijnand, however, realised that the fishing industry was slowly but surely moving out of Vlaardingen and he would have to look for other markets."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Houdijk's workers repairing a fishing vessel in the port of Vlaardingen. 1930's."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/3.1.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["That was no easy task. The American stock market crash of 1929 also caused an economic crisis in the Netherlands that lasted for years and resulted in hundreds of thousands of unemployed. Yet, Houdijk survived the turbulent 1930s virtually unscathed. This was first of all due to the efforts of the indefatigable Wijnand Houdijk, who for days on end visited various companies in and outside the region - right up to the shipyards in the northern provinces - to secure orders. He did this in the full conviction that a small customer could always become a big customer."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijnand Houdijk sr. in his office on the 1st van Leyden Gaelstraat. 1939."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/3.2.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The good reputation that the company had built up in ten years also helped. Especially in Vlaardingen he knew many people and conversely many people knew him. After Wijnand's invariably simple question 'whose are you', dexterous boys from Vlaardingen could immediately start work at Houdijk doing repairs or working on machines and automation. Joop Houdijk, middle son of Wijnand, later wrote in his memoirs: 'The company survived the crisis years relatively well, through hard and often long work and thanks to the excellent employees who put their heart and soul into the business.'"])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dexterous boys could almost immediately start work at the Machine Factory when they finished school. 1939."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/3.3.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        }
      ]
    },
    {
      "id": 4,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/4.1.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution-oriented: the hot-water boiler"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["That Houdijk's machine factory was an innovative company from the beginning is shown by the hot-water boiler for grocery shops, which Houdijk developed in the 1930s. In those years, few families had hot water at home. Precursors to the hot-water boiler and central-heating boiler already existed but were so expensive that the average Dutch household could not afford them. It was no different in Vlaardingen, but hot water was still needed for laundry, for example. Houdijk built a hot-water boiler, 3 metres in height and with a diameter of 1.5 metres, for several grocers in Vlaardingen. The shop's waste could be burned at the bottom of the boiler, so that the water tank above would heat up. On Sunday evenings, grocers would burn off their rubbish, so that early the following morning, housewives from Vlaardingen could buy kegs of hot water for a few pennies. This proved so successful that the municipality changed their local legislation regarding opening hours. Grocers were allowed to open their shops earlier on Mondays to sell not only hot water but also certain household items such as soap and scrubbing products."])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ca. 1935"])},
      "category": 2,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/4.1.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Houdijk placed large metal vats on the streets along the Oude Haven to advertise 'Firm Houdijk'. 1937."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/4.1.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["That Houdijk's machine factory was an innovative company from the beginning is shown by the hot-water boiler for grocery shops, which Houdijk developed in the 1930s. In those years, few families had hot water at home. Precursors to the hot-water boiler and central-heating boiler already existed but were so expensive that the average Dutch household could not afford them. It was no different in Vlaardingen, but hot water was still needed for laundry, for example. Houdijk built a hot-water boiler, 3 metres in height and with a diameter of 1.5 metres, for several grocers in Vlaardingen. The shop's waste could be burned at the bottom of the boiler, so that the water tank above would heat up. On Sunday evenings, grocers would burn off their rubbish, so that early the following morning, housewives from Vlaardingen could buy kegs of hot water for a few pennies. This proved so successful that the municipality changed their local legislation regarding opening hours. Grocers were allowed to open their shops earlier on Mondays to sell not only hot water but also certain household items such as soap and scrubbing products."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A welder working on a hot-water boiler for a grocer in Vlaardingen. 1939."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/4.2.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        }
      ]
    },
    {
      "id": 5,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/5.1.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Going into hiding and continuing work"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friday morning, 10 May 1940. Most inhabitants of Vlaardingen were still asleep when Hitler's Wehrmacht invaded their city. German bombers flattened the area around the Kortedijk in the north of Vlaardingen, while the villagers went out into the street or climbed on their roofs to see what was happening. On 14 May, after the centre of neighbouring Rotterdam was destroyed by bombing and the Dutch government capitulated under the threat of further violence, the German occupation of the Netherlands became a fact. The occupation had an immediate negative consequence for Houdijk. The repair work on fishing boats, which had provided them with a good income for almost twenty years, fell away completely. The Germans requisitioned almost all Dutch fishing vessels to use them for cargo traffic between Nazi Germany and Scandinavia."])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1940-1945"])},
      "category": 1,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/5.1.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friday morning, 10 May 1940. Most inhabitants of Vlaardingen were still asleep when Hitler's Wehrmacht invaded their city. German bombers flattened the area around the Kortedijk in the north of Vlaardingen, while the villagers went out into the street or climbed on their roofs to see what was happening. On 14 May, after the centre of neighbouring Rotterdam was destroyed by bombing and the Dutch government capitulated under the threat of further violence, the German occupation of the Netherlands became a fact. The occupation had an immediate negative consequence for Houdijk. The repair work on fishing boats, which had provided them with a good income for almost twenty years, fell away completely. The Germans requisitioned almost all Dutch fishing vessels to use them for cargo traffic between Nazi Germany and Scandinavia."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Korte Hoogstraat behind the Kortedijk lies in rubble as a result of the German bombardment. 1940.<br> Source: photo collection Vlaardingen City Archives"])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/5.1.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Although Houdijk's machine factory continued to operate, things were not easy. The Houdijk family experienced very tense times. Wijnand's daughter, Nelly Houdijk, was active in the resistance. Despite the fact that this was punishable by death, she helped not only Jewish families to go into hiding, but also other people in need to find a safe haven. Wijnand's brother Adrianus, however, was a member of the NSB (Dutch Nazi party). Wijnand himself strongly disliked the Nazis and the persecution of Jews, but even more so the NSB members, all of whom he saw as traitors."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secretary Nettie Sterk, Aad Houdijk and Nelly Houdijk in a shed behind the Machine Factory. 1939."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/5.2.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nevertheless, Wijnand could not escape working for the occupying forces. He did this in exchange for so-called 'Ausweisen', work permits stating where and when someone worked. During the first years of the war, Wijnand used these permits to avoid having his staff deported to Germany to be put to work there. But in 1943, there were many changes for Houdijk's machine factory. The Germans demanded that the machines and tools be transported to Germany, but Wijnand Houdijk managed to avoid that by hiding them in a local ice factory. However, Houdijk and his employees were less fortunate when Germany experienced an increasing shortage of workers. Despite the 'Ausweisen', the Germans compelled several of Houdijk's men to work in Germany."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One of those men was Wijnand's son: Joop Houdijk. The family did everything they could to prevent him from being sent to Germany. One day in May 1943, Joop was cycling to the machine factory to visit his father when suddenly an older man pushed a note into his hands. The note contained an address and instructions to go there immediately. Without being able to say goodbye, Joop left for the Sarijnenhove, a farm in the countryside west of Vlaardingen, where he worked as a farmhand until the end of the war. 'I loved working on the farm, but I was always in fear of being caught. As a person in hiding, you lived under constant pressure,' Joop wrote in his memoirs."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German soldiers posing near the Westhavenkade in occupied Vlaardingen. 1940. Source: photo collection Jan Anderson."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/5.3.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The last months of the war were difficult for Houdijk and his machine factory. Wijnand's son was in hiding, a few employees had been sent to work in Germany and much of the equipment was hidden. Wijnand hired inexperienced workers from Zeeland to make gas meters and heaters, while he himself worked outside making repairs in exchange for food. On 4 May 1945, the German army command in the Netherlands capitulated. The employees who had been put to work in Germany returned and the workbenches, machines and other equipment were put back in place. The post-war reconstruction could begin."])}
        }
      ]
    },
    {
      "id": 6,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/6.1.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The arrival of the three brothers"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After the end of the Second World War, Wijnand picked up the pieces and went firmly ahead. He invested in steam cooperage and drum presses and employed a construction draughtsman. His oldest son, Wijnand junior, joined the company, followed by his youngest, Aad, in 1947 and his middle son, Joop, in 1950. The three brothers each took responsibility for a different part of the business. Joop focused on the technical and commercial side, Aad did the bookkeeping and Wijnand junior managed the workshop and maintained contacts with local companies. Old hand Wim van der Schee: 'Joop did not mince his words and was quite courageous. Wijnand was the techie of the family and was happy for Joop to take care of the human side. Aad, who suffered from asthma, was not often to be found on the factory floor.' Their sister Nelly also worked in the family business until 1946, but she tragically died at a young age due to heart valve disease."])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1947"])},
      "category": 1,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/6.1.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A group photo taken in celebration of Machine Factory W. Houdijk's 25th anniversary. 1947."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/6.1.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After the end of the Second World War, Wijnand picked up the pieces and went firmly ahead. He invested in steam cooperage and drum presses and employed a construction draughtsman. His oldest son, Wijnand junior, joined the company, followed by his youngest, Aad, in 1947 and his middle son, Joop, in 1950. The three brothers each took responsibility for a different part of the business. Joop focused on the technical and commercial side, Aad did the bookkeeping and Wijnand junior managed the workshop and maintained contacts with local companies. Old hand Wim van der Schee: 'Joop did not mince his words and was quite courageous. Wijnand was the techie of the family and was happy for Joop to take care of the human side. Aad, who suffered from asthma, was not often to be found on the factory floor.' Their sister Nelly also worked in the family business until 1946, but she tragically died at a young age due to heart valve disease."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Despite his sons joining the company, Wijnand sr. kept on working in the family business. 1954."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/6.2.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It soon became clear to Houdijk's employees that the three brothers would be taking over from their father, but they were not given command of the company lightly. Van der Schee: 'He didn't just hand it to them. They really had to work hard for it. They had to join in as helpers, for example, no matter whether the work was dirty or not. They had to put up with that.' This approach worked as a good learning opportunity for their mutual cooperation. 'As long as we worked together, we never had any disagreements, not even with father,' Joop wrote. Wijnand senior continued to work in the family business together with his three sons until the 1960s. Health problems forced Wijnand junior to retire early in 1980 and shortly afterwards Aad also left the company. Of the three brothers, only Joop remained until 1987, when he, too, had to leave the family business due to health problems."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Houdijk family on a wedding anniversary. The three Houdijk brothers are standing. From left to right: Aad, Joop and Wijnand jr. 1960's."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/6.3.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        }
      ]
    },
    {
      "id": 7,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/7.3.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extending the factory"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the years following the Second World War, it was time to significantly expand the machine factory on the 1st van Leyden Gaelstraat. The municipality granted permission to roof the alley next to the factory and this was followed by the purchase of two adjacent houses that were converted into assembly halls."])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1955"])},
      "category": 3,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/7.3.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Machine Factory's premises on the 1st van Leyden Gaelstraat. Early 1950's."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/7.1.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the years following the Second World War, it was time to significantly expand the machine factory on the 1st van Leyden Gaelstraat. The municipality granted permission to roof the alley next to the factory and this was followed by the purchase of two adjacent houses that were converted into assembly halls."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Machine Factory expanded with the alleyway and two houses next to it. 1954."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/7.2.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Despite this newly acquired area, the expansions soon proved to be insufficient. Paint factory 'Eerste Vlaardingse Verffabriek', which was located just next door to the machine factory, was regularly plagued by fires. In 1955, another fire broke out and 'while the paint factory was still burning, the whole business was sold to Houdijk', according to a journalist from local daily Vlaardings Dagblad. Not long afterwards, a hall of the 'Verenigde Touwfabrieken' behind Houdijk's machine factory was also taken over. The company's working area had been doubled in a short time and it was ready for the next decades."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Machine Factory's working area doubled after its takeover of the paint factory. 1957."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/7.3.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Many former Houdijk employees still remember the premises on 1st van Leyden Gaelstraat very well. Jaap van Ree: 'When I started work there in 1950, it was just five years after the Second World War. I didn't have much, apart from a folding ruler and a stool that I had made myself and where I sat on when eating. There was a stove in the back to warm us, but it was still very cold in winter.' Arie-Hans Houdijk, Joop's eldest son, remembered the smells of his holiday job in the factory. 'The smells of oil and lubricants, of burnt iron from welding, milling and drilling. It was a very specific and recognisable smell. It was dirty, rough work that was done there. Men with dirty hands and overalls that looked black with oil and grease.'"])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After the Second World War, Houdijk's machines were running on full capacity again. 1950's."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/7.4.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        }
      ]
    },
    {
      "id": 8,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/8.1.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First step in biscuit handling: the Telinmatic"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the late 1950s, Houdijk frequently visited Hooimeijer, a manufacturer of Dutch rusks, in Barendrecht for repair work on their machines. In 1960, Hooimeijer knocked on the door with a different request. They had been thinking for some time about a kind of shuffleboard that would automatically sort their rusks when they came out of the oven ready to be packed. They asked Houdijk to develop this concept further and turn it into reality. Houdijk's design department, headed by Arie Gog, went to work on it and came up with the Telinmatic, a machine that gently blew the brittle rusks upwards and so guided them in the right direction. The Telinmatic proved to be a resounding success."])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1962"])},
      "category": 2,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/8.1.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Telinmatic on Hooimeijer's factory floor still working reliably after thirty years. 1994."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/8.1.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n the late 1950s, Houdijk frequently visited Hooimeijer, a manufacturer of Dutch rusks, in Barendrecht for repair work on their machines. In 1960, Hooimeijer knocked on the door with a different request. They had been thinking for some time about a kind of shuffleboard that would automatically sort their rusks when they came out of the oven ready to be packed. They asked Houdijk to develop this concept further and turn it into reality. Houdijk's design department, headed by Arie Gog, went to work on it and came up with the Telinmatic, a machine that gently blew the brittle rusks upwards and so guided them in the right direction. The Telinmatic proved to be a resounding success."])}
        },
        {
          "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een Telinmatic op de fabrieksvloer van Hooimeijer deed na dertig jaar nog trouw dienst. 1994"])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["video"])},
          "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/zPD39MIawL8"])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soon, Houdijk also sold this machine to rusk manufacturers throughout the Netherlands and even across the border in Belgium and Germany. These rusk manufacturers often also produced biscuits and this led to a demand for similar machines for, for instance, sandwich biscuits, Pims or Jaffa Cakes and crackers. Houdijk was also able to meet that demand because of their solution-focused approach. The Telinmatic remained one of Houdijk's best-selling machines for decades."])}
        },
        {
          "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Houdijk advertised its biscuit handling machine on the Intepack fair in Germany. 1990's."])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["video"])},
          "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/4ox17zTHrrQ"])}
        }
      ]
    },
    {
      "id": 9,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/9.2.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“A machine for everyone”"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the 1960s and 1970s, Houdijk's orders became increasingly complex. Straightforward automations were a thing of the past and Houdijk's creativity was increasingly put to the test. As was the case with the Telinmatic, customers came to Houdijk with a request for diverse and innovative machines and installations. Former draughtsman Koos Kesseboom has fond memories of this: 'It always concerned a modification or something new. These were great and very interesting times.' For example, the machine factory produced a system that allowed jars of hot-poured jam to cool in a controlled manner before further processing, but they also made presses that tested the quality of concrete paving stones. Together with a large Dutch cheesemaker, Houdijk developed a machine that automatically placed cheeses on shelves and turned them over periodically. Houdijk thus developed a machine for every request they received."])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ca. 1970"])},
      "category": 3,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/9.2.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["video"])},
          "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" In the Machine Factory, Houdijk's employees performed varied technical work. 1960's."])},
          "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/D-3ywLbwCe8"])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the 1960s and 1970s, Houdijk's orders became increasingly complex. Straightforward automations were a thing of the past and Houdijk's creativity was increasingly put to the test. As was the case with the Telinmatic, customers came to Houdijk with a request for diverse and innovative machines and installations. Former draughtsman Koos Kesseboom has fond memories of this: 'It always concerned a modification or something new. These were great and very interesting times.' For example, the machine factory produced a system that allowed jars of hot-poured jam to cool in a controlled manner before further processing, but they also made presses that tested the quality of concrete paving stones. Together with a large Dutch cheesemaker, Houdijk developed a machine that automatically placed cheeses on shelves and turned them over periodically. Houdijk thus developed a machine for every request they received."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Houdijk made a machine that cooled jars of jam down in a controlled manner. 1960's."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/9.2.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almost as popular as the Telinmatic were the transport systems which Houdijk made for cleaning untreated metal constructions in blast cabinets. This used to be done with sand, but the Dutch government banned this in 1960. The abrasive had to be reusable, so metalworkers switched to steel granules. In cooperation with Rotterdam-based company Van Kranenburg, Houdijk built a machine that collected the steel granules and cleaned them so they could be reused. When other European countries later also banned sandblasting, Houdijk had a big advantage over foreign competitors and could install their transport machines in other European countries."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In cooperation with Van Kranenburg, Houdijk developed a system that recycled steel granules. 1970's."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/9.3.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        }
      ]
    },
    {
      "id": 10,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/10.1.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkade: a long-term relationship"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With machines such as the Telinmatic and many others shipped from the machine factory in Vlaardingen to all over Europe, Houdijk's reputation for professionalism and a solution-focused approach was growing. When Royal Verkade (Dutch: Koninklijke Verkade), at that time one of the largest Dutch biscuit producers and also a family business, came up with a new type of oval biscuit, there were problems with the transport between their ovens and the packaging machine of Swiss company SIG. Because of their existing relationship and Houdijk's good reputation, SIG called in the help of Houdijk, in consultation with Verkade. Gerard Langver, then Verkade's project manager, recalled: 'There was a lot of back-and-forth engineering, machines were modified, a real headache. Because of the way Houdijk dealt with this with SIG, it all turned out well.'"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1976"])},
      "category": 4,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/10.1.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A worker of Verkade oversees Maria biscuits on a transport line made by Houdijk. 1970's."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/10.1.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With machines such as the Telinmatic and many others shipped from the machine factory in Vlaardingen to all over Europe, Houdijk's reputation for professionalism and a solution-focused approach was growing. When Royal Verkade (Dutch: Koninklijke Verkade), at that time one of the largest Dutch biscuit producers and also a family business, came up with a new type of oval biscuit, there were problems with the transport between their ovens and the packaging machine of Swiss company SIG. Because of their existing relationship and Houdijk's good reputation, SIG called in the help of Houdijk, in consultation with Verkade. Gerard Langver, then Verkade's project manager, recalled: 'There was a lot of back-and-forth engineering, machines were modified, a real headache. Because of the way Houdijk dealt with this with SIG, it all turned out well.'"])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The control panel with which Verkade's workers operated Houdijk's transport line tot SIG's packaging machine. 1970's."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/10.2.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Following this successful first cooperation, Houdijk soon became a regular supplier of machines for Verkade. Besides the Houdijk employees who did all the engineering and assembly, Houdijk's maintenance engineers were also regulars at Verkade. Langver: 'Houdijk's people often came to us. Tom Olsthoorn, for example, was there so often that he knew his way all around the factory. Many people thought he actually worked for Verkade.' Tom Olsthoorn confirmed his special relationship with Verkade: 'I have been coming to Verkade for a very long time, about forty-five years. My first job was to assemble and commission a production line for tealights, because in those days Verkade still made wax tea lights.' Verkade reasoned that the tea people drank with their biscuits had to stay warm and that was achieved with tea lights."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Although Verkade has become part of international holdings over the years, the relationship with Houdijk is still a good one. When the Verkade family opened a historical exhibition about their own factory in Zaandam in 2018, Houdijk also contributed. Verkade asked Houdijk to build a machine that delivered chocolate bars with a separate wrapper, so that children who visited the exhibition could colour their own wrappers and take them home."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Houdijk's machine wraps a bar of Verkade chocolate with a self-designed wrapping. 2018."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/10.3.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        }
      ]
    },
    {
      "id": 11,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/11.2.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moving to the Trawlerweg"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Although the machine factory had already been expanded considerably in the 1950s, business was so good in the following two decades that Houdijk made plans for further expansion. In 1979, the Houdijk brothers set their sights on buying up another part of the ropeyard behind the factory and another five houses that were located around it. The demolition and building permits were almost ready, but a change in the zoning plan meant that the expansion could not go ahead after all. The municipality of Vlaardingen had decided to separate industrial and residential areas. The plan for the expansion of the machine factory did not suit this plan and the factory was in danger of being shut down."])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1980"])},
      "category": 3,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/11.2.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Machine Factory couldn't expand on the 1st van Leyden Gaelstraat anymore. 1970's."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/11.1.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Although the machine factory had already been expanded considerably in the 1950s, business was so good in the following two decades that Houdijk made plans for further expansion. In 1979, the Houdijk brothers set their sights on buying up another part of the ropeyard behind the factory and another five houses that were located around it. The demolition and building permits were almost ready, but a change in the zoning plan meant that the expansion could not go ahead after all. The municipality of Vlaardingen had decided to separate industrial and residential areas. The plan for the expansion of the machine factory did not suit this plan and the factory was in danger of being shut down."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijnand junior, Joop and Aad were faced with the choice of either liquidating the family business or making a very costly investment in a new building. The first option would mean that the fifty or so employees, most of whom had worked for Houdijk for virtually their entire working lives, would be out on the street. The brothers unanimously decided on the second option."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joop Houdijk overseeing building progress in the middle of construction. 1979."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/11.2.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In 1980, the machine factory moved to newly built premises of 2,800 square metres at Trawlerweg 4. The machine factory was the first building on the industrial estate 'Het Scheur'."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Machine Factory Houdijk on the Trawlerweg 4 was the first building on 'Het Scheur'. 1980."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/11.3.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Both the brothers and their employees had to get used to the new building. Aad Houdijk explained: 'We were quite isolated there. In the old building, you saw each other all day long, but here the offices are separate.' Still, they were convinced that the new location promised well for the future. Joop: 'In the new location, Houdijk will have the opportunity to develop better, because the new building is much better geared to the company than the old premises.'"])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The rooms in the new building were better geared to the work that was done. 1980's."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/11.4.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        }
      ]
    },
    {
      "id": 12,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/12.1.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applied innovation: the Page Turner"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Although the internationally successful Telinmatic took up a large part of Houdijk's output, there was also room for the development of other types of machines. One of these was the so-called Page Turner, a machine with a special story. In 1979, Wijnand junior, Joop and Aad visited the 'Zonnehuis' nursing and care home in Vlaardingen. There they noticed that many residents had difficulty reading books because they could not turn the pages properly on their own. An employee of Zonnehuis introduced the brothers to someone who had made a wooden page turner for his wife. Inspired by this device, the brothers joined forces to improve reading devices for severely disabled people."])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1980"])},
      "category": 2,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/12.1.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Although the internationally successful Telinmatic took up a large part of Houdijk's output, there was also room for the development of other types of machines. One of these was the so-called Page Turner, a machine with a special story. In 1979, Wijnand junior, Joop and Aad visited the 'Zonnehuis' nursing and care home in Vlaardingen. There they noticed that many residents had difficulty reading books because they could not turn the pages properly on their own. An employee of Zonnehuis introduced the brothers to someone who had made a wooden page turner for his wife. Inspired by this device, the brothers joined forces to improve reading devices for severely disabled people."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draughtsmen designed Houdijk's innovative machine down to the details. 1980's."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/12.1.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After a year of experimenting with various prototypes, the finished device, capable of turning pages, was delivered and named the 'Page Turner'. At the slightest command of a hand, foot, knee or even a slight breath - tailored to individual personal requirements - the device responded and turned a page of a book or magazine. Arie Gog, head of the drawing office at the machine factory, was responsible for the technical design of the machine. Gog's innovative device made an impression. Dutch daily newspaper Het Vrije Volk wrote: 'With the patience of a saint, he allowed the new ideas for the page turning device to evolve into the final product through research and experiments.' The proceeds did not go to Houdijk. A foundation was set up to invest the money in the development of new technology for the target group."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The easy to operate Page Turner can turn a page of a book. A clear sheet prevents the pages from falling shut again. 1980."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/12.2.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        }
      ]
    },
    {
      "id": 13,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/13.1.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In trouble"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["While the machine factory survived the Great Depression of the 1930s virtually unscathed, the situation was different during the economic crisis of the early 1980s. This crisis was triggered by a coup in oil producer Iran in 1978. About seven per cent of the world's oil production stopped overnight. As a result of this shortage, a year later other oil producing countries raised their prices almost threefold. This was an unmitigated disaster for industrial companies all over the world."])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1985"])},
      "category": 4,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/13.1.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["While the machine factory survived the Great Depression of the 1930s virtually unscathed, the situation was different during the economic crisis of the early 1980s. This crisis was triggered by a coup in oil producer Iran in 1978. About seven per cent of the world's oil production stopped overnight. As a result of this shortage, a year later other oil producing countries raised their prices almost threefold. This was an unmitigated disaster for industrial companies all over the world."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By taking on smaller jobs and repair work, Houdijk's could keep its employees working. 1980's."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/13.1.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This was also the case for Houdijk, which needed oil both for production and to keep the machines running. The rise in oil prices meant that it became more expensive for Houdijk to build machines. At the same time, the companies that Houdijk supplied were also spending more money on their own production processes. There was, therefore, little room for investment and the purchase of new machines, and consumer confidence plummeted. The machine factory saw its order numbers fall sharply and experienced financially very difficult times. Several times Houdijk was on the verge of bankruptcy and it came down to the right timing of payments by the few customers in this period. It would take years before the family business, by then almost seventy years old, would pull itself permanently out of the slump."])}
        }
      ]
    },
    {
      "id": 14,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/14.1.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Led by Bart Houdijk, third generation"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After having been at the helm of the company for about forty years, it was time for the second generation to hand the business on to the next. As the son of Joop, Bart Houdijk was already well acquainted with the family business when he took charge in 1987. From the age of fourteen, he regularly accompanied his father on business trips during school holidays. He also went along with the field technicians to do jobs, including with Technical Supervisor Rinus van Driel: 'Together with Tom Olsthoorn, I had a job at Hooimeijer and we needed someone for a rusks production line. They said they had a holiday worker to help us. So that's how I got Bart. The poor boy spent a week under the machine drilling holes. He did it without grumbling, I'll give him that. Every now and then we had to get him out from under the machine to drink a cup of coffee and after that he would carry on drilling.'"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1987"])},
      "category": 1,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/14.1.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portraits of the first and second generation Houdijks hang in Bart's office. 2018."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/14.1.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After having been at the helm of the company for about forty years, it was time for the second generation to hand the business on to the next. As the son of Joop, Bart Houdijk was already well acquainted with the family business when he took charge in 1987. From the age of fourteen, he regularly accompanied his father on business trips during school holidays. He also went along with the field technicians to do jobs, including with Technical Supervisor Rinus van Driel: 'Together with Tom Olsthoorn, I had a job at Hooimeijer and we needed someone for a rusks production line. They said they had a holiday worker to help us. So that's how I got Bart. The poor boy spent a week under the machine drilling holes. He did it without grumbling, I'll give him that. Every now and then we had to get him out from under the machine to drink a cup of coffee and after that he would carry on drilling.'"])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Later, after completing his Law studies at Erasmus University Rotterdam, Bart was offered the choice of following in the footsteps of his father and uncles. The machine factory was in dire financial straits. It was a challenge Bart was willing to take on, but it was not easy for the then twenty-six-year-old managing director: 'My father found it very hard to accept that he could no longer work and had to leave the business to me in such a difficult time.' During the first two years, Nol van Haase, former director of Rotterdamsche Droogdok Maatschappij (RDM), a major shipbuilding and repair company, supported him every day with sound advice. Under Bart's leadership, the company overcame the financial problems of the 1980s and Houdijk eventually developed into a global player in the field of biscuit feeding."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just like his grandfather, father and uncles, Bart Houdijk is known as an involved and sociable employer. 2018."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/14.2.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        }
      ]
    },
    {
      "id": 15,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/15.1.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From machine factory to engineering company"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Houdijk's machine factory had been in dire straits since the 1980s. To prevent the now three-generation family business from going bankrupt, Bart had to make several necessary decisions. Houdijk Holland, as the company was now called, would focus entirely on the engineering of biscuit feeding machines. Since the 1960s, this had been one of Houdijk's main sources of income, and for Bart the main reason to focus on this for the future."])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ca. 1995"])},
      "category": 4,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/15.1.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The international success Houdijk had with its biscuit handling machines formed the basis of its later specialisation. 1980's."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/15.1.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Houdijk's machine factory had been in dire straits since the 1980s. To prevent the now three-generation family business from going bankrupt, Bart had to make several necessary decisions. Houdijk Holland, as the company was now called, would focus entirely on the engineering of biscuit feeding machines. Since the 1960s, this had been one of Houdijk's main sources of income, and for Bart the main reason to focus on this for the future."])}
        },
        {
          "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Houdijk's traditional manual work was increasingly supplemented with digital tools. 1990's."])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["video"])},
          "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/6IOkgsB_Rk0"])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The new objective also meant that all activities in the field of repair work and machine manufacturing were discontinued. He outsourced the manufacture of machine parts to various suppliers. Bart Houdijk: 'I didn't really have any work left for the mechanics, bench fitters and sheet metal workers. I ended up reorganising Houdijk three times. At first, I had to lay off twenty people, then thirty and in the last reorganisation in 2003, another ten. Eventually, there were only thirty-five of us. Ten to fifteen in the engineering department, ten in the workshop and the rest in sales and administration. I look back on it with melancholy.' Employees who had been with the company for a long time were saddened by the redundancies. Among them was Annette Dunweg, who works for Houdijk since 1978: 'It's not nice to see your colleagues leave. It's actually very sad. Fortunately, things became better after that. Many colleagues who had been made redundant also came returned. That also says something.'"])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Although the forced dismissal of old hands was difficult, Bart's vision for the continued existence of Houdijk turned out to be a good one. As a result of the reorganisations, Houdijk recovered and more work was generated. Since the last reorganisation in 2003, Houdijk Holland's activities have been limited to the development, final assembly, testing and commissioning of machines for transporting, sorting, stacking and post-processing of biscuits and crackers from the oven to the packaging machine. Apart from rehiring former employees, Bart also hired technicians with a university background in Computer Science, Engineering, Industrial Design, Electrical Engineering and Mechanical Engineering. It underlined the conversion of a manufacturing company into an engineering company."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today, Houdijk's machines are designed fully digitally. 2020."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/15.3.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        }
      ]
    },
    {
      "id": 16,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/16.1.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Gradomatic"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whereas orders came in very slowly a decade earlier, things went well for Houdijk Holland from the mid-1990s onwards. The demand for handling systems for biscuits and crackers was high again and manufacturers from all corners of the world knew where to find Houdijk Holland. This was a good time to introduce a new Houdijk machine to the market: the Gradomatic. By placing a series of conveyor belts at a certain angle, it was possible to sort products from the ovens for transport to packaging machines without much resistance. 'The Gradomatic was an improvement in many ways. The Telinmatic was a machine where biscuits floated on air. That's of course wonderful for distributing the biscuits, but the disadvantage was that the crumbs were often up to the ceiling,' Tom Olsthoorn explained."])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ca. 1995"])},
      "category": 2,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/16.1.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whereas orders came in very slowly a decade earlier, things went well for Houdijk Holland from the mid-1990s onwards. The demand for handling systems for biscuits and crackers was high again and manufacturers from all corners of the world knew where to find Houdijk Holland. This was a good time to introduce a new Houdijk machine to the market: the Gradomatic. By placing a series of conveyor belts at a certain angle, it was possible to sort products from the ovens for transport to packaging machines without much resistance. 'The Gradomatic was an improvement in many ways. The Telinmatic was a machine where biscuits floated on air. That's of course wonderful for distributing the biscuits, but the disadvantage was that the crumbs were often up to the ceiling,' Tom Olsthoorn explained."])}
        },
        {
          "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Gradomatic prevents products from breaking and solves the Telinmatic's problem of crumbs flying around. 1997."])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["video"])},
          "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/wAXKSQcqFnE"])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Gradomatic fitted in well with the growing demand for machines that worked reliably and efficiently at high volumes and speed. Together with other Houdijk machines, the Gradomatic is part of a larger production line: the Flowmaster. Senior Service Engineer Edward van Oostende: 'With the Flowmaster we can determine for each Gradomatic how many biscuits we take from the supply belt. With the latest model, we assess by camera whether the quality is good and tap the biscuits with a paddle onto the dosing belt.' With the introduction of this machine line, Houdijk Holland confirmed their position as market leader in biscuit feeding."])}
        },
        {
          "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Houdijk's innovative power enables it to continually improve upon its machines. 2017."])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["video"])},
          "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/_9wlBKiJE7g"])}
        }
      ]
    },
    {
      "id": 17,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/17.1.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start of cooperation with SNL Group"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The specialisation that Bart brought to the activities of Houdijk Holland meant that he had to find suppliers for the manufacture of their machines. During one of the outsourcing rounds, Frans Lammers of SNL Group Schijndel came into the picture. The first few simpler projects went well and SNL soon became a regular supplier for Houdijk's transport systems. 'The engineering, sales and after sales are done by Houdijk. We do the production, but we also think along with them. We evaluate frequently and think about the manufacturability. How can it be done simpler and how can the lead time be shortened? We have regular discussions with the buyers and engineers about this. Of course, we do this work for several customers, but I really have a special relationship with Houdijk,' Frans explained."])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1998"])},
      "category": 4,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/17.1.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The specialisation that Bart brought to the activities of Houdijk Holland meant that he had to find suppliers for the manufacture of their machines. During one of the outsourcing rounds, Frans Lammers of SNL Group Schijndel came into the picture. The first few simpler projects went well and SNL soon became a regular supplier for Houdijk's transport systems. 'The engineering, sales and after sales are done by Houdijk. We do the production, but we also think along with them. We evaluate frequently and think about the manufacturability. How can it be done simpler and how can the lead time be shortened? We have regular discussions with the buyers and engineers about this. Of course, we do this work for several customers, but I really have a special relationship with Houdijk,' Frans explained."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ard Smits and Frans Lammers founded SNL Group Schijndel. 2000's."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/17.1.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apart from doing good business, the special relationship with Houdijk also translated into mutual contacts between Bart and Frans. 'Bart sometimes rang up at five o'clock in the morning. Then he would get on a plane somewhere, in Mexico or Brazil, and tell me that we were going to have a great project together.' What Lammers valued most about working with Houdijk is that it is a real family business and stands for continuity and quality. The appreciation is entirely mutual because SNL is still a regular supplier of Houdijk Holland."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SNL Group Schijndel is Houdijk's regular supplier for metal and machine work. 2010's."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/17.2.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        }
      ]
    },
    {
      "id": 18,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/18.1.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In search of space"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Part of the reorganisation needed to get Houdijk back on its feet was to cut spending on real estate. To secure sufficient funds for further specialisation, Bart sold the premises that Wijnand junior, Joop and Aad had built at Trawlerweg 4 and subsequently rented them on more favourable terms. When the difficult period was over and Bart's reorganisations began to bear fruit, he bought the premises back."])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ca 2000"])},
      "category": 3,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/18.1.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Part of the reorganisation needed to get Houdijk back on its feet was to cut spending on real estate. To secure sufficient funds for further specialisation, Bart sold the premises that Wijnand junior, Joop and Aad had built at Trawlerweg 4 and subsequently rented them on more favourable terms. When the difficult period was over and Bart's reorganisations began to bear fruit, he bought the premises back."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When Houdijk started to grow again, Trawlerweg 4 soon proved to be too small. However, there was no room for expansion in Vlaardingen at that time. A temporary solution was found in Rotterdam, where external business premises could be rented. After a few years, however, it turned out that this was not tenable. Because of the spread of operations between two locations, Houdijk needlessly wasted time in transportation. The search for a permanent expansion of the business premises continued."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Houdijk needlessly wasted time on transporting people, materials and machines back and forth. 2000's."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/18.1.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        }
      ]
    },
    {
      "id": 19,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/19.2.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trawlerweg expansion"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Although Houdijk could not meet the need for more floor space in the 2000s, a new opportunity presented itself in 2015. The premises immediately next door to Houdijk, at Trawlerweg 2, housed a printing company which had to close its doors as a result of emerging digitisation. The premises were transferred to Houdijk in 2015 and the company renovated them completely. Since then, Houdijk Holland has been located at Trawlerweg 2-4. 'That was necessary because of the growth, but when you look at what we really need in terms of surface area, this is actually already too small,' John Kasbergen explained."])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2015"])},
      "category": 3,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/19.2.webp"])}
      },
      "contents": [
        {
          "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bart kicks off the expansion of the business premises on the Trawlerweg. 2015."])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["video"])},
          "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/1atV8-NMZQQ"])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Although Houdijk could not meet the need for more floor space in the 2000s, a new opportunity presented itself in 2015. The premises immediately next door to Houdijk, at Trawlerweg 2, housed a printing company which had to close its doors as a result of emerging digitisation. The premises were transferred to Houdijk in 2015 and the company renovated them completely. Since then, Houdijk Holland has been located at Trawlerweg 2-4. 'That was necessary because of the growth, but when you look at what we really need in terms of surface area, this is actually already too small,' John Kasbergen explained."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The building that Wijnand jr., Joop and Aad opened in 1980, was greatly expanded by the transfer of the former printing company. 2019."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/19.2.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders came in from all over the world and the number of employees grew from 35 in 2003 to over 200 in 2015. Soon, the added floor area at Trawlerweg 2 proved too small for the thriving company. Less than six years later, there was another expansion of the premises with the addition of a new section to the assembly hall at Trawlerweg 2. Given the strong growth that Houdijk is still experiencing, the question is not if, but when the next expansion of the premises in Vlaardingen will take place."])}
        }
      ]
    },
    {
      "id": 20,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/20.1.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduction of the Capper"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'We only win if we build machines that perform better and achieve higher quality,' Bart said when Houdijk presented the Capper in 2015. 'That development was in line with our previous machines. The biscuits leave the oven bare and sometimes they need some work before they can go into the packaging. That's right up our street.' After the Telinmatic and the Gradomatic, Houdijk once again confirmed its position as global market leader in the biscuit handling industry with the Capper."])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2015"])},
      "category": 2,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/20.1.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Houdijk Capper fills sandwich biscuits with a layer of crème. 2009."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/20.1.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'We only win if we build machines that perform better and achieve higher quality,' Bart said when Houdijk presented the Capper in 2015. 'That development was in line with our previous machines. The biscuits leave the oven bare and sometimes they need some work before they can go into the packaging. That's right up our street.' After the Telinmatic and the Gradomatic, Houdijk once again confirmed its position as global market leader in the biscuit handling industry with the Capper."])}
        },
        {
          "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" An early type of Houdijk machine that handles sandwich products. 1991."])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["video"])},
          "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/9nLmpcW8gug"])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Capper is specifically designed for sandwich biscuits, i.e. products with a filling in between, such as the world-famous Oreo biscuits. Over the years, Houdijk has always been looking to improve their machines. The first model of the Capper from 2015 still turned the biscuits one by one with a kind of chute, before the filling could be deposited. In the most recent Capper - better known as the Houdijk Capper Mark III - the biscuits are turned in a continuous process, so that much higher speeds can be achieved."])}
        },
        {
          "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Capper processes more than five thousand sandwich biscuits per minute. 2019."])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["video"])},
          "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/oBw4McQCqdw"])}
        }
      ]
    },
    {
      "id": 21,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/21.1.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Cookiebot and the application of robots"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the large factory halls in, for example, America and China, biscuits come out of the ovens in their thousands, sometimes tens of thousands, per minute. Houdijk's high volume lines are specifically designed to handle these quantities of biscuits per minute and are fully geared to process one product shape as efficiently as possible. Bart and the sales team became increasingly aware that there was a demand for machines which could process a larger variety of differently shaped products and packaging. Bart Houdijk: 'Existing Houdijk machines were not really suitable for that, so we looked for - often very complicated - adjustments and solutions. After all that puzzling, a customer came with a new product variant and we could start the search for a solution all over again. Then I thought, \"We're just going to solve this problem with robots.\" We are a smart company and so we moved quickly.'"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2018"])},
      "category": 2,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/21.1.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the large factory halls in, for example, America and China, biscuits come out of the ovens in their thousands, sometimes tens of thousands, per minute. Houdijk's high volume lines are specifically designed to handle these quantities of biscuits per minute and are fully geared to process one product shape as efficiently as possible. Bart and the sales team became increasingly aware that there was a demand for machines which could process a larger variety of differently shaped products and packaging. Bart Houdijk: 'Existing Houdijk machines were not really suitable for that, so we looked for - often very complicated - adjustments and solutions. After all that puzzling, a customer came with a new product variant and we could start the search for a solution all over again. Then I thought, \"We're just going to solve this problem with robots.\" We are a smart company and so we moved quickly.'"])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Cookiebot's pick-and-place robotic arms transfers products from one place to another. 2020."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/21.1.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After initial tests with robotic systems in late 2017, Houdijk presented the Cookiebot in 2018: a pick-and-place robot that can move biscuits of different shapes and sizes guided by camera sensors. R&D Engineer Menze-Jan Buitenhuis: 'The Cookiebot can pick up a biscuit with a gripping arm and place it in a plastic tray or on another conveyor belt in exactly the right pattern. We installed the first Cookiebot in Belgium. That factory made frozen croissants which we picked up with the Cookiebot and put on a conveyor belt to the packaging machines.' This flexible machine quickly became popular. Alongside the Gradomatic, Flowmaster and Capper, the Cookiebot is one of the biscuit handling lines with which Houdijk serves customers all over the world."])}
        },
        {
          "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Houdijk's development of robot lines, means they can offer their customers more flexibility. End 2019/Early 2020."])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["video"])},
          "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/pFLMRkC_onA"])}
        }
      ]
    },
    {
      "id": 22,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/22.3.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Houdijk Holland is the global market leader in biscuit feeding machines. Almost 95 percent of the machines designed and assembled by the Vlaardingen-based company are destined for other countries. Internationally well-known manufacturers such as Nabisco/LU/Mondelèz, PepsiCo and Ferrero are among its customers and Houdijk's machines can be found in many countries around the world. Bart Houdijk: 'For the sake of convenience, I always say: we achieve 25 per cent of our turnover in Europe, 25 per cent in North America, 25 per cent in South America and 25 per cent in Asia.'"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2020"])},
      "category": 4,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/22.3.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Houdijk Holland is the global market leader in biscuit feeding machines. Almost 95 percent of the machines designed and assembled by the Vlaardingen-based company are destined for other countries. Internationally well-known manufacturers such as Nabisco/LU/Mondelèz, PepsiCo and Ferrero are among its customers and Houdijk's machines can be found in many countries around the world. Bart Houdijk: 'For the sake of convenience, I always say: we achieve 25 per cent of our turnover in Europe, 25 per cent in North America, 25 per cent in South America and 25 per cent in Asia.'"])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Houdijk's excellent international reputation started already in the 1960s with the success of the Telinmatic in Belgium and Germany. France followed soon after and, with the takeover of Verkade by United Biscuits, so did the United Kingdom. Projects were carried out as far away as Finland and Russia, and former field mechanic Jan de Priëlle even travelled to communist Kazakhstan. 'We had to set up a whole biscuit line there, but hardly anything was prepared on site. Electricity was not available. Everything was set in motion only after we arrived. And in the meantime, we were just sitting around doing nothing.' "])}
        },
        {
          "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Houdijk shows and sells its innovative machines to biscuit and cookie manufacturers from across the globe on the Interpack fair in Germany. Late 1980's."])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["video"])},
          "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/sJdffVqbm7U"])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The first assignment in North America followed in 1989. Edward van Oostende: 'I had only just started work at Houdijk but was then allowed to join the project in Chicago. We installed a Telinmatic there for Bakeline.' In the years thereafter, Houdijk focused on biscuit manufacturer Nabisco, now Mondelèz. Following an order for a machine line for Oreo biscuits in Canada, Houdijk received more requests from Nabisco/Mondelèz. Bart: 'They have been instrumental in our development. Mondelèz builds enormous biscuit lines, so we had to work very hard to achieve that as well. Because they started producing in other countries, we also went all over the world. Another big customer is PepsiCo, to whom we supply biscuit and cracker lines in South America."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Houdijk's successful first project with Nabisco gave it an entrance into the North American market. 1993."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/22.2.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Since the late 2010s, Houdijk Holland has had agencies in most EU countries, Switzerland, the United Kingdom, Ukraine, Russia, Turkey, the United States, Canada, Mexico, Guatemala, Nicaragua, Colombia, Brazil, Argentina, Chile, South Africa, Israel, Pakistan, India, South Korea, Indonesia and Australia. Business relationships in the United States have been maintained by Houdijk North America from Richmond, Virginia, since 2020. Houdijk expects to expand further into Eastern Europe and Russia in the future."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With a branch in the United States and representation in many countries, Houdijk's machines can be found all over the world. 2020."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/22.3.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        }
      ]
    },
    {
      "id": 23,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/23.2.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innovative mindset"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driven by the same drive for innovation in machinery, Bart also strives to apply innovative technology both inside and outside the company premises. There are screens on the walls of the office that indicate the availability of meeting rooms, there is an entirely digital system at the entrance to welcome visitors and the company garden includes a bee hotel and is completely ecologically designed and maintained."])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2020"])},
      "category": 3,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/23.2.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driven by the same drive for innovation in machinery, Bart also strives to apply innovative technology both inside and outside the company premises. There are screens on the walls of the office that indicate the availability of meeting rooms, there is an entirely digital system at the entrance to welcome visitors and the company garden includes a bee hotel and is completely ecologically designed and maintained."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More than 2000 solar panels on the roof, provide Houdijk with renewable energy. 2019."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/23.1.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'The difference between the old-fashioned and the new or ecological way is often not so great and does not cost much more money. I'm not a fanatic, but if something can be done in a more innovative way, then we should do it. That way you avoid having to change everything after a few years anyway,' Bart explained. One such opportunity arose when the electrical system in the car park was renewed. Instead of just replacing the original cabling, Bart had it prepared for twenty EV charging stations and green parking spaces with rainwater storage. The more than 2,000 solar panels on the roof have been generating a large proportion of the energy consumption for some time, and the old-fashioned fluorescent tubes and gas connections have been replaced by LED lighting and electric heaters."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The innovation power with which Bart wants to keep the company ahead of the competition also has its limits. Houdijk's ambition demands much flexibility from the employees. Senior Service Engineer Edward van Oostende: 'Sometimes, this puts pressure on the company and creates stress. For the past few years, we have been looking forward to a more stable situation. The COVID-19 pandemic did not help either.' Bart Houdijk took this message seriously: 'I realise that the last three years have not been easy for us. By working more and more with robots, we are moving from our traditional market to a new one. I pushed hard for this innovation and took on too much. It was not easy, but fortunately we are now back on track.'"])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Through continued development, Houdijk refines the uses of its robot lines. 2021."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/23.2.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        }
      ]
    },
    {
      "id": 24,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/24.1.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One hundred years of a family-owned company"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'I think you can call Houdijk a real people business, even though we make machines.' With these words, Bart Houdijk described the family business on the occasion of its seventieth anniversary in 1992. The bond between the company and its people existed from the beginning. Two of the first employees, hired by Wijnand senior in 1922, even worked in the Vlaardingen machine factory for more than fifty years. As was not unusual in those days, Willem van der Schee and Bernardus Versteege started working as helpers at Houdijk when they were fourteen and worked on until they reached the retirement age of sixty-five. Willem's son, Wim van der Schee, joined the family business at the suggestion of Joop Houdijk. Like his father, Wim stayed with Houdijk until his retirement."])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022"])},
      "category": 1,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/24.1.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'I think you can call Houdijk a real people business, even though we make machines.' With these words, Bart Houdijk described the family business on the occasion of its seventieth anniversary in 1992. The bond between the company and its people existed from the beginning. Two of the first employees, hired by Wijnand senior in 1922, even worked in the Vlaardingen machine factory for more than fifty years. As was not unusual in those days, Willem van der Schee and Bernardus Versteege started working as helpers at Houdijk when they were fourteen and worked on until they reached the retirement age of sixty-five. Willem's son, Wim van der Schee, joined the family business at the suggestion of Joop Houdijk. Like his father, Wim stayed with Houdijk until his retirement."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The atmosphere at Houdijk has been friendly and familial since day and age. 1937."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/24.1.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besides the three Houdijk brothers, more employees who joined the company shortly after the Second World War contributed to the family atmosphere within the company. One of them was Arie Gog, who was taken on as a draughtsman and soon became head of the drawing office. When an order came in, it was Arie who drew the first design and for that reason became known as the father of the machines made by Houdijk. At the same time as Arie, secretary Nettie Sterk started working for Houdijk. It did not bother her that she was a woman in a man's world. Miss Nettie was known to be very persistent and after some time she became Aad Houdijk's right hand. The little dog that always accompanied her was a regular feature on the shop floor."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Many of Houdijk's employees would work their entire professional lives for the family company in Vlaardingen. 1988."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/24.2.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["According to Bart's brother Guus Houdijk, much of the family atmosphere on the shop floor is due to their father Joop. 'He was always very people-oriented and involved with the employees and their families. In fact, he always knew how the children of his employees were doing.' Besides fostering an informal atmosphere, giving employees their own responsibility also contributed to a high level of employee involvement. Edward van Oostende: 'When I started at Houdijk, we still cut and worked our own materials. We were given a pile of drawings and set to work. I was given the freedom to develop early on. By making use of that, I knew everything about our machines. By participating in all the steps of building a machine, you also get a greater sense of responsibility'."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bart consciously continues this corporate culture. 'I always seek to instil three cultural values in new employees. The first is a friendly, respectful and collegial work climate. We do it together and talk to each other, not about each other. We discuss things with each other based on arguments and facts, not from authority or power. The second value is that the customer is at the heart of things. If something has to be done for a customer, I prefer to see it done as quickly as possible. The last point is order and cleanliness. We work here in a neat, organised and professional way. That is not only more efficient, but it also presents a good impression to our customers.' Despite possible obstacles due to the growing number of employees, the friendly atmosphere that characterises Houdijk is also noticed and appreciated by new colleagues. Commissioning Engineer Sebastiaan Vijfhuizen, employed by Houdijk since 2018: 'On my first day at work, we visited everyone for a brief introduction and I immediately sensed a very friendly atmosphere. It's not so strict or hierarchical. You can just drop in on each other and the colleagues also get on well outside working hours.'"])}
        },
        {
          "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Three generations of the Houdijk family have put their mark on the family company."])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["video"])},
          "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/PXG644kJDqE"])}
        }
      ]
    },
    {
      "id": 25,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/placeholder.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The next generation"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the hundred years of Houdijk's existence, the company has changed dramatically. From ship repairers to machine factory to the global market leader in biscuit handling. All these developments took place under the leadership of the Houdijk family. One hundred years ago, Wijnand Houdijk senior started the company. After the Second World War, his three sons Wijnand junior, Joop and Aad joined the company and in the late 1980s Bart Houdijk took the helm. With Bart's nephew Sander, who operates Houdijk's machines as a mechanic, the fourth generation of Houdijk is also active in the family business."])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022 and beyond"])},
      "category": 1,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the hundred years of Houdijk's existence, the company has changed dramatically. From ship repairers to machine factory to the global market leader in biscuit handling. All these developments took place under the leadership of the Houdijk family. One hundred years ago, Wijnand Houdijk senior started the company. After the Second World War, his three sons Wijnand junior, Joop and Aad joined the company and in the late 1980s Bart Houdijk took the helm. With Bart's nephew Sander, who operates Houdijk's machines as a mechanic, the fourth generation of Houdijk is also active in the family business."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only the future will tell whether the family business will be run by a fourth generation of the Houdijk family. Although Bart leaves it up to his sons Laurens and Olivier, he does try to involve them in the family business as much as possible. Bart's wife Liliana Houdijk: 'Bart takes a step-by-step approach when it comes to Laurens and Olivier working in the factory. It was the same for himself and his brothers. It wasn't forced on Bart and he doesn't force it on them either.' To familiarise his eldest son Laurens with the company, Bart has already taken him to the Interpack trade fair and on a business trip to Italy and Mexico. After completing his studies in Engineering, Laurens will start work at Houdijk as a junior sales and office staff member in 2022. In this way, he hopes to combine his interest in technology and commerce. 'I am interested in technology and what Houdijk does is impressive. I think it's nice to see the machines up and running whenever I go along to a customer.' Laurens' younger brother Olivier, who is studying Sociology, sees a role in human resources for himself. Olivier: 'My father always goes to the factory on Saturdays and sometimes my brother and I accompany him. I think it means a lot to him if we were to join Houdijk, but he does leave us free to do what suits us.' Along the way, they learn a few things from their father about the company and about how to deal with the people who work there. Laurens: 'He teaches us that there is no point in forcing people to do their work or telling them what to do.' Olivier: 'He says that we should trust people to do their work as they see fit.'"])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bart very much prefers that his sons continue the family business together. One more on the outside of the company and the other more internally. 'We will embark on that adventure together. I am convinced they can do it. I will be here for a few more years to take them along, train them and guide them.' Bart is also looking to the future, keeping open the possibility of striking out on a new path with the family business. By moving Houdijk more towards robotisation, he is already preparing the company to be able to operate outside the biscuit industry. This will no longer be the case in his time, because - as he explains himself - he has devoted himself entirely to biscuits. Where he has perfected the biscuit handling machines of his father and uncles, Bart wants to give his sons that opportunity by building robot lines."])}
        }
      ]
    }
  ],
  "memory_items": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memories"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quotes from employees of Houdijk Holland"])},
    "items": [
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Former foreman of the Turning Mill and Works Council member employed between 1950 and 1992"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the informal work environment at Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'I used to take Bart and his brothers to the gym. I only did that once. I said, \"Well, I won't do that anymore, because they'll be jumping into the harbour if something happens and I won't be jumping in after them.\" You were part of the family right away, though.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaap van Ree"])}
      },
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Former foreman of the Turning Mill and Works Council member employed between 1950 and 1992"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Wijnand Houdijk senior"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'I was married and still living with my parents. We already had two little ones. The house consisted of three rooms with a kitchen, but I wanted my own house. I had applied to Shell because they promised that I could have my own house. When I told that to the company, Houdijk senior got angry. He wrote a letter to the council and went to the town hall to complain to them about getting me a house. \"I suppose you're not leaving now, are you?\", he asked.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaap van Ree"])}
      },
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employed between 1971 and 2016"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the cooperation between Hooimeijer and Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'My father developed the Telinmatic with Houdijk. A technically gifted man, my father. The relationship between Hooimeijer and Houdijk started in the early 1960s. Every summer, when there was a break for maintenance of the machines, a Houdijk team worked at my father's place. Also, when there were fires at Hooimeijer, Houdijk was the first to help. I am very proud of what Hooimeijer was and what Houdijk is now. I genuinely think it is a beautiful company.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jan Joost Hooimeijer"])}
      },
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employed between 1971 and 2016"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the workplace climate at Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'It is an art to bind people to your company for the long term. In the end it's about the people, not the work. If you have the people, the work will get done. Actually, you should not tell someone how to do their job, you should let them tell you how you want it done. Talk about the work that needs to be done and make sure that everyone feels they have a part in it. That is also how Houdijk did it.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jan Joost Hooimeijer"])}
      },
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Former employee of Verkade/United-Biscuits"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the cooperation between Verkade and Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'The Verkade family likes quality. We had engaged a German company for new supply systems for the rusk lines. The rusks often broke during processing. We heard that Houdijk had developed a new supply system. An air bed machine and a counting system with which the number of rusks could be accurately determined. Purely on trust, we then bought three of these and cooperated in the further development. They called those machines the Telinmatic. They worked very well and saved us a lot of money on waste. More recently, Verkade also participated in the development of the Skipper, a biscuit dosing system by Houdijk. We bought three of those and they are still running to full satisfaction.<br><br> 'In our company, everyone knew the Houdijk people. For example, they thought Tom Olsthoorn worked at Verkade, because he was there so often. He also knew his way around. The lines between Houdijk and Verkade were very short and clear. When I had just started working for Verkade, I sometimes had contact with Joop and his brother Wijnand. I remember Bart walking through the aisles of the old machine factory as a little boy.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerard Langver"])}
      },
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Former draughtsman - employed between 1977 and 2001"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the family atmosphere at Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'The mutual understanding with Houdijk was always very good. I had a great time for twenty-three years and I still pop in here every year. Then I have a chat and get a fruit box or an almond pastry letter. Recently, I got a chocolate letter. That is quite unique actually. I heard from a friend that he never heard anything from his boss again when he retired. When someone had worked for Houdijk for a long time and retired, they would organise a party and be honoured. That's a completely different culture.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koos Kesseboom"])}
      },
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receptionist - employed since 1978"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the family atmosphere at Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Mr Joop Houdijk, Bart's father, was a very warm and engaging man. He always patted me on the back. Not just me, but also others. Definitely a pleasant employer, but so is Bart. He got that from his father. The colleagues are also very nice. Especially the \"old guard\" have been through so much together. Unpleasant things, but also nice things such as weddings, births of children, parties... It feels like family, a close-knit group.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annette Dunweg"])}
      },
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receptionist - employed since 1978"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the family atmosphere at Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'For the past few years, we have been having Saint Nicholas parties again because more of our colleagues are having children. Then you also get closer to the \"young guard\". In 2020, we had a \"Saint Nicholas McDrive party\" because of Corona. That went all around the building. We stayed in the car, while Saint Nicholas' helpers walked around outside and gave the children presents at the end of the drive. They really enjoyed that.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annette Dunweg"])}
      },
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team Leader Assembly - employed since 1979"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the family atmosphere at Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Sometimes you get to an age where you have to decide what you want to do before you grow old. Some people then decide to leave the company. I say: \"That's entirely up to you, but the door is always open.\" Some are back after only a week, others after a few months. Houdijk still keeps that family atmosphere. Bart is very social and will meet you halfway if you want something. That is really the dominant factor here.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hugo Sluimer"])}
      },
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Younger brother of Bart Houdijk"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the involvement of Joop Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Houdijk was always good to its people. They had no reason to leave. I think my father, in particular, was a binding factor in that. He was very involved with his employees and their families. Especially the \"old guard\" was pretty much part of the Houdijk family. People are much more willing to work for a family business than for a company that only focuses on results for shareholders.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guus Houdijk"])}
      },
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Older brother of Bart Houdijk"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Wijnand Houdijk senior"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'On Sundays, we regularly visited grandpa and grandma Houdijk. They were a respectable couple with a respectable house and an attitude that radiated authority. I think my father looked at his father in the same way, as a man of authority. Until his death, he always sat neatly dressed in a suit and tie behind his desk and looked very well-groomed. In the early days, he did get his hands dirty, of course, but my brothers and I never saw that.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arie-Hans Houdijk"])}
      },
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Older brother of Bart Houdijk"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Wijnand and Aad Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'We often visited uncle Wijnand or uncle Aad. It was tradition to go to the eldest brother's for New Year's Eve and Christmas. Uncle Aad was a bit sickly. He had severe asthma and that limited him a lot in his activities. He always wore a suit and carried a puffer in the right-hand pocket of his jacket. You saw him use it regularly. It was also quite logical that he was hardly ever seen on the factory floor. At most, he just wanted to show his face now and then. They were always very good to the staff. They very much looked after their employees.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arie-Hans Houdijk"])}
      },
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Older brother of Bart Houdijk"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About holiday work and Joop Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'When my father started working in the company, I also became more aware of the factory. I don't have the impression that he was never at home, but he was away a lot. He had to go abroad regularly. I also went with him a few times on customer visits on days when I was free. Then we would go to Bolletje or Verkade and I would stare my eyes out in those factories. I remember the strong baking smell when you entered such a factory. That's where my father would go to talk and sell machines. He was really the man for sales and for maintaining contact with the customers.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arie-Hans Houdijk"])}
      },
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Older brother of Bart Houdijk"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Joop and Bart Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Bart is very different from my father. He's more of a thinker and has a quicker overview. My father actually wanted to do everything himself. That doesn't make things easy for you. Bart undoubtedly has his worries too, but he delegates more and can deal with them more easily. It's extraordinary to see how Bart has reinvigorated and expanded the business. I'm proud of that.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arie-Hans Houdijk"])}
      },
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Older brother of Bart Houdijk"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Bart and Sander Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'My father asked Bart if he wanted to take over the machine factory. I don't think any of the other boys had the ambition to ever work in the factory. We saw how it burdened my father. He was regularly away from home, slept badly and it was obvious that he was very worried about the factory. The stress and sleepless nights made his heart suffer. That did dissuade us from following in our father's footsteps. But in the end, Bart did. He has built a beautiful company, has expanded a lot and has good employees. I never hear any dissatisfied noises from the employees. My own son, Sander, has been working at Houdijk for years now. He is a mechanic on the shop floor, but also often goes abroad to assemble the machines there. In the beginning, it was difficult to walk into the company as a Houdijk. He is the nephew of the boss and it was questionable whether he would be accepted. However, he is quite modest so he did fit in.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arie-Hans Houdijk"])}
      },
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner and Managing Director - employed since 1987"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About his youthful involvement with Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'I was already involved in the company as a child. I did holiday work there and kept it up for years. That was still in the old building. I also worked here during my studies. I often travelled with my father on business trips throughout Europe. He would go to Germany or France and I would go with him to visit the customers. I was 15 or 16 at the time. I think my father saw me as a potential successor early on. I never really made a conscious choice to go and work for my father. I just grew into it. It wasn't because of wild ambitions or anything like that, but I've never regretted it.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bart Houdijk"])}
      },
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner and Managing Director - employed since 1987"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Joop Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'My father found it very hard to accept that he could no longer work and had to leave the business to me. He found it hard he had to leave the business to me in such difficult times. He didn't see the real boom, but he did see that we were getting back on our feet. He couldn't really talk to me about the company anymore. I mean that literally because it was too stressful for him. That was also the reason why he had to stop. He used to come in here for coffee, but he wouldn't last half an hour. As soon as my father walked in, he was again overwhelmed by all the stress.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bart Houdijk"])}
      },
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managing Director SNL Group Schijndel, supplier to Houdijk"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the cooperation with Bart Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Bart is from the generation that will complete one hundred years of Houdijk. That authenticity has something. He has paintings of his father and uncles hanging in his office for a reason. That shows that Houdijk is still a real family business. I have a special bond with Houdijk, but also with Bart himself. We talk regularly about practical matters and, for example, about his succession. It's a family business and that's one of Houdijk's strengths. I sometimes visit former family businesses that have been bought up by investors. They soon lose their soul. That soul is present here.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frans Lammers"])}
      },
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercial Director - employed since 2005"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the advantages of a family business"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Family businesses are the most successful in the type of markets in which Houdijk operates. Bart is always busy with the long-term vision. He doesn't have to draw dividends from the company because he is the only shareholder. That money stays within the company, while purely profit-oriented companies want to rake in as much as possible. This limits the options for investments to strengthen the company. One day, at a fair, an acquaintance came up to me and said that he always visits Houdijk's stand first to see what new machines there are. That's nice to hear.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marnus Rotte"])}
      },
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["R&D Engineer - employed since 2013"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Bart Houdijk's commitment"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Bart is very committed to the employees on large projects. He is always aware of problems that occur at customers. When I was recently in Canada for a project, he sent me a message in the evening to ask how it went. I replied a little later but realised that it was already night in the Netherlands. Still, I received a message back with further questions.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menze Jan Buitenhuis"])}
      },
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test Engineer - employed since 2013"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About working with colleagues"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Each year I organise a number of events with the party committee, such as the \"Winter Games\" and \"Summer Games\". In the winter there are indoor activities such as pool, darts, curling or table football. In summer, there are many outdoor activities such as basketball, football or an assault course. Recently, we added a pub quiz. The purpose of these events is for colleagues from different departments to get to know each other in a different way. In the days following such an event, I notice that colleagues greet each other more jovially and that cooperation often runs just a little more smoothly. Organising the events is much appreciated by colleagues and that makes it fun to do.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dion Hofland"])}
      },
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissioning Engineer - employed since 2018"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the family atmosphere at Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'The company culture here is very open. I recently read a welcome message from a new software engineer and he immediately commented on the pleasant working atmosphere. The friendliness in our department is a real source of motivation. No question is too stupid and you are immediately included in everything that is going on. We also socialise outside of work hours and go climbing or running. Work and private life are not strictly separated then, but people really want to socialise with each other inside and outside working hours.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sebastiaan Vijfhuizen"])}
      },
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissioning Engineer - employed since 2018"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the workplace climate at Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Bart really listens to the people at Houdijk and we are always welcome to have a chat with him. And it also works the other way round. If he thinks something you're doing is interesting, he doesn't hesitate to call you to ask how it's going and whether you need his help. That's also to the credit of some of the managers here. They just tell you when they think something is great and give you compliments. That is very valuable and I like to do it the other way round as well. You notice that people really appreciate the compliments.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sebastiaan Vijfhuizen"])}
      },
      {
        "category": 2,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Former foreman of the Turning Mill and Works Council member"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employed between 1950 and 1992 - About working at 1st van Leyden Gaelstraat"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'When I started work there in 1950, it was just five years after the Second World War. I didn't have much, apart from a folding ruler and a stool that I had made myself and where I sat on when eating.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaap van Ree"])}
      },
      {
        "category": 2,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Former fitter and mechanic - employed between 1956 and 1996"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About his job application to Wijnand Houdijk senior"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'After a quarrel with my then employer, I went to see Houdijk that same evening. I met the old boss, Wijnand senior, who asked why I had come. I said I came to see if there was work, because I had had a row. He asked me \"whose are you?\" Vlaardingen is of course relatively small and there were people who were on Wijnand's blacklist. He didn't get along with them, but there were also people with whom he did get along. If you were one of them, you were lucky. My father was a regular visitor to Houdijk's, because he still worked in transport with a horse and cart. Houdijk always needed iron and my father would bring it. The old man saw my name and asked if I was a relative. I said that I was, of course. \"You can start right away,\" he said.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jan de Priëlle"])}
      },
      {
        "category": 2,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Former fitter and mechanic - employed between 1956 and 1996"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About working in the Soviet Union"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Once, I was in communist Kazakhstan with a crew to set up a whole biscuit line. There were all kinds of Russians, Yugoslavs and the like working there. We had to distribute the finished product in plastic boxes. But we needed electricity for that and we didn't have any over there. \"That will come tomorrow,\" they kept saying. In the meantime, we sat around doing nothing. One day I was called into the office. The Yugoslav manager told me that my wife had problems with her heart, but she would be fine. I wanted to go home immediately. I had children and of course I wasn't going to stay there, but that was impossible. They had taken my passport and would not return it. After asking several times, I met a Yugoslav who also had to go home. He came to me one evening and said he was going home the next morning. He had arranged a small car, which was necessary because we were 80 kilometres from the airport, and if I wanted to, I could join him. I went to the manager and said that I needed my passport now, because I was leaving tomorrow. He said it was not so bad and there was still work here. I said, \"Bye, I'm leaving.\"'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jan de Priëlle"])}
      },
      {
        "category": 2,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Former fitter and mechanic - employed between 1956 and 1996"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About a work incident"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'We once went to a fishmonger's here in Vlaardingen to overhaul and clean a kettle. The kettle had to be inspected every few years, so all the taps were closed. The inside of the kettle had to be cleaned as well, as there were all kinds of pipes there. It was a summer's day and after being in there for fifteen minutes, you had to get out quickly to be able to breathe properly. One of the boys then thought that he could add a bit of fresh air. He had placed an oxygen cylinder with a hose in the kettle and opened the tap to bring oxygen in. But he didn't realise how dangerous that was. Pure oxygen gets into your clothes. When he lit a cigarette, he was suddenly on fire. Next to the kettle were large barrels of brine for the herring, so we quickly put him in there. He didn't suffer any burns, but he did lose his hair.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jan de Priëlle"])}
      },
      {
        "category": 2,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical Supervisor - employed since 1971"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About his first working years"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'I was among the first at the Lower Engineering School to officially leave school with a diploma. When we got that diploma, the employment office came to see us at school. Companies in the area needed people. They asked me what I wanted to do, but I didn't really know. I was only fifteen. Then they asked me what subjects I got the best grades in. Well, that was constructional fitting and then they gave me two addresses where I could apply for a job. One was Houdijk and the other somewhere in Schiedam. I first went to Vlaardingen, where they were still located in the Van Leyden Gaelstraat. I went in and sat down with Joop and Wijnand Houdijk. They asked me: \"So, you want to come and work here? Do you also want to do the company training to become a meter technician?\" I was willing to do so, I couldn't say no. \"Great, when can you start?\" That was the whole job interview at the time.<br><br>'I was immediately paired with an older colleague to learn the trade, because I was still an apprentice, of course. Only when they knew a bit about your skills and whether you had something to offer did you get your own tasks to do. I always got on well with my colleagues; everyone was willing to teach you something. So, I started as an apprentice and became a foreman after five or six years. From that position, I became a project manager, then the head of final assembly and also head of the entire assembly department. I grew in my work, which became increasingly more complex. It is very different now from what it used to be. Nowadays you have to show what you can do in advance before you can show what you can do in practice.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinus van Driel"])}
      },
      {
        "category": 2,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical Supervisor - employed since 1971"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Bart Houdijk's holiday job"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Together with Tom Olsthoorn, I had a job at Hooimeijer in Barendrecht, where we had to overhaul a rusks production line. That was in the middle of the summer, very hot. Then we came back to the factory and reported on how things were going and what we still had to do. I told them I needed an extra man, because we couldn't quite manage it. They said they still had a holiday worker. I had some simple work for him, so that was fine. Then they gave me Bart. The poor boy spent a week or more under the machine drilling holes. He did it without grumbling, I'll give him that. No whining or cursing, he just went about his business. Every now and then we had to get him out from under the machine to drink a cup of coffee and after that he would carry on drilling.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinus van Driel"])}
      },
      {
        "category": 2,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Former Head of Production - employed between 1974 and 2006"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the work at 1st van Leyden Gaelstraat"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'In the old building at the Van Leyden Gaelstraat, it was extremely difficult to work. It was divided up into separate rooms because it was being extended bit by bit. When we had to pick up a machine, we had to place a crane in a side street to lift the machine onto the lorry. It was all so terribly laborious compared to now.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinus Deuster"])}
      },
      {
        "category": 2,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical Specialist - employed since 1975"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About his first working years"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'When I started working here, I soon went to work making Telinmatics with Rinus Bot in the old ropeyard at the back. These were the parts, of course, as we made everything ourselves. Days of sawing material, marking it out and drilling. That was quite a project and you were involved from start to finish. If you sawed your own material, you also had to deburr, clean, mark, drill and often paint it with Hamerslag Blauw, which always covered the surface.<br><br>'At a certain point, the supervisors saw that I was more suited to work on the final assembly, so I also got involved in wiring the electricity. I took many courses for that. Houdijk paid for them, but, of course, you attended them in your own time. They were all evening courses, one or two evenings a week. I learned a lot at Houdijk, and we were given the opportunity to do so.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tom Olsthoorn"])}
      },
      {
        "category": 2,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Former draughtsman - employed between 1977 and 2001"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About his first working years"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'I used to work in the drawing office at Cincinnati Milacron, also in Vlaardingen. Because of the recession, fewer and fewer products were sold and there were waves of redundancies, and I was one of them. My previous employer advised me to check with Houdijk. I talked to Joop Houdijk, but they didn't need anyone at the time. He did write everything down though. A few months later, I was working on my attic and Joop was suddenly at my door. \"Are you still unemployed?\", he asked. \"Come and see me this afternoon.\" After a chat he said I could start on the following Monday. \"If it is not working out, you'll hear about it, otherwise you won't hear anything.\" I didn't hear anything for fourteen days, so that was all right.<br><br>'We were in the Van Leyden Gaelstraat at the time. They had linked up a number of old fishermen's cottages and knocked down the walls. The machine factory was downstairs and we had the drawing office upstairs. The head of the drawing office was also the chief engineer. When he received an order from the sales department, he made a large, global sketch. Once he had drawn the electronics and mechanics, he called us in to work it out further so that the factory could start working on it.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koos Kesseboom"])}
      },
      {
        "category": 2,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Former draughtsman - employed between 1977 and 2001"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About new technology on the shop floor"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'In the beginning we used drawing boards, later on we switched to computers. I was already 58 when I started drawing with the computer. That was all new to me then, while the younger guys were more experienced and could make the switch much more easily. We did enjoy it. We no longer needed a pencil or an eraser. Working with the computer is much quicker and we made far fewer mistakes. Before, defects only came to light when the machine was already finished. That was not a very pleasant situation, so to speak. All that goes digitally now. They can actually draw a machine which is in their heads and then make it work digitally. They can see how it works even before they have built it.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koos Kesseboom"])}
      },
      {
        "category": 2,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Former draughtsman - employed between 1977 and 2001"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the workplace climate"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'We worked hard and also worked overtime a lot. Sometimes, I worked alone at night in the building. I had the key. One evening, I was busy drawing and it was dead quiet. Suddenly, I saw the head of a guard above my drawing board. He had quietly entered the building. It scared me senseless.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koos Kesseboom"])}
      },
      {
        "category": 2,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receptionist - employed since 1978"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About women at Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'I felt immediately at home when I joined; it was very special really. The same goes for the reception by Bart's father and uncles. I stepped into the world of technology. It was a man's world, of course, but I got on well with the guys in the factory from the start. There was an old-fashioned bookkeeper of about sixty, called Miss Nettie. Together with Bep, the coffee lady, we were the only three ladies around. We sometimes played practical jokes on the guys: we secretly put yucky stuff on their sandwiches or hung the director's new bicycle in the hoists. I remember the smells the most... I can still smell the drilling oil, the turning mill and milling machines.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annette Dunweg"])}
      },
      {
        "category": 2,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receptionist - employed since 1978"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the interpack trade fair"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'I used to go along to the interpack trade fair in Germany every three years. In the beginning, we had a small stand. I took care of the coffee and the telephone and Bart was there with two salesmen. When I walked around there for the first time, I thought it was wonderful. It was so big. Now we have one of the nicer and larger stands. I am really proud of that.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annette Dunweg"])}
      },
      {
        "category": 2,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner and Managing Director - employed since 1987"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About his attitude towards employees"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'I see myself as one of the team. I do not like playing the boss. I take the big decisions myself, but apart from that, I want to do things together. I always approach new employees to have an introductory talk with them. I always let them plod along for a month, then they know how things work and we have the introductory talk. I give them a bird's eye view of Houdijk's history and of who I am, and then I close with a number of corporate values that I want to pass on: focus on the customer, a collegial working climate and order and tidiness!'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bart Houdijk"])}
      },
      {
        "category": 2,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Older brother of Bart Houdijk"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the machine factory at 1st van Leyden Gaelstraat"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'When I look back at it now, the factory was mostly old and dirty, a real machine factory where people worked with lubricating oil and grease and dirt on the floor. It's mainly the smell of oil and lubricants that I remember, also the smell of a kind of burnt iron, because they were always welding, milling, drilling or sawing. Back then, it was really dirty and rough work. Men with dirty hands and overalls that were black with oil and grease. You can't compare that with now. It's so neat, smooth and tidy now.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arie-Hans Houdijk"])}
      },
      {
        "category": 2,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Older brother of Bart Houdijk"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the machine factory at the Trawlerweg"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'The first building here on the Trawlerweg was only a small part of what it is now. It was a large hall with milling machines and turning machines, still a really dirty factory. Not nearly as dirty as at Van Leyden Gaelstraat and not nearly as clean as now, but it was a real factory with machines that smelled and made noise. That has changed enormously. During the holiday job I did, I helped with the cleaning and sweeping. Milling and turning produced iron shavings that fell on the ground with a kind of milky lubricant, so that had to be swept away. I mainly helped with the dirty jobs on the factory floor together with my oldest brother Wijnand. I don't really remember whether we earned any money from it. We must have received something from Miss Nettie, she was always kind to us.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arie-Hans Houdijk"])}
      },
      {
        "category": 2,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Young brother of Bart Houdijk"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the workplace climate at the Trawlerweg"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'I did a lot of holiday work here. Sweeping curls, cleaning, driving the forklift truck, painting. It didn't matter that I was the son of the director. I know all the old-timers here very well too, for more than forty years already. I understood why all the production work was contracted out later and that this had consequences for many craftsmen, but I did think it was a pity. I can still see Wim van der Schee standing behind his drawing board. That is no longer the case. The pranks of the past have also become a lot less. They used to send a youngster to Vlaardingen to get all sorts of stupid, non-existent things like hammer-handle grease. Then he would come to a shopkeeper, who immediately understood the joke and sent him on to another shop on the other side of Vlaardingen. When he had been all over Vlaardingen and came back, they laughed at him. I don't think that happens nowadays, because the boys have become much wiser. They don't fall for that anymore.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guus Houdijk"])}
      },
      {
        "category": 2,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["R&D Engineer - employed since 2013"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About working at Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'The nice thing about my job is that we think everything through, draw it, test it and have it made. Then you can immediately see on the floor what you have come up with. Before, I worked for an offshore company, where you are sent a few photos and do everything from behind your desk. Here, you walk straight into the workshop and see your creation being built. You immediately get a feeling for your product. I always walk around the workshop in the morning. Then I look at it and think: \"that looks beautiful\". That makes me enthusiastic. The nice thing about Houdijk is that we sometimes sell machines of which we do not yet know whether we can make them. On the one hand, as an engineer you want to be sure that what you make will actually work, but the challenge of doing more than you think you can, is great fun.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menze Jan Buitenhuis"])}
      },
      {
        "category": 2,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["R&D Engineer - employed since 2013"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About working at Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'As engineers, we have to pay close attention to who is going to use our machines. Sometimes, the operators are plucked from the street in the morning to work in the biscuit factory. You have to develop a different type of machine for them than, for example, someone who operates a computer chip machine. He has to know exactly what he is doing. We may think that of all the buttons on the machine, he only has to press a specific button to start the machine, but if it is not intuitive, the machine will not work. We write manuals, but they need to make sense to someone else.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menze Jan Buitenhuis"])}
      },
      {
        "category": 2,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test Engineer - employed since 2013"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About room for personal input"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'As test engineers, we have a lot of freedom and responsibility. I like that. We have the opportunity to learn, to develop ourselves and to show what we can do. When I started at the Model Shop, I noticed that the work was quite inefficient. I thought it was necessary to bring more structure and organisation into it. After approval of my manager, I redesigned the whole testing process. Now we have a very good basis and I enjoy it every day.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dion Hofland"])}
      },
      {
        "category": 2,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissioning Engineer - employed since 2018"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About his most memorable project"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'I joined Houdijk as a final technician and became a Commissioning Engineer not long after. The transition between the two was quite interesting. As a final technician, I assembled a machine that was headed for Germany. During the time it was being tested, I became a Commissioning Engineer and was able to deliver the same machine in its entirety. I really enjoyed the whole development process and completing the project. That machine really became my baby.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sebastiaan Vijfhuizen"])}
      },
      {
        "category": 3,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical Supervisor - employed since 1971"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About innovation at Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Development and innovation is handled by the Research & Development department. Bart is an important driving force in this. He often comes up with out-of-the-box ideas. The place is full of techies and then suddenly Bart comes up with an idea we hadn't thought of yet. That's because he looks at it from the outside, he's not a techie. Sometimes these ideas are binned, but he has some very good ideas that we respond to. That is also possible because the lines are very short. If I walk into Bart's office, so to speak, because I want to ask a question, that's what I do.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinus van Driel"])}
      },
      {
        "category": 3,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team Leader Assembly - employed since 1979"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About what distinguishes Houdijk from others"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'I think Houdijk has little competition because it has developed in the more difficult projects. It used to be the case that whenever a customer came to us with a new or crazy idea, and our competitors said they weren't going to do it, they could always turn to Houdijk. Sometimes we would lose money and sometimes we would make a profit. In countries such as China, too, we have little or no competition, despite the fact that there were people who tried to copy parts of our machines. We are of course at trade fairs to show what we make. We are always open to show our machines and answer questions, as long as they don't climb under them to take pictures. They do not really contain any secrets, but the programming of our machines is so difficult that you cannot just copy it. Anyone can make a frame and a conveyor belt, it's not that difficult. What comes after that - making a roll, filling a tree, stacking - for that you have to invest a lot of time and energy in the software. That is where we really have an advantage and therefore little competition.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hugo Sluimer"])}
      },
      {
        "category": 3,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager Operations - employed since 2015"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About innovation at Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'We are who we are because we always create innovative machines and come up with new solutions. This is largely based on the knowledge and experience of Bart, Marnus and the sales department. They know what is happening in the market or check with the customer what they need. They also see new developments, such as reducing plastic packaging, which is actually imposed by the European Union. Our customers then have to switch to cardboard packaging and ask us to come up with something for that. We also have other customers who have all kinds of new products and who want to run them over one and the same production line. We will make this line as flexible as possible. Research & Development then actually comes up with a new concept. These innovations are a real challenge for us. In fact, we present something new at every major trade fair. We aim to do so. At the interpack exhibition, for example, which is the biggest and most interesting trade fair for us, people first come to Houdijk to see what is new in the market. At subsequent trade fairs, you see others with almost the same machines.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["John Kasbergen"])}
      },
      {
        "category": 3,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissioning Engineer - employed since 2018"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About innovation at Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'As Commissioning Engineers, we have quite an influence on Houdijk's innovations. We are the last ones at the customer's site. If something doesn't work, we have to come up with a different solution on the spot. Sometimes that new solution is better than what was initially thought up and becomes the new standard. If we think something adds value to a machine, we pass it on so that our improvement might become the standard.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sebastiaan Vijfhuizen"])}
      },
      {
        "category": 3,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissioning Engineer - employed since 2018"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Bart Houdijk and innovation"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'It's great to see how the whole of Houdijk throws itself into new developments and just goes about implementing it. We also have enough confidence in the people here to do that, but Bart is also fair enough to back off a bit if he pushes too far. Sometimes, he thinks we can do more than we think we can. That then causes us to do what we didn't think was possible. He sets the bar higher and higher. In 2020, we had a really busy year. In addition to ongoing projects, we also had a number of new machines that all demanded a lot of attention. The Capper Mark III was still being developed, just like the Vision Selector and the new Cookiebot. That was a bit too much for us. Bart said that there will still many developments and ideas in the pipeline, but that he would shelve them for now in order to crystallise what was already in development.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sebastiaan Vijfhuizen"])}
      },
      {
        "category": 3,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technology Advisor - employed since 1988"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the development of the Capper"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'A competitor was already making the Cookie Capper before we introduced it. At a certain point, we thought we could do that too, and probably better. We then challenged the Lead Engineer to design a new and better system. He came up with something that was on a higher level in terms of hygiene, accessibility and maintenance. In the first few years, the Capper only cost us money, but now we do make a profit. The Capper is standardised, so it is easy to make them again for every order. But we do like to innovate, so many parts are constantly being developed. We call the most recent and improved Capper the Capper Mark III. Our product knowledge is very important here We know exactly how to pick up a biscuit and what is needed to put them in a tray. At the 2014 interpack trade fair, there were two copies of our machines. The Capper had been photographed and recreated. On the outside it did indeed look the part, right down to the small details, but I have yet to hear that it actually works. In addition to the mechanics and mechanical engineering, there is also a lot of know-how about the product in the software. That cannot be copied just like that. We have our highly trained people for that.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jo Brand"])}
      },
      {
        "category": 3,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercial Director - employed since 2005"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the development of the Capper"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'The Houdijk Capper was preceded by quite a long development process, but we are now leaders in terms of the technology involved. We knew all about biscuits, but we knew nothing about cream. That is a completely different world. Biscuits can be expressed in millimetres and creams are about temperature, fluidity and pressure. Put a bit of this in and it does this, a bit of that in and it does something else. We had no feeling with that because it cannot be calculated. Like a cook, he has his ingredients and makes a sauce. He needs to add something to make it thicker or smoother. Flexibility is very important here. The market is king, so if people in China want Green Tea cream instead of the white cream from America, they will get Green Tea.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marnus Rotte"])}
      },
      {
        "category": 3,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissioning Engineer - employed since 2018"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the latest version of the Capper"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'The idea of the Capper is that biscuits with a filling in between come out of the machine. We now have the latest version of this Capper. That is the Mark III. What is new and innovative about it compared to the two previous versions is the way the biscuits are turned. The first one had alternating biscuit runs, whereby one was already turned and the other was not. Therefore, even before the Capper with chutes, we had to make sure that one half of what came out of the oven was turned and the other half was not. The version after that did it a bit differently. There, the biscuits were turned around inside the Capper with retaining pins, so no longer outside the machine. That saves some space in the length of the production line, but mainly time. With this, we had solved a major shortcoming of the first version. The only limitation was that turning the products still took too long. In the Capper Mark III, we transport the biscuits with a linear drive and turn and align them in a single process. This has made the machine another 30 per cent faster than the previous version.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sebastiaan Vijfhuizen"])}
      },
      {
        "category": 3,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technology Advisor - employed since 1988"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the Vision Selector"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'The efficiency of a line is actually about preventing disruptions in the production process. The Vision Selector assesses the customer's biscuits and discards the bad ones to prevent the system from jamming. It is of course a shame to throw away products, but if you don't do this, people literally have to dig to free the machine from underneath the biscuits and then clean it all again. Biscuit production takes place in unimaginable quantities. If things go wrong for a moment, you can almost ski down the biscuit mountain. To improve the efficiency of such a line and prevent waste, the Vision Selector was developed. At first, it proved difficult to accurately assess whether an individual biscuit was good or bad with a camera system. Fortunately, this is not always necessary. A biscuit does not taste worse if it is a little askew. The Vision Selector first makes sure that the line doesn't get stuck. We guarantee that what passes through it does not cause a blockage. If one biscuit is stuck on another, which sometimes happens, it would get stuck somewhere anyway. All those other biscuits would want to go through as well and it would be a big mess. With the Vision Selector we then filter out the bad supply. If biscuits of a certain size suddenly come out much larger, the Selector says \"into the bin with you\" so that the whole system doesn't grind to a halt.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jo Brand"])}
      },
      {
        "category": 3,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["R&D Engineer - employed since 2013"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the Cookie Dozer"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'The most recent innovation I've been working a lot on is the Cookie Dozer. That is a new machine, which I hope will be a success in the market. We use a camera to see where the biscuits are on a conveyor belt and pick them up with a Cookiebot. This places the biscuits neatly in the correct pattern on a conveyor belt that goes to the Cookie Dozer. The Cookie Dozer sweeps all those biscuits onto a plastic plate and joins everything together to make a roll, stack or tray. We are now building two lines of these on the workshop floor."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menze Jan Buitenhuis"])}
      },
      {
        "category": 4,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner and Managing Director - employed since 1987"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the reorganisations at Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'When I joined Houdijk in the 1980s, things were not going too well for the company. There was not enough work. To get at least some work, we did assignments that were actually too difficult for us or that did not pay enough money. We were in a real downward spiral at the time. I then announced a reorganisation and decided to focus only on the biscuit activities and to abandon any form of in-house production. The thought process around this took a few years. My thoughts gradually took shape and in the end I reorganised three times. First, twenty people left, then another thirty, and then another ten. In the end, unfortunately, I had to lay off between fifty and sixty people. That was a very emotional time. Despite the circumstances, many people thanked me for their time with Houdijk. They were all professionals and they all found work again. Most of them were able to find work elsewhere before their time with us ended. Ultimately, there were only thirty-five of us left. That was after the last reorganisation, around the year 2000. I find it hard to imagine that now. All in all, there were about ten to fifteen people in engineering, ten in the workshop and the rest were sales and administrative tasks. I do look back on it with a certain nostalgia.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bart Houdijk"])}
      },
      {
        "category": 4,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales Assistant and Management Secretary - employed since 1999"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the reorganisations at Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'There were days when I went to work and not knowing whether there was a red and white ribbon on the door. That was when things were going very badly. Bart called everyone together in the canteen and told them it was sink or swim. I had heard that people were being made redundant and I was the last one to join the company, so I was afraid I would have to leave too. It was very stressful. I still wonder if everyone realised that at the time. That was a very critical point. I think we all had some trouble at the time with Bart's insistence on the biscuits. He didn't want to make any sidesteps into machines for dog biscuits or hamburgers, for example, even though we were asked to do so. But if you then heard the underlying reasons, such as the cleanability of machines working with meat, you were dealing with completely different hygiene requirements than with the dust of dry biscuits. We weren't working on that at the time. Bart stuck to his course and went to the US with Wim van der Schee. There, they won two major contracts in quick succession. I think everyone eventually embraced Bart's ideas. Even in the difficult times, no one left because they no longer trusted him.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marlies Overbeek"])}
      },
      {
        "category": 4,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team Leader Assembly - employed since 1979"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the reorganisations at Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'We've had a few reorganisations and of course that' s been unpleasant. I've experienced three now and they were not the best of times. Bart waited as long as possible, until we were up to our neck in problems. When things got really bad, it was a matter of last-in-first-out. We didn't have much influence on that. Fortunately, things are going well now and I hope it will continue like this.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hugo Sluimer"])}
      },
      {
        "category": 4,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employed between 1971 and 2016"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the reorganisations at Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Circumstances led to a necessary reorganisation. After the reorganisation, Houdijk became a completely different company. We closed down the mechanical department. We still did design and assembly, but before we used to make everything ourselves. Sheet-metal work, construction work, milling and turning. The entire machine was manufactured here in house. We were able to transfer the colleagues who had to leave to other companies as much as possible. Even when times became difficult later, we were able to relocate our people.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jan Joost Hooimeijer"])}
      },
      {
        "category": 4,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner and Managing Director - employed since 1987"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the name Houdijk Holland"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'I thought \"Machinefabriek Houdijk\" sounded so corny, so, at some point, I changed the trading name. I remember in the 1990s there was a company in Vlaardingen called Fontein Holland, which always sounded good and had an international ring to it. Then we became Houdijk Holland and that even has an alliteration.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bart Houdijk"])}
      },
      {
        "category": 4,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team Leader Commissioning - employed since 1987"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the first assignment in North America"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'When I first started, we were still mainly working in Europe. I remember very well that when I had only been here for six months, they asked me: \"Have you ever been on an airplane?\" No, I hadn't. \"Well, then you can fly tomorrow.\" I went to England for some projects. In 1989, we went overseas to America for the first time. I had only just started working here, but I was one of the lucky ones who got to go. I went to Chicago. We were welcomed there by the client, a real family business. They looked after us, arranged for a rental car and a hotel, as well as the work in the factory. We ended up spending five weeks there, from installation to completion. That was quite an experience.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edward van Oostende"])}
      },
      {
        "category": 4,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercial Director - employed since 2005"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About assignments in America"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'We had a stumbling start, but then a large American biscuit manufacturer came up with a reinvestment programme in which they wanted to optimise their production lines worldwide. They were mainly talking to the somewhat larger packaging companies who they thought could make that happen, but they couldn't. At some point, they invited us and I went with Bart to Switzerland. There we met a delegation of people who already wanted to leave because they thought we were too small to help them. Bart and I were prepared for that and we knew that we were certainly not too small. We are the biggest in our field. After many meetings, we finally convinced them of that and we got the contract for the Oreo lines and more. That contract boosted our ability to invest, which we did. That success also created a kind of snowball effect. When we tell people that we supply to the big players, it helps us with other manufacturers. In the course of one year, I had to fly between China and Mexico maybe four or five times. My wife would give me a small suitcase at the airport with some new clothes and I would be on my way again. That's also part of sales.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marnus Rotte"])}
      },
      {
        "category": 4,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Former employee of Verkade/United-Biscuits"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the relationship between Verkade and Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'I have to dig deep in my memory to find out whether we ever hired a competitor of Houdijk's. I think we did once, but the truth is that it never went down well. We experienced very bad financial times at Verkade and we had to watch our pennies. You just went for the cheapest option, but that wasn't the best option. We also sometimes bought from others, purely because of Houdijk's delivery times. Because things were going so well in recent years, the delivery times were also long and we couldn't wait for that. We hated that because we wanted to do business with Houdijk. If it had been possible, there would have been Houdijk everywhere, I'm convinced of that.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerard Langver"])}
      },
      {
        "category": 4,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managing Director SNL Group Schijndel, supplier to Houdijk"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Bart Houdijk's qualities"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'In my opinion, Bart mainly gets satisfaction from becoming ever more professional, achieving good results, not only in money but also in nice production lines and serving his customers well. He is someone who continuously improves, and that is something I share with him. We get satisfaction from seeing what has been added in one year and how the business has developed. It is like planting a seed and then seeing it grow. I always say: \"if you interfere too much with the plant, it will die, and if you stand above it with an umbrella, it won't grow either\". Bart always does that well. He is very result-oriented, but still reasonable and human.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frans Lammers"])}
      },
      {
        "category": 4,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner and Managing Director - employed since 1987"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Houdijk's development"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'My father started with the production lines for many biscuits per minute, but they were all of one type. Around 2010, we added the Capper, the sandwich machines. That was also intended for the biscuit industry, so the same customers, the same market. Since 2017, we have also added robot systems. The advantage of these robots is much more flexibility, but the numbers per minute are much lower. The strength lies precisely in that flexibility. Sometimes, customers do not even need faster machines. They want to produce, for example, almond paste cakes in the morning, pink iced fancies in the afternoon and almond boats in the evening. This can be done with the same robots without much conversion time. I see a lot of potential in robot lines in the years to come. We already made robot-like machines, not robots in the pure sense of the word, but robot-like. The step from robot-like to robot is relatively small and we are a smart company, so we can switch quite quickly. In the high-volume market and with the Capper, we are really at the top of the market and we can say that this is the best there is. We cannot do that with the robots because we face strong competition there. Competition that we cannot yet match, but that is just a matter of time.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bart Houdijk"])}
      },
      {
        "category": 4,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercial Director - employed since 2005"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Houdijk's development"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'I have a technical background, but that does not mean that I am technically smart. Jo Brand tests all the engineers here. I would fail miserably, but I do have a intuitive sense of what is possible. Bart is actually a lawyer, but I can tell you that nobody can tell him anything about technical matters. He may not be able to calculate it, but he sees possibilities in what needs to be done. Some people will come up with ten reasons why it shouldn't work, and Bart will say that's too quick an answer: \"think about it for another two days and then we'll see if there's a possibility\". The market is asking you to be innovative. The red line in our programme has been the Telinmatic and Gradomatic. The first Telinmatic ran at about three hundred rusks per minute. We are now running at three thousand Oreos per minute. So, if you'd stayed with that first rusk and not changed anything, you'd now have a T-Ford that nobody wants anymore.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marnus Rotte"])}
      },
      {
        "category": 4,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercial Director - employed since 2005"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Houdijk's development"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Happiness is created by working very hard. You are rewarded for your hard work; it never just falls into someone's lap. Bart is very considerate of both the company and the people. That is one of his qualities. He knows how to gather the right people around him who are loyal to him and enjoy working with him. I like working with him too, because he is a very good ambassador for his own company. A congenial man, so to speak, but one with a very clear vision. When we were small and had little financial leeway, we could do little and everything had to be done in small steps. The moment things start moving in bigger steps, we can also make bigger leaps forward. This has been going very fast for the last five to ten years.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marnus Rotte"])}
      },
      {
        "category": 4,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Former employee Verkade/United-Biscuits"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Houdijk's development"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'It is very difficult to specialise in the biscuit market and biscuit handling. It may sound stupid, transporting biscuits, but to become one of the most important global players in that field is, of course, extremely impressive. Bart may have been doubted at times, but he and the people at Houdijk did a really good job. They now have such beautiful and large premises. It looks really nice. Just before I retired, I did another large project with Houdijk. That turned out really well, so for me it was a wonderful way to end my career.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerard Langver"])}
      },
      {
        "category": 4,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ICT Manager - employed since 2015"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Houdijk's flexibility"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'What I like about this company is that it is still managed as a small company. That sometimes has advantages and sometimes disadvantages, but it is very flexible and the lines are short. If we decide to use other technology, we just do it. In a very large company, things are a bit rigid and it is not easy to adapt to something new. I think everyone here is innovative enough for that. I hope we will be around for the next hundred years.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaap Tempelman"])}
      },
      {
        "category": 4,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technology Advisor - employed since 1988"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About what distinguishes Houdijk from others"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'We design beautiful machines with an extremely high capacity and they can therefore become quite large. The customer also has to clean these large systems. Of course, we do our best to design every part in such a way that it is as easy as possible to clean, but when this has to be done during production, panic can set in. The supervisor is screaming that production must continue and that the operator must solve this quickly. You then sometimes see people walking on the machine to reach a certain spot. That is, of course, not the intention, but it is the practice. Because we take this into account, we distinguish ourselves from many competitors.<br><br>'We do not only think about how a biscuit can be processed or could cause problems, but also about how the system is used and sometimes abused by the operating personnel. That is something that really sets us apart. We put a lot of effort into that and we accept that quality comes at a cost. We want the customer to buy a good product and come back for more later.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jo Brand"])}
      },
      {
        "category": 4,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technology Advisor - employed since 1988"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Houdijk's future"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'I think we definitely have a future in continuing what we do now. We deliver good quality geared to the world of baking and we think about cleaning, maintenance, costs in the longer term, further developments, you name it. These are real areas in which we contribute to bakers and there are few companies that specialise in this. There is plenty of scope for this in the future. New markets are also emerging all the time in countries where the level of prosperity is rising and automation is taking hold.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jo Brand"])}
      },
      {
        "category": 4,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager Operations - employed since 2015"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Houdijk's future"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'The robots we make now are the future. That may be a break with the conventional, but that is what the customer needs. They offer a lot of flexibility, particularly for smaller bakeries. Eventually, we will also turn them into high-volume lines by simply installing several rows of robots, but that cannot really compete with the dedicated lines in terms of processing numbers. I believe that we can do more with robots. It is also not inconceivable that we can tap into another market, because it is a certain technology that can be applied to many other products. Its flexibility is truly the future.'"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["John Kasbergen"])}
      }
    ]
  }
}