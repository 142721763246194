import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { Swiper } from 'swiper';

import { IMemoryItem } from '@/types/memories.interfaces';

interface IContents {
    author?: {
        image_url: string;
        name: string;
        title: string;
    };
    image?: {
        caption?: string;
        height: string;
        url: string;
        width: string;
    };
    text?: string;
    type: string;
    video?: string;
}

interface ITimelineItem {
    category: number;
    contents: IContents[];
    description: string;
    hero: {
        caption: string;
        image_url: string;
    };
    id: number;
    image: string;
    title: string;
    year: string;
}

interface ICategory {
    id: number;
    title: string;
}

const activeCategoryId = ref<number | null>(null);
const slidesSwiper = ref<Swiper>();
const timelineSwiper = ref<Swiper>();

const useTimeline = () => {
    const { tm } = useI18n();

    const categories = computed(() => tm('categories') as ICategory[]);

    const filteredItems = computed(() => {
        const items = tm('items') as ITimelineItem[];
        const cachedActiveCategory = activeCategoryId.value;

        if (cachedActiveCategory === null) {
            return items;
        }

        return items.filter((item) => item.category === cachedActiveCategory);
    });

    const filteredMemories = computed(() => {
        const items = tm('memory_items.items') as IMemoryItem[];
        const cachedActiveCategory = activeCategoryId.value;

        const filteredMemoriesByCategory =
            cachedActiveCategory === null
                ? items
                : items.filter((item) => item.category === cachedActiveCategory);

        const filteredMemoriesGroupedByTitle = filteredMemoriesByCategory.reduce(
            (group, memory) => {
                const { title } = memory;
                // eslint-disable-next-line no-param-reassign
                group[title] = group[title] ?? [];
                group[title].push(memory);
                return group;
            },
            {} as { [key: string]: IMemoryItem[] },
        );

        return filteredMemoriesGroupedByTitle;
    });

    const activeCategory = computed(() => {
        if (activeCategoryId.value === null) return null;

        return categories.value.find((category) => category.id === activeCategoryId.value);
    });

    return {
        activeCategory,
        activeCategoryId,
        categories,
        filteredItems,
        filteredMemories,
        slidesSwiper,
        timelineSwiper,
    };
};

export default useTimeline;
export { ITimelineItem };
