export default {
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over Houdijk Holland"])},
  "about_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Houdijk Holland bestaat in 2022 honderd jaar. Op 1 april 1922 richtte Wijnand Houdijk sr. de 'Reparatie-inrichting en Machinefabriek W. Houdijk' op in Vlaardingen. In de eerste jaren houdt Houdijk zich vooral bezig met de reparatie van vissersschepen en machines. Zo'n vijfentwintig jaar na de oprichting, treden de drie zonen van Wijnand sr. - Wijnand jr., Joop en Aad - toe tot het familiebedrijf. Onder hun leiding ontpopt Houdijk zich tot een allround machinefabriek, waar voor elke automatiseringsvraag een machine wordt gebouwd. Het bedrijf zet in de jaren zestig zijn eerste stappen in de biscuit handling en de machines van Houdijk zijn door heel Europa te vinden. <br><br>Eind jaren tachtig neemt Bart Houdijk het stokje van zijn vader Joop over. Hij doopt het familiebedrijf om tot Houdijk Holland en specialiseert het bedrijf compleet in de engineering van handling systemen voor fabrikanten van koekjes, kaakjes en crackertjes van over de hele wereld. Honderd jaar na de oprichting, is Houdijk Holland uitgegroeid tot de wereldwijde marktleider in de biscuit handling. Ondanks deze groei, koestert Houdijk nog altijd de informele sfeer van het familiebedrijf. <br><br>Meer informatie vindt u op <a href='https://houdijk.com' target='_blank' rel='nofollow noreferrer noopener' class='underline'>Houdijk.com</a>"])},
  "back_to_timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar de tijdlijn"])},
  "back_to_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar het begin"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluit"])},
  "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disclaimer"])},
  "disclaimer_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Juridische informatie</strong><br>De Houdijk 100 website is eigendom van Houdijk Holland. Op alle content van de Houdijk 100 website berust copyright met alle rechten voorbehouden. U mag een of meerdere pagina's en/of gedeelten van de website downloaden of afdrukken, op voorwaarde dat u geen copyright- of andere eigendomsvermeldingen verwijdert. Het downloaden of anderszins kopiëren van de content van de website geeft u geenszins eigendomsrecht daarover. U mag de website of de inhoud daarvan niet reproduceren, verzenden (elektronisch of anderszins), wijzigen of gebruiken voor openbare of commerciële doeleinden zonder voorafgaande schriftelijke toestemming van Houdijk Holland. De informatie en de meningen op de Houdijk 100 website worden verstrekt zonder enige vorm van garantie, expliciet dan wel impliciet. Voor vragen en suggesties kunt u contact opnemen met onze webmaster via <a href=\"mailto:info&#64;houdijk.com\">info&#64;houdijk.com</a>."])},
  "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter op thema"])},
  "filtered_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gefilterd op: ", _interpolate(_named("category"))])},
  "memories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herinneringen"])},
  "memories_navigate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk herinneringen van medewerkers"])},
  "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volgende"])},
  "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorige"])},
  "privacy_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Houdijk 100 website verzamelt geen enkel persoonsgegeven van u. Als u gegevens aan Houdijk Holland verstrekt, worden deze verwerkt in overeenstemming met de Algemene Verordening Gegevensbescherming. Houdijk Holland gebruikt deze gegevens enkel voor de specifieke doeleinden waarvoor u die aanlevert. Uw gegevens worden slechts zo lang bewaard als nodig is voor het doel waarvoor ze zijn verzameld. Houdijk Holland zal uw gegevens niet aan derden ter beschikking stellen tenzij daartoe een wettelijke verplichting bestaat, tenzij dit een verwerker is in dienst van Houdijk Holland of voordat u geïnformeerd bent en toestemming heeft gegeven voor het verstrekken van uw gegevens. Zie de specificatie hieronder voor meer informatie. Als u wilt opvragen welke gegevens wij van u hebben, kunt u ons daarvoor een schriftelijk verzoek sturen. Mochten bepaalde gegevens niet juist blijken te zijn, dan kunnen wij deze voor u corrigeren. Ook kunt u ons vragen de verwerking van uw gegevens te beperken of uw gegevens te verwijderen. U kunt op elk moment een verzoek tot gegevensopname indienen."])},
  "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])},
  "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lees meer"])},
  "scan_qrcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan de QR-code met uw mobiele telefoon om de tijdlijn te bedienen."])},
  "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toon"])},
  "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijdlijn"])},
  "categories": [
    {
      "id": null,
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles"])}
    },
    {
      "id": 1,
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100 jaar familiebedrijf"])}
    },
    {
      "id": 2,
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100 jaar op de werkvloer"])}
    },
    {
      "id": 3,
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100 jaar innovatie"])}
    },
    {
      "id": 4,
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100 jaar koers van de onderneming"])}
    }
  ],
  "items": [
    {
      "id": 1,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/1.1.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De oprichting"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Met een van zijn vrouw Johanna geleend startkapitaal van 345 gulden, richtte Wijnand Houdijk sr. op 1 april 1922 de 'Machinefabriek en Reparatie-inrichting W. Houdijk' op. Wijnand sr., geboren in 1890 te Oudewater, had al ruime ervaring als technicus en leidinggevende toen hij op 32-jarige leeftijd eigen baas werd. Van leerling-bankwerker in een machinefabriek klom hij op tot chef van de werkplaats van het staalconstructiebedrijf Hollandia. Na een tijd als bedrijfsleider bij Scheepswerf Van Dam te hebben gewerkt, besloot hij voor zichzelf te beginnen"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1922"])},
      "category": 1,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/1.1.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De medewerkers van de Reparatie-inrichting en Machinefabriek W. Houdijk aan de Maasstraat. Van links naar rechts: Daan Hartman, Wijnand Houdijk sr., Jan de Graaf, Jan Versteege, Henk Henneveld, Nardus Versteege en Willem van der Schee. 1922."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/1.1.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Met een van zijn vrouw Johanna geleend startkapitaal van 345 gulden, richtte Wijnand Houdijk sr. op 1 april 1922 de 'Machinefabriek en Reparatie-inrichting W. Houdijk' op. Wijnand sr., geboren in 1890 te Oudewater, had al ruime ervaring als technicus en leidinggevende toen hij op 32-jarige leeftijd eigen baas werd. Van leerling-bankwerker in een machinefabriek klom hij op tot chef van de werkplaats van het staalconstructiebedrijf Hollandia. Na een tijd als bedrijfsleider bij Scheepswerf Van Dam te hebben gewerkt, besloot hij voor zichzelf te beginnen."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijnand sr. vond een werkplek in de kuiperij van Jan Hoogendorp in de Maasstraat in Vlaardingen. Met een afmeting van 7 bij 4 meter en een zoldertje voor opslag was het niet ruim. Samen met zijn eerste werknemer Jan Versteege - bijgenaamd Dikke Jan - richtte hij de werkplaats in met een aantal draai- en schaafbanken. De eerste dagen verliepen goed. Zo goed zelfs, dat Wijnand sr. na een week ook Jans jongere broertje Bernardus Versteege aannam, kort daarna gevolgd door Willem van der Schee. Zij zouden zich de rest van hun werkzame leven voor Houdijk blijven inzetten. Binnen een jaar wist Wijnand sr. de lening aan zijn vrouw alweer terug te betalen."])}
        }
      ]
    },
    {
      "id": 2,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/2.1.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verhuizing naar de Van Leyden Gaelstraat"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drie jaar na de oprichting groeide de 'Machinefabriek en Reparatie-inrichting W. Houdijk' al uit haar voegen. In 1925 verruilde Wijnand sr. de werkplaats aan de Maasstraat voor een voormalige kolenopslag met twee verdiepingen aan de 1e van Leyden Gaelstraat, een paar honderd meter verderop."])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1925"])},
      "category": 3,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/2.1.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drie jaar na de oprichting groeide de 'Machinefabriek en Reparatie-inrichting W. Houdijk' al uit haar voegen. In 1925 verruilde Wijnand sr. de werkplaats aan de Maasstraat voor een voormalige kolenopslag met twee verdiepingen aan de 1e van Leyden Gaelstraat, een paar honderd meter verderop."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Machinefabriek W. Houdijk aan de 1e van Leyden Gaelstraat. Jaren 1930"])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/2.1.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Op de begane grond plaatsten Wijnand sr. en zijn medewerkers de draai- en schaafbanken, platenwals en smederij. Boven waren de kantoren, kantine en later de tekenkamer en keuken te vinden. In een schuur op het achtererf lagen de stalen platen en buizen voor de machines opgeslagen."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Op een Ford TT werd een draaibank afgeleverd bij de Machinefabriek. 1925."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/2.2.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In tegenstelling tot wat de huidige bestemmingsplannen zouden toelaten, stonden in dezelfde straat als de Machinefabriek toentertijd ook woonhuizen. De rookwalmen en fabrieksgeuren waren volgens oud-medewerkers al van afstand te ruiken."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De smederij op de begane grond van de Machinefabriek. 1939"])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/2.3.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        }
      ]
    },
    {
      "id": 3,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/3.1.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goede reputatie in crisistijd"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toen Wijnand sr. in 1922 de 'Machinefabriek en Reparatie-inrichting W. Houdijk' begon, waren het snoep- en fondantfabriek De Valk en bakker Simon Niemandsverdriet uit de 1e van Leyden Gaelstraat die als eersten bij hem aanklopten voor reparatiewerk. Kort daarna wisten ook Scheveningse en Katwijkse vissers de reparatiediensten van 'Wijnand de Maasstraat', zoals het bedrijf onder hen bekend stond, te vinden. Wanneer schippers met pech of technische mankementen aan hun schepen zich bij Wijnand sr. meldden, ging de Houdijk-ploeg aan boord om de reparatie uit te voeren. Wijnand sr. zag echter in dat de visserij zich langzaam maar zeker uit Vlaardingen verplaatste en besefte dat hij ook op zoek moest naar andere markten."])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ca. 1930"])},
      "category": 4,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/3.1.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toen Wijnand sr. in 1922 de 'Machinefabriek en Reparatie-inrichting W. Houdijk' begon, waren het snoep- en fondantfabriek De Valk en bakker Simon Niemandsverdriet uit de 1e van Leyden Gaelstraat die als eersten bij hem aanklopten voor reparatiewerk. Kort daarna wisten ook Scheveningse en Katwijkse vissers de reparatiediensten van 'Wijnand de Maasstraat', zoals het bedrijf onder hen bekend stond, te vinden. Wanneer schippers met pech of technische mankementen aan hun schepen zich bij Wijnand sr. meldden, ging de Houdijk-ploeg aan boord om de reparatie uit te voeren. Wijnand sr. zag echter in dat de visserij zich langzaam maar zeker uit Vlaardingen verplaatste en besefte dat hij ook op zoek moest naar andere markten."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reparateurs van Houdijk aan de slag op een vissersboot in de Vlaardingse haven. Jaren 1930."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/3.1.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dat was geen eenvoudige opgave. De Amerikaanse Beurskrach in 1929 veroorzaakte ook in Nederland een jarenlange economische crisis met honderdduizenden werklozen als gevolg. Toch kwam Houdijk de woelige jaren 1930 vrijwel ongeschonden door. Dit kwam ten eerste door de inzet van de onvermoeibare Wijnand sr., die dagenlang verschillende bedrijven binnen en buiten de regio bezocht - tot de scheepswerven in de noordelijke provincies aan toe - om opdrachten binnen te halen. Dit deed hij in de volle overtuiging dat 'een vijfguldenklant altijd nog een vijfduizendguldenklant kon worden'."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijnand Houdijk sr. in zijn kantoor aan de 1e van Leyden Gaelstraat. 1939."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/3.2.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wat ook hielp was de goede reputatie die het bedrijf in tien jaar tijd had opgebouwd. Vooral in Vlaardingen kende hij veel mensen en andersom kenden ook veel mensen hem. Na de simpele vraag 'van wie ben jij er eentje' die Wijnand sr. steevast stelde, konden handige Vlaardingse jongens direct bij Houdijk aan de slag voor reparatie-, machine- en automatiseringswerk. Joop Houdijk, middelste zoon van Wijnand sr., verklaarde hierover later in zijn memoires: 'De crisisjaren zijn zij door hard en dikwijls lang werken, mede dankzij de zeer goede medewerkers die zich met hart en ziel voor de zaak inzetten, betrekkelijk goed doorgekomen.'"])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handige jongens konden vrijwel direct vanuit school bij de Machinefabriek aan de slag. 1939."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/3.3.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        }
      ]
    },
    {
      "id": 4,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/4.1.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oplossingsgericht: de warmwaterketel"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dat de 'Machinefabriek en Reparatie-inrichting W. Houdijk' van meet af aan een innovatief bedrijf was, blijkt wel uit het warmwaterketeltje voor kruideniers, dat Houdijk in de jaren 1930 ontwikkelde. In die jaren hadden nog maar weinig gezinnen thuis warm water. Voorlopers van de boiler en de cv-ketel bestonden al wel, maar waren zo duur, dat een doorsnee Nederlands huishouden die niet kon betalen. Dat was in Vlaardingen niet anders, maar warm water was echter wel nodig voor bijvoorbeeld het wasgoed. Voor een aantal Vlaardingse kruideniers bouwde Wijnand sr. een warmwaterketel van 3 meter hoog en met een diameter van 1,5 meter. Onderin de ketel kon de kruidenier zijn afval kwijt om het te verstoken, zodat het waterreservoir daarboven opwarmde. Op zondagavond brandde een kruidenier dan zijn afval weg, om vroeg in de ochtend daarna Vlaardingse huisvrouwen tonnetjes met warm water voor een paar guldencent te verkopen. Dit sloeg zo goed aan, dat de gemeente haar lokale wetgeving rondom openingstijden aanpaste. Kruideniers mochten hun winkels 's maandags eerder openen om naast warm water ook bepaalde huishoudelijke artikelen als zeep en schrobmiddelen te verkopen."])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ca. 1935"])},
      "category": 2,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/4.1.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Op straat langs de Oude Haven stalde Houdijk ketels om reclame te maken voor 'Firma Houdijk'. 1937."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/4.1.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dat de 'Machinefabriek en Reparatie-inrichting W. Houdijk' van meet af aan een innovatief bedrijf was, blijkt wel uit het warmwaterketeltje voor kruideniers, dat Houdijk in de jaren 1930 ontwikkelde. In die jaren hadden nog maar weinig gezinnen thuis warm water. Voorlopers van de boiler en de cv-ketel bestonden al wel, maar waren zo duur, dat een doorsnee Nederlands huishouden die niet kon betalen. Dat was in Vlaardingen niet anders, maar warm water was echter wel nodig voor bijvoorbeeld het wasgoed. Voor een aantal Vlaardingse kruideniers bouwde Wijnand sr. een warmwaterketel van 3 meter hoog en met een diameter van 1,5 meter. Onderin de ketel kon de kruidenier zijn afval kwijt om het te verstoken, zodat het waterreservoir daarboven opwarmde. Op zondagavond brandde een kruidenier dan zijn afval weg, om vroeg in de ochtend daarna Vlaardingse huisvrouwen tonnetjes met warm water voor een paar guldencent te verkopen. Dit sloeg zo goed aan, dat de gemeente haar lokale wetgeving rondom openingstijden aanpaste. Kruideniers mochten hun winkels 's maandags eerder openen om naast warm water ook bepaalde huishoudelijke artikelen als zeep en schrobmiddelen te verkopen."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een lasser werkte aan de warmwaterketel voor een Vlaardingse kruidenier. 1939."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/4.2.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        }
      ]
    },
    {
      "id": 5,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/5.1.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onderduiken en doorwerken"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrijdagochtend 10 mei 1940. De meeste Vlaardingers sliepen nog toen Hitlers Wehrmacht hun stad binnenviel. Duitse bommenwerpers gooiden het gebied rondom de Kortedijk in het noorden van Vlaardingen plat, terwijl de inwoners de straat of hun dak op gingen om te zien wat er gebeurde. Op 14 mei, nadat het centrum van de buurstad Rotterdam door bombardementen verwoest werd en de met verder geweld bedreigde Nederlandse overheid capituleerde, was de Duitse bezetting een feit. Voor Houdijk had de bezetting al direct een negatieve consequentie. De reparatiewerkzaamheden aan vissersboten, waar ze al bijna twintig jaar een goede boterham mee verdienden, viel compleet weg. De Duitsers vorderden namelijk zo goed als alle Nederlandse vissersschepen om ze in te zetten voor het vrachtverkeer tussen nazi-Duitsland en Scandinavië."])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1940-1945"])},
      "category": 1,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/5.1.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrijdagochtend 10 mei 1940. De meeste Vlaardingers sliepen nog toen Hitlers Wehrmacht hun stad binnenviel. Duitse bommenwerpers gooiden het gebied rondom de Kortedijk in het noorden van Vlaardingen plat, terwijl de inwoners de straat of hun dak op gingen om te zien wat er gebeurde. Op 14 mei, nadat het centrum van de buurstad Rotterdam door bombardementen verwoest werd en de met verder geweld bedreigde Nederlandse overheid capituleerde, was de Duitse bezetting een feit. Voor Houdijk had de bezetting al direct een negatieve consequentie. De reparatiewerkzaamheden aan vissersboten, waar ze al bijna twintig jaar een goede boterham mee verdienden, viel compleet weg. De Duitsers vorderden namelijk zo goed als alle Nederlandse vissersschepen om ze in te zetten voor het vrachtverkeer tussen nazi-Duitsland en Scandinavië."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Korte Hoogstraat achter de Kortedijk lag in puin als gevolg van het Duitse bombardement. 1940. <br>Bron: fotocollectie Stadsarchief Vlaardingen."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/5.1.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Machinefabriek bleef draaien, maar eenvoudig ging dat niet. Voor de familie Houdijk waren het spannende tijden. Nelly Houdijk, dochter van Wijnand sr., was actief in het verzet. Ondanks dat hier de doodstraf op stond, hielp zij niet alleen Joodse gezinnen onderduiken, maar ook andere hulpbehoevenden aan een veilig onderkomen. Lijnrecht daartegenover stond haar oom Adrianus, de broer van Wijnand sr., die lid was van de NSB. Wijnand sr. zelf had een uitgesproken hekel aan nazi's en de Jodenvervolging, maar nog meer aan NSB'ers, die hij stuk voor stuk zag als verraders."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secretaresse Nettie Sterk, Aad Houdijk en Nelly Houdijk op het achterterrein van de Machinefabriek. 1939."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/5.2.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijnand sr. kwam er echter niet onderuit om toch voor de bezetter te werken. Dit deed hij in ruil voor zogenoemde 'ausweisen', werkvergunningen waarop vermeld stond waar en wanneer iemand werkte. De eerste oorlogsjaren voorkwam Wijnand sr. hiermee dat zijn personeel naar Duitsland werd afgevoerd om daar tewerkgesteld te worden. In 1943 veranderde er veel voor de Machinefabriek van Houdijk. De Duitsers eisten dat de machines en gereedschappen naar Duitsland getransporteerd werden, maar dat wist Wijnand sr. te voorkomen door ze te verstoppen in een lokale ijsfabriek. Minder geluk hadden Houdijk en zijn medewerkers toen er in Duitsland een steeds groter tekort aan arbeiders ontstond. Ondanks de 'ausweisen' verplichtten de Duitsers een aantal jongens van Houdijk om in Duitsland te werken."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een van die jongens was Wijnands zoon: Joop Houdijk. Het gezin zette alles op alles om zijn deportatie naar Duitsland te voorkomen. Op een meidag in 1943 fietste Joop naar de Machinefabriek om zijn vader te bezoeken toen hij plotseling van een oudere man een briefje in zijn handen geduwd kreeg. Op het briefje stond een adres en de opdracht daar direct naartoe te gaan. Zonder afscheid te kunnen nemen vertrok Joop naar de Sarijnenhove, een boerderij op het platteland ten westen van Vlaardingen, waar hij tot het einde van de oorlog werkte als boerenknecht. 'Ik hield van het boerenwerk, maar de angst gegrepen te worden vergezelde je altijd. Als onderduiker leefde je doorlopend onder spanning', schreef Joop in zijn memoires."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duitse soldaten poseerden langs de Westhavenkade in het bezette Vlaardingen. 1940. Bron: fotocollectie Jan Anderson."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/5.3.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De laatste maanden van de oorlog waren zwaar voor Houdijk en de Machinefabriek. Wijnands zoon zat ondergedoken, een paar medewerkers waren naar Duitsland afgevoerd en een goed deel van de werktuigen was verstopt. Wijnand huurde onervaren Zeeuwen in om gasklokken en kacheltjes te maken, terwijl hijzelf buiten werkte voor reparaties in ruil voor voedsel. Op 4 mei 1945 kwam het verlossende bericht en gaf de Duitse legerleiding in Nederland zich over. Het gedeporteerde personeel keerde terug, de werkbanken, machines en andere werktuigen werden weer op hun plaats gezet en de wederopbouw kon beginnen."])}
        }
      ]
    },
    {
      "id": 6,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/6.1.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De komst van de drie broers"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na afloop van de Tweede Wereldoorlog pakte Wijnand sr. stevig door. Hij investeerde in stoomkuiperijen en vatenpersen, en nam een constructietekenaar in dienst. Zijn oudste zoon, Wijnand junior, kwam in het bedrijf te werken, gevolgd door Aad - de jongste - in 1947 en middelste zoon Joop in 1950. De drie broers namen ieder de verantwoordelijkheid voor een ander deel van het bedrijf op zich. Joop richtte zich op de technische en commerciële kant, Aad deed de boekhouding en Wijnand jr. gaf leiding aan de werkplaats en onderhield contacten met lokale bedrijven. Oudgediende Wim van der Schee: 'Joop Houdijk was niet op zijn mond gevallen en had best bravoure. Wijnand was de techneut van de familie en vond het wel prima dat Joop de menselijke kant regelde. Vanwege astma was Aad niet zo vaak op de fabrieksvloer te vinden.' Tot 1946 werkte hun zus Nelly ook in het familiebedrijf, maar tragisch genoeg overleed zij al op jonge leeftijd aan de gevolgen van een hartklepziekte."])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1947"])},
      "category": 1,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/6.1.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groepsfoto ter gelegenheid van het 25-jarig jubileum van de Machinefabriek W. Houdijk. 1947."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/6.1.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na afloop van de Tweede Wereldoorlog pakte Wijnand sr. stevig door. Hij investeerde in stoomkuiperijen en vatenpersen, en nam een constructietekenaar in dienst. Zijn oudste zoon, Wijnand junior, kwam in het bedrijf te werken, gevolgd door Aad - de jongste - in 1947 en middelste zoon Joop in 1950. De drie broers namen ieder de verantwoordelijkheid voor een ander deel van het bedrijf op zich. Joop richtte zich op de technische en commerciële kant, Aad deed de boekhouding en Wijnand jr. gaf leiding aan de werkplaats en onderhield contacten met lokale bedrijven. Oudgediende Wim van der Schee: 'Joop Houdijk was niet op zijn mond gevallen en had best bravoure. Wijnand was de techneut van de familie en vond het wel prima dat Joop de menselijke kant regelde. Vanwege astma was Aad niet zo vaak op de fabrieksvloer te vinden.' Tot 1946 werkte hun zus Nelly ook in het familiebedrijf, maar tragisch genoeg overleed zij al op jonge leeftijd aan de gevolgen van een hartklepziekte."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ondanks het aantreden van zijn zoons, bleef Wijnand sr. doorwerken in het familiebedrijf. 1954."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/6.2.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het werd voor de medewerkers van Houdijk al snel duidelijk dat de drie broers het stokje van hun vader zouden overnemen, maar dat stokje werd de broers niet zomaar overhandigd. Van der Schee : 'Het was niet zomaar van hier heb je het. Ze moesten echt bikkelen. Ze gingen bijvoorbeeld gewoon mee als hulpje, of dat nu vies werk was of niet. Dat was dan jammer.' Die aanpak werkte als een goede leerschool voor de onderlinge samenwerking. 'Zolang we samenwerkten hebben we nooit onenigheid gehad, ook niet met vader', schreef Joop. Wijnand sr. bleef nog tot in de jaren 1960 samen met zijn drie zoons in het familiebedrijf werken. Gezondheidsklachten dwongen Wijnand jr. in 1980 vroegtijdig met pensioen te gaan en kort daarna trok ook Aad zich terug uit het bedrijf. Van de drie broers bleef alleen Joop over, tot hij in 1987, eveneens vanwege gezondheidsklachten, afscheid moest nemen van het familiebedrijf."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De familie Houdijk op een huwelijksjubileum met op de bovenste rij de drie broers. Van links naar rechts Aad, Joop en Wijnand jr. Jaren '60."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/6.3.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        }
      ]
    },
    {
      "id": 7,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/7.3.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitbreiding van de fabriek"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In de jaren na de Tweede Wereldoorlog was de tijd rijp om de Machinefabriek aan de 1e van Leyden Gaelstraat flink uit te breiden. Eerst verleende de gemeente toestemming om de steeg naast de fabriek te overkappen, gevolgd door het opkopen van twee aangrenzende huizen die werden omgebouwd tot montagehal."])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1955"])},
      "category": 3,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/7.3.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het pand van de Machinefabriek aan de 1e van Leyden Gaelstraat. Begin jaren '50."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/7.1.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In de jaren na de Tweede Wereldoorlog was de tijd rijp om de Machinefabriek aan de 1e van Leyden Gaelstraat flink uit te breiden. Eerst verleende de gemeente toestemming om de steeg naast de fabriek te overkappen, gevolgd door het opkopen van twee aangrenzende huizen die werden omgebouwd tot montagehal."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Machinefabriek werd uitgebreid met de naastgelegen steeg en twee huizen. 1954."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/7.2.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ondanks deze nieuw verworven oppervlakte, bleken de uitbreidingen al snel niet meer toereikend. De 'Eerste Vlaardingse Verffabriek', net naast de Machinefabriek gelegen, werd regelmatig geplaagd door branden. In 1955 brak er weer brand uit en werd 'nog tijdens de brand van de verffabriek het hele zaakje overgedaan aan Houdijk', aldus een journalist van het Vlaardings Dagblad. Niet lang daarna werd ook een hal van de 'Verenigde Touwfabrieken' aan de achterzijde van de Machinefabriek overgenomen. In korte tijd verdubbelde het werkoppervlak van het bedrijf en kon het er weer decennia tegenaan."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na de verffabriek te hebben opgekocht, verdubbelde het werkoppervlak van de Machinefabriek. 1957"])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/7.3.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veel oud-medewerkers van Houdijk kunnen zich de Machinefabriek aan de 1e van Leyden Gaelstraat nog goed herinneren. Jaap van Ree: 'Toen ik ging werken in 1950 was het net vijf jaar na de oorlog. Je had niet veel, behalve een duimstok en een zelfgemaakt krukje om op te schaften. Achterin de zaak stond een kacheltje, maar 's winters was het er alsnog erg koud.' Voor Arie-Hans Houdijk, Joops oudste zoon, waren het vooral de geuren die hem bijstonden van zijn vakantiewerk in de fabriek. 'De geuren van olie en smeermiddelen, van verbrand ijzer vanwege het lassen, frezen en boren. Er hing een hele specifieke en herkenbare geur. Het was vies en ruw werk dat daar gedaan werd. Mannen met vieze handen en overalls die zwart zagen van de olie en de smeer."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na de Tweede Wereldoorlog draaiden de machines op de werkvloer van de Machinefabriek weer op volle toeren. Jaren '50."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/7.4.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        }
      ]
    },
    {
      "id": 8,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/8.1.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eerste stap in biscuithandling: de Telinmatic"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eind jaren 1950 was Houdijk al vaak voor reparatiewerk aan de machines bij de beschuitfabrikant Hooimeijer in Barendrecht over de vloer geweest. In 1960 klopte Hooimeijer aan voor een ander verzoek. Zij broedden al een tijd op het idee voor een soort sjoelbak die hun beschuitjes automatisch vanuit de oven sorteerde om verpakt te kunnen worden. Aan Houdijk vroegen zij dat concept verder uit te werken en werkelijkheid te maken. De ontwerpafdeling van Houdijk onder leiding van Arie Gog ging ermee aan de slag en kwam met de 'Telinmatic,' een machine die de brosse beschuitjes met een luchtbedsysteem lichtelijk omhoog blies en zo in de juiste banen leidde. Dit was een doorslaand succes."])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1962"])},
      "category": 2,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/8.1.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hooimeijer adverteerde haar breukvrije ribbelverpakking in kranten door heel Nederland. 1957."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/8.1.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eind jaren 1950 was Houdijk al vaak voor reparatiewerk aan de machines bij de beschuitfabrikant Hooimeijer in Barendrecht over de vloer geweest. In 1960 klopte Hooimeijer aan voor een ander verzoek. Zij broedden al een tijd op het idee voor een soort sjoelbak die hun beschuitjes automatisch vanuit de oven sorteerde om verpakt te kunnen worden. Aan Houdijk vroegen zij dat concept verder uit te werken en werkelijkheid te maken. De ontwerpafdeling van Houdijk onder leiding van Arie Gog ging ermee aan de slag en kwam met de 'Telinmatic,' een machine die de brosse beschuitjes met een luchtbedsysteem lichtelijk omhoog blies en zo in de juiste banen leidde. Dit was een doorslaand succes."])}
        },
        {
          "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een Telinmatic op de fabrieksvloer van Hooimeijer deed na dertig jaar nog trouw dienst. 1994."])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["video"])},
          "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/zPD39MIawL8"])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al snel verkocht Houdijk deze machine ook aan beschuitfabrikanten in de rest van Nederland en zelfs over de grens in België en Duitsland. Die beschuitfabrikanten produceerden vaak ook biscuits en koekjes, waardoor de vraag naar gelijksoortige machines voor bijvoorbeeld sandwichbiscuits, Pims of Jaffa Cakes en crackers ontstond. Door hun oplossingsgerichtheid kon Houdijk ook aan die vraag voldoen. De Telinmatic bleef nog decennialang een van de best verkopende machines van Houdijk."])}
        },
        {
          "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Op de Interpack-beurs in Duitsland maakte Houdijk reclame voor zijn biscuithandlingmachines. Jaren '90."])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["video"])},
          "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/4ox17zTHrrQ"])}
        }
      ]
    },
    {
      "id": 9,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/9.2.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Voor iedereen een eigen machine”"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In de jaren 1960 en 1970 werden de opdrachten voor Houdijk steeds complexer. Eenvoudige automatiseringen waren verleden tijd en de creativiteit van de Machinefabriek werd steeds meer op de proef gesteld. Zoals ook het geval was met de Telinmatic, klopten klanten bij Houdijk aan met een vraag voor uiteenlopende en vernieuwende machines en installaties. Oud-tekenaar Koos Kesseboom keek hier met plezier op terug: 'Het was altijd aangepast of nieuw, altijd wat anders. Dat waren hele leuke dagen en was heel interessant.' Zo rolde er uit de Machinefabriek bijvoorbeeld een systeem waarmee potjes warm-gegoten jam gecontroleerd konden afkoelen voor verdere bewerking, maar maakten zij ook persen die de kwaliteit van betonnen trottoirtegels testten. Samen met een grote Nederlandse kaasfabrikant ontwikkelde Houdijk een machine die geautomatiseerd kazen op de plank legde en na verloop van tijd omdraaide. Voor iedere vraag maakte Houdijk dus een eigen machine."])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ca. 1970"])},
      "category": 3,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/9.2.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["video"])},
          "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In de Machinefabriek Houdijk aan de 1e van Leyden Gaelstraat werd dagelijks uiteenlopend technisch werk verricht. Jaren '60."])},
          "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/D-3ywLbwCe8"])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In de jaren 1960 en 1970 werden de opdrachten voor Houdijk steeds complexer. Eenvoudige automatiseringen waren verleden tijd en de creativiteit van de Machinefabriek werd steeds meer op de proef gesteld. Zoals ook het geval was met de Telinmatic, klopten klanten bij Houdijk aan met een vraag voor uiteenlopende en vernieuwende machines en installaties. Oud-tekenaar Koos Kesseboom keek hier met plezier op terug: 'Het was altijd aangepast of nieuw, altijd wat anders. Dat waren hele leuke dagen en was heel interessant.' Zo rolde er uit de Machinefabriek bijvoorbeeld een systeem waarmee potjes warm-gegoten jam gecontroleerd konden afkoelen voor verdere bewerking, maar maakten zij ook persen die de kwaliteit van betonnen trottoirtegels testten. Samen met een grote Nederlandse kaasfabrikant ontwikkelde Houdijk een machine die geautomatiseerd kazen op de plank legde en na verloop van tijd omdraaide. Voor iedere vraag maakte Houdijk dus een eigen machine."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een machine van Houdijk koelde potjes jam gecontroleerd af. Jaren '60."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/9.2.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bijna even populair als de Telinmatic waren de transportsystemen die Houdijk maakte voor het schoonstralen van onbehandelde metalen constructies in straalcabines. Hiervoor werd zand gebruikt, maar de Nederlandse overheid verbood dit in 1960. Het straalmiddel moest hergebruikt kunnen worden, dus stapten metaalbewerkers over op stalen korreltjes. In samenwerking met het Rotterdamse Van Kranenburg, maakte Houdijk een machine die de straalkorrels opving en reinigde, zodat ze opnieuw gebruikt konden worden. Dat andere Europese landen later ook het stralen met zand zouden verbieden, betekende dat Houdijk een grote voorsprong had op de buitenlandse concurrentie, en zijn transportmachines in andere Europese landen kon plaatsen."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Met Van Kranenburg ontwikkelde Houdijk een systeem dat straalkorrels opvangt en reinigt voor hergebruikt. Jaren '70."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/9.3.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        }
      ]
    },
    {
      "id": 10,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/10.1.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkade: een langdurige relatie"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door machines als de Telinmatic en de vele andere die vanuit de Machinefabriek in Vlaardingen naar heel Europa gingen, stond Houdijk steeds beter bekend om zijn vakbekwaamheid en oplossingsgerichtheid. Toen Koninklijke Verkade, toentertijd een van Nederlands grootste biscuit- en koekjesfabrikanten én familiebedrijf, een nieuw soort ovaal koekje bedacht, ontstonden er problemen met het transport tussen hun ovens en de verpakkingsmachine van het Zwitserse bedrijf SIG. Vanwege de bestaande relatie en goede reputatie schakelde SIG in overleg met Verkade de hulp in van Houdijk. Toenmalig projectmanager bij Verkade, Gerard Langver, herinnerde zich: 'Er is toen heel veel “heen-en-weer-geëngineerd”, er zijn machines aangepast, echt een hoofdpijndossier. Door de manier waarop Houdijk dat oppakte met SIG en de engineers van Verkade,  is er een goed werkende automatische verpakkingslijn opgeleverd.'"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1976"])},
      "category": 4,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/10.1.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een medewerkster van Verkade overzag een transportlijn van Houdijk met Maria koekjes. Jaren '70."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/10.1.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door machines als de Telinmatic en de vele andere die vanuit de Machinefabriek in Vlaardingen naar heel Europa gingen, stond Houdijk steeds beter bekend om zijn vakbekwaamheid en oplossingsgerichtheid. Toen Koninklijke Verkade, toentertijd een van Nederlands grootste biscuit- en koekjesfabrikanten én familiebedrijf, een nieuw soort ovaal koekje bedacht, ontstonden er problemen met het transport tussen hun ovens en de verpakkingsmachine van het Zwitserse bedrijf SIG. Vanwege de bestaande relatie en goede reputatie schakelde SIG in overleg met Verkade de hulp in van Houdijk. Toenmalig projectmanager bij Verkade, Gerard Langver, herinnerde zich: 'Er is toen heel veel “heen-en-weer-geëngineerd”, er zijn machines aangepast, echt een hoofdpijndossier. Door de manier waarop Houdijk dat oppakte met SIG en de engineers van Verkade,  is er een goed werkende automatische verpakkingslijn opgeleverd.'"])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Met dit paneel bedienden medewerkers van Verkade de transportlijn van Houdijk naar de verpakkingsmachine van SIG. Jaren '70."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/10.2.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na deze succesvolle eerste samenwerking, werd Houdijk al snel een vaste leverancier van machines voor Verkade. Naast de medewerkers van Houdijk die al het engineerwerk en de assemblage deden, waren ook de onderhoudsmonteurs een vaste verschijning bij Verkade. Langver: 'Mensen van Houdijk kwamen veel bij ons. Tom Olsthoorn bijvoorbeeld, zat er zó veel, hij wist overal de weg. Heel veel mensen dachten dat hij gewoon bij Verkade werkte.' Tom Olsthoorn beaamde zijn bijzondere relatie met het Verkade-bedrijf: 'Ik kom al heel lang bij Verkade, iets van 45 jaar. Mijn eerste klus was het opbouwen en in bedrijf stellen van een waxine-lijn, toen maakte Verkade namelijk nog waxinelichtjes.' De thee die mensen drinken bij de koekjes moest warm blijven en dat gebeurde met waxinelichtjes, zo redeneerde Verkade."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoewel Verkade door de jaren heen onderdeel is geworden van internationale holdings, is de band met Houdijk nog steeds goed. Toen de familie Verkade in 2018 hun eigen museum opende over hun fabriek in Zaandam, heeft Houdijk daar ook een steentje aan bijgedragen. Verkade vroeg Houdijk namelijk een machine te bouwen die repen chocolade met een losse wikkel uitleverde, zodat kinderen die de expositie bezochten zelf hun eigen wikkels konden inkleuren en mee naar huis konden nemen."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De wikkelmachine van Houdijk kan een reep Verkadechocolade in een zelfontworpen wikkel verpakken. 2018."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/10.3.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        }
      ]
    },
    {
      "id": 11,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/11.2.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naar de Trawlerweg"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoewel de Machinefabriek in de jaren 1950 al flink werd uitgebreid, liep de zaak in de twee decennia daarna zo goed, dat er opnieuw plannen voor uitbreiding werden gemaakt. In 1979 zetten de gebroeders Houdijk hun zinnen op het opkopen van nog een deel van de touwbaan achter de fabriek en nog eens vijf huizen die daaromheen lagen. De sloop- en bouwvergunningen waren al zo goed als gereed, maar door een wijziging in het bestemmingsplan kon de uitbreiding toch niet doorgaan. De gemeente Vlaardingen had namelijk besloten om de industrie van het woongebied te scheiden. Het plan voor uitbreiding van de Machinefabriek paste daar niet in en de fabriek dreigde gesaneerd te worden."])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1980"])},
      "category": 3,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/11.2.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aan de 1e van Leyden Gaelstraat kan de Machinefabriek niet meer uitbreiden. Eind jaren '70."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/11.1.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoewel de Machinefabriek in de jaren 1950 al flink werd uitgebreid, liep de zaak in de twee decennia daarna zo goed, dat er opnieuw plannen voor uitbreiding werden gemaakt. In 1979 zetten de gebroeders Houdijk hun zinnen op het opkopen van nog een deel van de touwbaan achter de fabriek en nog eens vijf huizen die daaromheen lagen. De sloop- en bouwvergunningen waren al zo goed als gereed, maar door een wijziging in het bestemmingsplan kon de uitbreiding toch niet doorgaan. De gemeente Vlaardingen had namelijk besloten om de industrie van het woongebied te scheiden. Het plan voor uitbreiding van de Machinefabriek paste daar niet in en de fabriek dreigde gesaneerd te worden."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijnand jr., Joop en Aad stonden voor de keuze om ofwel het familiebedrijf te liquideren of een erg dure investering in nieuwbouw te doen. De eerste optie zou betekenen dat de circa vijftig medewerkers, waarvan de meesten zo goed als hun hele beroepsleven voor Houdijk hadden gewerkt, op straat zouden komen te staan. De broers besloten unaniem voor de tweede optie."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Midden in het geraamte van het nieuwbouwpand, controleerde Joop Houdijk de voortgang. 1979."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/11.2.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In 1980 verhuisde de Machinefabriek naar een nieuwgebouwd pand van 2.800 vierkante meter aan de Trawlerweg 4. Het industrieterrein 'Het Scheur' was toen, op de Machinefabriek na, nog leeg."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Machinefabriek Houdijk aan de Trawlerweg 4 is nog het enige pand op het bedrijventerrein 'Het Scheur'. 1980."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/11.3.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het nieuwe pand was voor zowel de broers als hun medewerkers flink wennen. Aad Houdijk verklaarde: 'We zaten er behoorlijk geïsoleerd. Je zag elkaar in het oude pand de hele dag, maar de kantoren hier zijn gescheiden.' De nieuwe locatie beloofde veel goeds voor de toekomst, zo was de overtuiging. Joop: 'Houdijk krijgt in de nieuwe vestiging de gelegenheid zich beter te ontplooien, omdat de nieuwbouw heel wat beter op het bedrijf is afgestemd dan de oude panden.'"])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het nieuwe pand werd qua indeling beter afgestemd op de bedrijvigheid van Houdijk. Jaren '80."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/11.4.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        }
      ]
    },
    {
      "id": 12,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/12.1.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegepaste innovatie: de Page Turner"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoewel de internationaal succesvolle Telinmatic een groot deel van de productie in beslag nam, was er ook ruimte voor de ontwikkeling van andersoortige machines. Een daarvan was de zogenoemde Page Turner, een machine met een bijzonder verhaal. In 1979 bezochten Wijnand jr., Joop en Aad het verpleeg- en verzorgingscentrum Zonnehuis in Vlaardingen. Daar zagen ze dat veel bewoners moeite hadden met het lezen van boeken omdat ze de pagina's niet goed omgeslagen kregen. Een medewerker van het Zonnehuis bracht de broers in contact met iemand die zelf een houten bladomslagapparaat voor zijn vrouw had gemaakt. Geïnspireerd door dit apparaat, sloegen de broers de handen ineen om leesapparatuur voor ernstig gehandicapten te maken en te verbeteren."])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1980"])},
      "category": 2,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/12.1.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoewel de internationaal succesvolle Telinmatic een groot deel van de productie in beslag nam, was er ook ruimte voor de ontwikkeling van andersoortige machines. Een daarvan was de zogenoemde Page Turner, een machine met een bijzonder verhaal. In 1979 bezochten Wijnand jr., Joop en Aad het verpleeg- en verzorgingscentrum Zonnehuis in Vlaardingen. Daar zagen ze dat veel bewoners moeite hadden met het lezen van boeken omdat ze de pagina's niet goed omgeslagen kregen. Een medewerker van het Zonnehuis bracht de broers in contact met iemand die zelf een houten bladomslagapparaat voor zijn vrouw had gemaakt. Geïnspireerd door dit apparaat, sloegen de broers de handen ineen om leesapparatuur voor ernstig gehandicapten te maken en te verbeteren."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Constructietekenaars werkten de innovatieve machines van Houdijk tot in de puntjes uit. Jaren '80."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/12.1.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na een jaar experimenteren met verschillende prototypes, werd het definitieve apparaat, dat in staat was om bladzijden om te slaan, opgeleverd en de 'Page Turner' gedoopt. Op het zwakste bevel van de hand, voet, knie of zelfs een lichte ademblaas - afgestemd op individuele persoonlijke eisen - reageerde het apparaat en sloeg het een bladzijde van een boek of tijdschrift om. Het technische ontwerp van de machine was van de hand van Arie Gog, chef-tekenkamer in de Machinefabriek. Gogs innovatieve apparaat maakte indruk. Het dagblad Het Vrije Volk schreef: 'Met engelengeduld liet hij de nieuwe ideeën voor het bladomslagapparaat via onderzoek en proefjes in het eindproduct evolueren.' De opbrengsten hiervan kwamen niet bij Houdijk terecht. Daarvoor werd een stichting opgericht dat het geld weer investeerde in het ontwikkelen van nieuwe technologie voor de doelgroep."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De eenvoudig bedienbare Page Turner kan een bladzijde omslaan. Een doorzichtige plaat voorkwam dat de bladzijden vanzelf weer dichtvallen. 1980."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/12.2.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        }
      ]
    },
    {
      "id": 13,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/13.1.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In de moeilijkheden"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terwijl de Machinefabriek tijdens de economische crisis van de jaren 1930 vrijwel onbeschadigd door kwam, was dat anders tijdens de crisisjaren begin jaren 1980. Aanleiding voor deze economische crisis was een staatsgreep in oliestaat Iran in 1978. Zo'n zeven procent van de wereldwijde olieproductie stopte van de ene op de andere dag. Door deze schaarste verhoogden andere olielanden een jaar later hun prijzen tot bijna het drievoudige. Dat was voor industriebedrijven over de hele wereld een regelrechte ramp."])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1985"])},
      "category": 4,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/13.1.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terwijl de Machinefabriek tijdens de economische crisis van de jaren 1930 vrijwel onbeschadigd door kwam, was dat anders tijdens de crisisjaren begin jaren 1980. Aanleiding voor deze economische crisis was een staatsgreep in oliestaat Iran in 1978. Zo'n zeven procent van de wereldwijde olieproductie stopte van de ene op de andere dag. Door deze schaarste verhoogden andere olielanden een jaar later hun prijzen tot bijna het drievoudige. Dat was voor industriebedrijven over de hele wereld een regelrechte ramp."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door kleinere klussen en reparatiewerk aan te nemen, konden de medewerkers van Houdijk aan het werk blijven. Jaren '80."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/13.1.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zo ook voor Houdijk, dat voor zowel de fabricage als het draaiende houden van de machines olie nodig had. De stijging van olieprijzen betekende dan ook dat het voor de Machinefabriek duurder werd om machines te bouwen. Tegelijkertijd waren de bedrijven waar Houdijk aan leverde ook meer geld kwijt aan hun eigen productieprocessen. Ruimte voor investeringen en aanschaf van nieuwe machines was er dus ook maar weinig en het consumentenvertrouwen kelderde. De Machinefabriek zag de orderaantallen stevig teruglopen en beleefde financieel erg zware tijden. Een aantal keer balanceerde Houdijk op de rand van faillissement en kwam het aan op de juiste timing van betalingen door de weinige klanten in deze periode. Het zou nog jaren duren voordat het inmiddels bijna zeventigjarige familiebedrijf zich blijvend uit dit dal zou slepen."])}
        }
      ]
    },
    {
      "id": 14,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/14.1.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onder leiding van Bart Houdijk, derde generatie"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na ongeveer veertig jaar aan het roer van het bedrijf te hebben gestaan, was het voor de tweede generatie tijd om het stokje over te geven aan de volgende generatie. Bart Houdijk was als zoon van Joop al goed bekend bij het familiebedrijf toen hij in 1987 de leiding kreeg. Vanaf zijn veertiende ging hij tijdens schoolvakanties regelmatig mee op zakenreis met zijn vader. Ook ging hij met de buitenmonteurs mee om karweitjes te doen, onder anderen met Technisch Supervisor Rinus van Driel: 'Samen met Tom Olsthoorn had ik een klus bij Hooimeijer en daar moesten we voor een beschuitlijn iemand bij hebben. Ze hadden nog wel een vakantiemannetje, zeiden ze. Toen kreeg ik dus Bart mee. Die arme jongen heeft een week onder de machine gelegen om gaatjes te boren. Dat deed hij zonder morren, dat moet ik hem nageven. Af en toe moesten we hem er onderuit halen om een bakje koffie te drinken en dan ging hij weer verder.'"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1987"])},
      "category": 1,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/14.1.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Barts kantoor hangen portretten van de eerste en tweede generaties. 2018."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/14.1.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na ongeveer veertig jaar aan het roer van het bedrijf te hebben gestaan, was het voor de tweede generatie tijd om het stokje over te geven aan de volgende generatie. Bart Houdijk was als zoon van Joop al goed bekend bij het familiebedrijf toen hij in 1987 de leiding kreeg. Vanaf zijn veertiende ging hij tijdens schoolvakanties regelmatig mee op zakenreis met zijn vader. Ook ging hij met de buitenmonteurs mee om karweitjes te doen, onder anderen met Technisch Supervisor Rinus van Driel: 'Samen met Tom Olsthoorn had ik een klus bij Hooimeijer en daar moesten we voor een beschuitlijn iemand bij hebben. Ze hadden nog wel een vakantiemannetje, zeiden ze. Toen kreeg ik dus Bart mee. Die arme jongen heeft een week onder de machine gelegen om gaatjes te boren. Dat deed hij zonder morren, dat moet ik hem nageven. Af en toe moesten we hem er onderuit halen om een bakje koffie te drinken en dan ging hij weer verder.'"])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nadat hij later zijn studie Rechten aan de Erasmus Universiteit had afgerond, werd Bart de keuze voorgelegd om in de voetstappen van zijn vader en ooms te treden. De Machinefabriek verkeerde financieel in zwaar weer. Dat was een uitdaging die Bart wel aan wilde gaan, maar makkelijk was het niet voor de toen 26-jarige directeur: 'Mijn vader had er veel moeite mee dat hij niet meer kon werken en de zaak aan mij moest overlaten in zo'n moeilijke tijd.' De eerste twee jaar werd hij dagelijks met wijze raad ondersteund door Nol van Haase, oud-directeur van de Rotterdamsche Droogdok Maatschappij. Onder Barts leiding kwam het bedrijf de financiële problemen van de jaren 1980 te boven en ontwikkelde Houdijk zich uiteindelijk tot wereldspeler op het gebied van biscuit feeding."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net als zijn grootvader, vader en ooms, staat Bart Houdijk bekend als een sociale en betrokken werkgever. 2018."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/14.2.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        }
      ]
    },
    {
      "id": 15,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/15.1.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Van machinefabriek tot engineering company"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al sinds de jaren 1980 verkeerde de Machinefabriek in zwaar weer. Om het inmiddels drie generaties oude familiebedrijf niet failliet te laten gaan, moest Bart een aantal noodzakelijke beslissingen nemen. Houdijk Holland, zoals het bedrijf nu ging heten, zou zich volledig gaan richten op de engineering van biscuit feeding machines. Sinds de jaren 1960 was dit een van de belangrijkste inkomstenbronnen voor Houdijk, en voor Bart dé reden om daar ook de toekomst in te zoeken."])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ca. 1995"])},
      "category": 4,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/15.1.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het internationale succes van de biscuithandlingmachines van Houdijk legde de grondslag voor de latere specialisering. Jaren '80."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/15.1.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al sinds de jaren 1980 verkeerde de Machinefabriek in zwaar weer. Om het inmiddels drie generaties oude familiebedrijf niet failliet te laten gaan, moest Bart een aantal noodzakelijke beslissingen nemen. Houdijk Holland, zoals het bedrijf nu ging heten, zou zich volledig gaan richten op de engineering van biscuit feeding machines. Sinds de jaren 1960 was dit een van de belangrijkste inkomstenbronnen voor Houdijk, en voor Bart dé reden om daar ook de toekomst in te zoeken."])}
        },
        {
          "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het traditionele handwerk werd bij Houdijk steeds meer aangevuld met digitale werkmiddelen. Jaren '90."])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["video"])},
          "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/6IOkgsB_Rk0"])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De nieuwe doelstelling betekende dat ook dat alle werkzaamheden op het gebied van reparatie en machinefabricage werden stopgezet. Het maken van onderdelen besteedde hij uit aan verschillende toeleveranciers. Bart Houdijk: 'Voor de monteurs, bankwerkers en plaatbewerkers had ik eigenlijk geen werk meer. Ik heb Houdijk uiteindelijk drie keer gereorganiseerd. Eerst twintig man moeten ontslaan, toen dertig en bij de laatste reorganisatie in 2003 nog eens tien. Op het kleinst waren we met maar vijfendertig man. Tien à vijftien op de engineeringsafdeling, tien in de werkplaats en de rest bij verkoop en administratie. Daar kijk ik met weemoed op terug.' Medewerkers die al lang aan het bedrijf verbonden waren, zagen de ontslagen met lede ogen aan. Onder hen Annette Dunweg, sinds 1978 werkzaam voor Houdijk. 'Het is niet fijn als je je collega's ziet vertrekken. Dat is heel triest. Het is daarna gelukkig weer beter gegaan. Veel collega's die ontslagen waren, zijn ook weer teruggekomen. Dat wil ook wel wat zeggen.'"])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoewel het gedwongen ontslag van oudgedienden zwaar viel, bleek Barts visie op het voortbestaan van Houdijk goed uit te pakken. Als gevolg van de reorganisaties klom Houdijk er weer bovenop en ontstond er ook weer meer werk. Vanaf de laatste reorganisatie in 2003 zou Houdijk Holland zich nog uitsluitend bezighouden met de ontwikkeling, eindassemblage, het testen en in bedrijf stellen van machines voor het transporteren, sorteren, stapelen en nabewerken van koekjes, biscuits en crackers vanuit de oven naar de verpakkingsmachine. Naast de terugkeer van oudgedienden, nam Bart ook techneuten aan met universitaire achtergrond in Computer Science, Engineering, Industrieel Ontwerp, Elektrotechniek en Werktuigbouwkunde. Het onderstreepte de omzetting van maakbedrijf tot engineeringsbedrijf."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De machines van Houdijk worden tegenwoordig volledig digitaal ontworpen. 2020."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/15.3.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        }
      ]
    },
    {
      "id": 16,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/16.1.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Gradomatic"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waar de opdrachten een decennium eerder nog erg langzaam binnendruppelden, ging het vanaf halverwege de jaren 1990 goed met Houdijk Holland. Vraag naar handlingsystemen voor koekjes, biscuits en crackers was er weer volop en fabrikanten uit alle hoeken van de wereld wisten Houdijk Holland te vinden. Dit was een goed moment om een nieuwe Houdijk-machine op de markt te brengen: de Gradomatic. Door een serie transportbanden in een bepaalde hellingsgraad te zetten, was het mogelijk om zonder veel weerstand producten uit de ovens te sorteren voor transport naar verpakkingsmachines. 'De Gradomatic was in veel opzichten een verbetering van zijn voorganger. De Telinmatic was een apparaat waarbij koek op lucht dreef. Dat is natuurlijk heel fijn om koek te verdelen, maar het nadeel was dat de kruimels en het stof vaak tot boven in het plafond zaten', verklaarde Tom Olsthoorn."])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ca. 1995"])},
      "category": 2,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/16.1.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waar de opdrachten een decennium eerder nog erg langzaam binnendruppelden, ging het vanaf halverwege de jaren 1990 goed met Houdijk Holland. Vraag naar handlingsystemen voor koekjes, biscuits en crackers was er weer volop en fabrikanten uit alle hoeken van de wereld wisten Houdijk Holland te vinden. Dit was een goed moment om een nieuwe Houdijk-machine op de markt te brengen: de Gradomatic. Door een serie transportbanden in een bepaalde hellingsgraad te zetten, was het mogelijk om zonder veel weerstand producten uit de ovens te sorteren voor transport naar verpakkingsmachines. 'De Gradomatic was in veel opzichten een verbetering van zijn voorganger. De Telinmatic was een apparaat waarbij koek op lucht dreef. Dat is natuurlijk heel fijn om koek te verdelen, maar het nadeel was dat de kruimels en het stof vaak tot boven in het plafond zaten', verklaarde Tom Olsthoorn."])}
        },
        {
          "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Gradomatic voorkomt dat producten breken en lost het probleem met rondvliegende kruimels van de Telinmatic op. 1997."])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["video"])},
          "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/wAXKSQcqFnE"])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Gradomatic sloot goed aan bij de toenemende vraag naar machines die op hoog volume en snelheid toch betrouwbaar en efficiënt werkten. De Gradomatic is samen met andere Houdijk-machines onderdeel van een grotere productielijn: de Flowmaster. Teamleider Commissioning Edward van Oostende: 'Met de Flowmaster verdelen we de aanvoer over een aantal Gradomatics door de koeken met een paddel de juiste baan in te tikken. Met een camerasysteem bewaken we de afvoersnelheid en de verdeling. De Gradomatic verzamelt de producten en vervoert die naar een doseermachine, waar de producten in rolletjes of traytjes worden verdeeld.'  Met deze machinelijn bevestigde Houdijk Holland zijn positie als marktleider in 'biscuit feeding'."])}
        },
        {
          "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De innovatiekracht van Houdijk stelt het bedrijf in staat zijn machines continu te verbeteren. 2017."])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["video"])},
          "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/_9wlBKiJE7g"])}
        }
      ]
    },
    {
      "id": 17,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/17.1.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samenwerking met SNL Group"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De specialisatie die Bart aanbracht in de werkzaamheden van Houdijk Holland betekende dat hij voor de fabricage van de machines toeleveranciers moest zoeken. Tijdens een van de uitbestedingsrondes kwam Frans Lammers van SNL Group Schijndel in beeld. De eerste paar eenvoudigere projecten verliepen goed en SNL werd snel een vaste toeleverancier voor de transportsystemen van Houdijk. 'De engineering, sales en aftersales liggen bij Houdijk. De productie doen wij, maar we denken ook mee. We evalueren veel en denken na over de maakbaarheid. Hoe kan het makkelijker en hoe kan de doorlooptijd verkort worden? Daar hebben we met de inkopers en engineers regelmatig gesprekken over. Dit werk doen we natuurlijk voor meerdere klanten, maar met Houdijk heb ik wel echt een speciale band', lichtte Frans toe."])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1998"])},
      "category": 4,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/17.1.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De specialisatie die Bart aanbracht in de werkzaamheden van Houdijk Holland betekende dat hij voor de fabricage van de machines toeleveranciers moest zoeken. Tijdens een van de uitbestedingsrondes kwam Frans Lammers van SNL Group Schijndel in beeld. De eerste paar eenvoudigere projecten verliepen goed en SNL werd snel een vaste toeleverancier voor de transportsystemen van Houdijk. 'De engineering, sales en aftersales liggen bij Houdijk. De productie doen wij, maar we denken ook mee. We evalueren veel en denken na over de maakbaarheid. Hoe kan het makkelijker en hoe kan de doorlooptijd verkort worden? Daar hebben we met de inkopers en engineers regelmatig gesprekken over. Dit werk doen we natuurlijk voor meerdere klanten, maar met Houdijk heb ik wel echt een speciale band', lichtte Frans toe."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ard Smits en Frans Lammers zijn de oprichters van SNL Group Schijndel. Jaren 2000."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/17.1.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De speciale band met Houdijk vertaalde zich naast goed zaken doen, ook in onderling contact tussen Bart en Frans. 'Bart belde weleens vijf uur 's ochtends op. Dan stapte hij ergens in het buitenland op een vliegtuig in Mexico of Brazilië en vertelde dan dat het weer een mooi project wordt samen.' Wat Lammers het meest kan waarderen aan de samenwerking met Houdijk is nog wel dat het een echt familiebedrijf is en instaat voor continuïteit en kwaliteit. De waardering is geheel wederzijds, want SNL is vandaag de dag nog altijd vaste toeleverancier van Houdijk Holland."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SNL Group Schijndel is een vaste toeleverancier van Houdijk op het gebied van metaal- en machinebouw. Jaren 2010."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/17.2.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        }
      ]
    },
    {
      "id": 18,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/18.1.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Op zoek naar ruimte"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onderdeel van de reorganisaties die nodig waren om Houdijk er weer bovenop te krijgen, was het snijden in uitgaven aan vastgoed. Om genoeg financiën te hebben voor verdere specialisatie, verkocht Bart het pand dat Wijnand jr., Joop en Aad aan de Trawlerweg 4 hadden laten bouwen om het vervolgens te huren tegen voordeligere voorwaarden. Na de moeilijke periode en toen Barts reorganisaties vruchten begonnen af te werpen, heeft hij het pand weer teruggekocht."])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ca 2000"])},
      "category": 3,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/18.1.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onderdeel van de reorganisaties die nodig waren om Houdijk er weer bovenop te krijgen, was het snijden in uitgaven aan vastgoed. Om genoeg financiën te hebben voor verdere specialisatie, verkocht Bart het pand dat Wijnand jr., Joop en Aad aan de Trawlerweg 4 hadden laten bouwen om het vervolgens te huren tegen voordeligere voorwaarden. Na de moeilijke periode en toen Barts reorganisaties vruchten begonnen af te werpen, heeft hij het pand weer teruggekocht."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toen Houdijk weer begon te groeien, bleek de Trawlerweg 4 al snel te krap te worden. In Vlaardingen was er op dat moment echter geen ruimte voor uitbreiding beschikbaar. Een tijdelijke oplossing werd gevonden in Rotterdam, waar een externe bedrijfsruimte kon worden gehuurd. Na enkele jaren bleek echter dat dit niet houdbaar was. Door de spreiding van werkzaamheden over twee locaties waren de medewerkers van Houdijk onnodig veel tijd kwijt aan het transport heen-en-weer. Voor permanente uitbreiding van de bedrijfsruimte moest nog enkele jaren verder worden gezocht."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aan het heen-en-weer transport van mensen, materialen en machines, was Houdijk te veel tijd kwijt. Jaren 2000."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/18.1.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        }
      ]
    },
    {
      "id": 19,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/19.2.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitbreiding Trawlerweg"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waar Houdijk in de jaren 2000 niet kon voldoen aan de behoefte aan meer bedrijfsoppervlak, deed er zich in 2015 een nieuwe kans voor. In het pand direct naast Houdijk, de Trawlerweg 2, zat een drukkerij, die als gevolg van de opkomende digitalisering de deuren moest sluiten. Het pand werd in 2015 overgedaan aan Houdijk, die het daarna totaal gerenoveerd heeft. Houdijk Holland heeft sindsdien een dubbeladres en is gevestigd aan de Trawlerweg 2-4. 'Dat was nodig vanwege de groei, maar als je kijkt naar wat we werkelijk nodig hebben aan oppervlakte, dan is dit bij elkaar eigenlijk alweer te klein,' legde John Kasbergen uit."])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2015"])},
      "category": 3,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/19.2.webp"])}
      },
      "contents": [
        {
          "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onder belangstelling van de medewerkers, legde Bart de symbolische eerste hand aan de uitbreiding van het pand aan de Trawlerweg. 2015."])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["video"])},
          "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/1atV8-NMZQQ"])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waar Houdijk in de jaren 2000 niet kon voldoen aan de behoefte aan meer bedrijfsoppervlak, deed er zich in 2015 een nieuwe kans voor. In het pand direct naast Houdijk, de Trawlerweg 2, zat een drukkerij, die als gevolg van de opkomende digitalisering de deuren moest sluiten. Het pand werd in 2015 overgedaan aan Houdijk, die het daarna totaal gerenoveerd heeft. Houdijk Holland heeft sindsdien een dubbeladres en is gevestigd aan de Trawlerweg 2-4. 'Dat was nodig vanwege de groei, maar als je kijkt naar wat we werkelijk nodig hebben aan oppervlakte, dan is dit bij elkaar eigenlijk alweer te klein,' legde John Kasbergen uit."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het pand dat Wijnand jr., Joop en Aad in 1980 openden, is door de overname van de voormalige drukkerij qua werkoppervlak verruimd. 2019."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/19.2.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De opdrachten kwamen vanuit alle windstreken binnen en het aantal medewerkers groeide van 35 in 2003 tot meer dan 200 in 2015. Spoedig bleek het toegevoegde vloeroppervlak van de Trawlerweg 2 te krap voor het voortvarende Houdijk. Nog geen zes jaar later is er nogmaals een uitbreiding van het pand geweest door de toevoeging van een nieuw stuk aan de montagehal aan de Trawlerweg 2. Gezien de sterke groei die Houdijk nog steeds doormaakt, is het eigenlijk niet de vraag óf, maar vooral wannéér de volgende uitbreiding van het pand in Vlaardingen plaats zal vinden."])}
        }
      ]
    },
    {
      "id": 20,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/20.1.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komst van de Capper"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'We winnen alleen als we machines bouwen die meer presteren en een hogere kwaliteit halen', stelde Bart toen Houdijk in 2015 de Capper presenteerde. 'Die ontwikkeling lag in lijn met onze eerdere machines. De koekjes komen kaal uit de oven en er moet soms nog wat mee gebeuren voordat ze de verpakking in kunnen. Dat is precies in ons straatje.' Na de Telinmatic en de Gradomatic bevestigde Houdijk met de Capper nog maar eens zijn positie als wereldwijde marktleider in de biscuithandlingindustrie."])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2015"])},
      "category": 2,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/20.1.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Houdijk Capper voorziet sandwichbiscuits van een laagje crème. 2009."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/20.1.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'We winnen alleen als we machines bouwen die meer presteren en een hogere kwaliteit halen', stelde Bart toen Houdijk in 2015 de Capper presenteerde. 'Die ontwikkeling lag in lijn met onze eerdere machines. De koekjes komen kaal uit de oven en er moet soms nog wat mee gebeuren voordat ze de verpakking in kunnen. Dat is precies in ons straatje.' Na de Telinmatic en de Gradomatic bevestigde Houdijk met de Capper nog maar eens zijn positie als wereldwijde marktleider in de biscuithandlingindustrie."])}
        },
        {
          "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Houdijk maakte al voor de ontwikkeling van de Capper een machine voor sandwichproducten. 1991."])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["video"])},
          "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/9nLmpcW8gug"])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Capper is specifiek ontworpen voor sandwichbiscuits, oftewel producten waar nog een vulling tussen moet komen, zoals de wereldberoemde Oreo-koekjes. Het innovatieve Houdijk bleef in de loop der jaren voortdurend naar verbeteringen van de machine zoeken. Bij de eerste versie uit 2015 werden de koekjes nog een voor een gekeerd met een soort glijgoot, voordat de vulling erop gespoten kon worden. Bij de meest recente Capper - bij Houdijk beter bekend als de Mark III - worden de koekjes in een continu proces gekeerd, waardoor veel hogere snelheden kunnen worden bereikt."])}
        },
        {
          "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per minuut verwerkt de Capper meer dan vijf duizend sandwichbiscuits. 2019."])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["video"])},
          "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/oBw4McQCqdw"])}
        }
      ]
    },
    {
      "id": 21,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/21.1.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Cookiebot en de toepassing van robots"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In de grote fabriekshallen in bijvoorbeeld Amerika en China komen de koekjes met duizenden, soms wel tienduizenden per minuut uit de ovens. De hoog-volumelijnen van Houdijk zijn specifiek voor deze aantallen biscuits per minuut ontworpen en compleet afgestemd om één productvorm zo efficiënt mogelijk te verwerken. Meer en meer merkten Bart en het salesteam dat er vraag ontstond naar machines die een grotere variatie van verschillende product- en verpakkingsvormen kunnen verwerken. Bart Houdijk: 'Bestaande Houdijk-machines waren daar niet echt voor geschikt, dus zochten wij naar - vaak heel ingewikkelde - aanpassingen en oplossingen. Na al dat gepuzzel, kwam een klant met nog een nieuwe productvariant en konden we de zoektocht naar een oplossing opnieuw beginnen. Toen dacht ik: “We gaan dit probleem gewoon met robots oplossen.” Wij zijn een slim bedrijf en hebben dus snel geschakeld.'"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2018"])},
      "category": 2,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/21.1.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In de grote fabriekshallen in bijvoorbeeld Amerika en China komen de koekjes met duizenden, soms wel tienduizenden per minuut uit de ovens. De hoog-volumelijnen van Houdijk zijn specifiek voor deze aantallen biscuits per minuut ontworpen en compleet afgestemd om één productvorm zo efficiënt mogelijk te verwerken. Meer en meer merkten Bart en het salesteam dat er vraag ontstond naar machines die een grotere variatie van verschillende product- en verpakkingsvormen kunnen verwerken. Bart Houdijk: 'Bestaande Houdijk-machines waren daar niet echt voor geschikt, dus zochten wij naar - vaak heel ingewikkelde - aanpassingen en oplossingen. Na al dat gepuzzel, kwam een klant met nog een nieuwe productvariant en konden we de zoektocht naar een oplossing opnieuw beginnen. Toen dacht ik: “We gaan dit probleem gewoon met robots oplossen.” Wij zijn een slim bedrijf en hebben dus snel geschakeld.'"])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De pick-and-place robotarmen van de Cookiebot verplaatsen producten van de ene plek naar de andere. 2020."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/21.1.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na de eerste tests met robotsystemen eind 2017, presenteerde Houdijk in 2018 de Cookiebot: een 'pick-and-place' robot die via camerasensoren koekjes van verschillende vormen en groottes kan verplaatsen. R&D Engineer Menze-Jan Buitenhuis: 'De Cookiebot kan met een grijparm een koekje oppakken en het in een plastic traytje leggen of op een andere transportband in precies het goede patroon. In België hebben we de eerste Cookiebot geplaatst. Die fabriek maakte ingevroren croissantjes die we met de Cookiebot oppakten en op een afvoerbandje naar de verpakkingsmachines legden.' Deze flexibele machine is snel populair geworden. Naast de Gradomatic, Flowmaster en Capper, is de Cookiebot nog een van de biscuithandlinglijnen waarmee Houdijk klanten over de hele wereld bedient."])}
        },
        {
          "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Met de ontwikkeling van robotlijnen, biedt Houdijk zijn klanten meer flexibiliteit. Eind 2019/begin 2020."])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["video"])},
          "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/pFLMRkC_onA"])}
        }
      ]
    },
    {
      "id": 22,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/22.3.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wereldwijd"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Houdijk Holland is de wereldwijde marktleider in biscuit feeding machines. Bijna 95 procent van de machines die het Vlaardingse bedrijf ontwerpt en assembleert, is bestemd voor het buitenland. Internationaal bekende fabrikanten als Nabisco/LU/Mondelèz, PepsiCo en Ferrero vallen onder het klantenbestand en de machines van Houdijk zijn wereldwijd te vinden. Bart Houdijk: 'Gemakshalve zeg ik altijd maar: “we doen nu 25 procent van onze omzet in Europa, 25 procent Noord-Amerika, 25 procent Zuid-Amerika en 25 procent Azië.'"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2020"])},
      "category": 4,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/22.3.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Houdijk Holland is de wereldwijde marktleider in biscuit feeding machines. Bijna 95 procent van de machines die het Vlaardingse bedrijf ontwerpt en assembleert, is bestemd voor het buitenland. Internationaal bekende fabrikanten als Nabisco/LU/Mondelèz, PepsiCo en Ferrero vallen onder het klantenbestand en de machines van Houdijk zijn wereldwijd te vinden. Bart Houdijk: 'Gemakshalve zeg ik altijd maar: “we doen nu 25 procent van onze omzet in Europa, 25 procent Noord-Amerika, 25 procent Zuid-Amerika en 25 procent Azië.'"])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Houdijks goede internationale reputatie begon al in de jaren 1960 met het succes van de Telinmatic in België en Duitsland. Al snel daarna volgde Frankrijk en - door de overname van Verkade door United Biscuits - ook Engeland. Projecten werden uitgevoerd tot in Finland en Rusland en oud-buitenmonteur Jan de Priëlle reisde zelfs nog af naar het communistische Kazachstan. 'Wij moesten daar een hele koeklijn opzetten, maar ter plaatse was er nauwelijks iets voorbereid. Elektriciteit was er niet voorhanden. Alles werd pas na onze komst in gang gezet. En wij zaten in de tussentijd maar te niksen.'"])}
        },
        {
          "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Op de Interpack Beurs in Duitsland toont en verkoopt Houdijk zijn innovatieve machines aan biscuitfabrikanten uit alle windstreken. Eind jaren '80."])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["video"])},
          "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/sJdffVqbm7U"])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De eerste opdracht in Noord-Amerika kwam in 1989. Edward van Oostende: 'Ik werkte nog maar net bij Houdijk, maar mocht toen toch mee naar Chicago. Voor Bakeline hebben we daar een Telinmatic geïnstalleerd.' In de jaren daarna richtte Houdijk de pijlen op de biscuitfabrikant Nabisco, tegenwoordig Mondelèz. Na een opdracht voor een machinelijn voor Oreo-koekjes in Canada, kreeg Houdijk meer aanvragen binnen van Nabisco/Mondelèz. Bart: 'Zij zijn instrumenteel geweest in onze ontwikkeling. Mondelèz bouwt enorme biscuitlijnen, dus daarvoor moesten wij zelf ook flink aan de bak. Doordat zij in andere landen gingen produceren, kwamen wij ook over de hele wereld. Een andere grote klant is PepsiCo, waaraan we biscuit- en crackerlijnen in Zuid-Amerika leveren.'"])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De succesvolle eerste opdracht voor Nabisco gaf Houdijk voeten aan de grond in Noord-Amerika. 1993."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/22.2.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinds eind jaren 2010 heeft Houdijk Holland agentschappen in het overgrote deel van de EU, Zwitserland, het Verenigd Koninkrijk, Oekraïne, Rusland, Turkije, de Verenigde Staten, Canada, Mexico, Guatemala, Nicaragua, Colombia, Brazilië, Argentinië, Chili, Zuid-Afrika, Israël, Pakistan, India, Zuid-Korea, Indonesië en Australië. De zakelijke contacten in de Verenigde Staten worden sinds 2020 door Houdijk North America vanuit Richmond, Virginia onderhouden. In de toekomst ligt er nog verdere expansie naar Oost-Europa en Rusland in het verschiet voor het multinationale Houdijk."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Met een zustertak in de Verenigde Staten en vertegenwoordigers wereldwijd, zijn de machines van het multinationale Houdijk over de hele wereld te vinden. 2020."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/22.3.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        }
      ]
    },
    {
      "id": 23,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/23.2.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innovatieve mentaliteit"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gedreven door dezelfde innovatiedrang op machinegebied, stuurt Bart ook aan op het toepassen van vernieuwende technieken binnen en buiten het bedrijfspand. Aan de muren van het kantoor hangen schermen die de beschikbaarheid van vergaderkamers aangeven, bij binnenkomst is er een geheel digitaal systeem om bezoekers te ontvangen en de bedrijfstuin inclusief bijenhotel is compleet ecologisch aangelegd en onderhouden."])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2020"])},
      "category": 3,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/23.2.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gedreven door dezelfde innovatiedrang op machinegebied, stuurt Bart ook aan op het toepassen van vernieuwende technieken binnen en buiten het bedrijfspand. Aan de muren van het kantoor hangen schermen die de beschikbaarheid van vergaderkamers aangeven, bij binnenkomst is er een geheel digitaal systeem om bezoekers te ontvangen en de bedrijfstuin inclusief bijenhotel is compleet ecologisch aangelegd en onderhouden."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer dan 2000 zonnepanelen op het dak voorzien Houdijk van groene energie. 2019."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/23.1.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Vaak is de stap tussen de ouderwetse en nieuwe of ecologische manier niet groot en kost het niet veel meer geld. Ik ben geen fanatiekeling, maar als iets vernieuwender kan, dan moeten we het vooral doen. Zo voorkom je dat je na een aantal jaar alsnog van alles moet omgooien,' lichtte Bart toe. Een van die kansen deed zich voor bij de vernieuwing van het elektrawerk bij de parkeerplaatsen. In plaats van de oorspronkelijke bekabeling opnieuw aan te leggen, liet hij het alvast klaarmaken voor twintig laadpalen en groene parkeerplaatsen met regenwateropslag. De meer dan 2000 zonnepanelen op het dak genereren al langere tijd een groot deel van het energieverbruik en de ouderwetse tl-buizen en gasaansluitingen hebben plaatsgemaakt voor ledverlichting en elektrische verwarmingen."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De innovatiekracht waarmee Bart het bedrijf aan kop wil laten lopen, kent ook zijn grenzen. Houdijks ambitie vergt veel van de flexibiliteit van de medewerkers. Senior Service Engineer Edward van Oostende: 'Soms zet dat druk op het bedrijf en brengt het stress met zich mee. De laatste paar jaar zijn we wel toe aan een stabielere situatie. Daar heeft de coronapandemie overigens ook niet aan bijgedragen.' Bart Houdijk nam dit signaal serieus en stak de hand in eigen boezem: 'Ik realiseer me dat de laatste drie jaar niet makkelijk zijn geweest voor ons. Door steeds meer met robots te werken, bewegen we ons van onze traditionele markt naar een nieuwe. Ik heb flink geduwd op die vernieuwing en daarmee te veel hooi op de vork genomen. Het was niet eenvoudig, maar gelukkig zitten we inmiddels weer op de goede weg.'"])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door continu door te ontwikkelen, verfijnt het innovatieve Houdijk de toepassing van zijn robotlijnen. 2021."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/23.2.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        }
      ]
    },
    {
      "id": 24,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/24.1.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honderd jaar familiebedrijf"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Ik denk dat je Houdijk wel een écht mensenbedrijf kunt noemen, al maken we dan machines.' Met die woorden omschreef Bart Houdijk het familiebedrijf in 1992 ter gelegenheid van het 70-jarig jubileum. Die binding tussen het bedrijf en zijn mensen bestond al vanaf de oprichting. Twee van de eerste werknemers die in 1922 door Wijnand sr. zijn aangenomen, hebben zelfs meer dan vijftig jaar gewerkt in de Vlaardingse machinefabriek. Zoals niet ongebruikelijk in die tijd, begonnen Willem van der Schee en Bernardus Versteege namelijk op hun veertiende als knechten bij Houdijk, en werkten zij door tot hun pensioenleeftijd van 65. De zoon van Willem, Wim van der Schee, kwam op suggestie van Joop Houdijk ook bij het familiebedrijf te werken. Net als zijn vader, zou ook Wim tot zijn pensioenleeftijd aan Houdijk verbonden blijven."])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022"])},
      "category": 1,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/24.1.webp"])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Ik denk dat je Houdijk wel een écht mensenbedrijf kunt noemen, al maken we dan machines.' Met die woorden omschreef Bart Houdijk het familiebedrijf in 1992 ter gelegenheid van het 70-jarig jubileum. Die binding tussen het bedrijf en zijn mensen bestond al vanaf de oprichting. Twee van de eerste werknemers die in 1922 door Wijnand sr. zijn aangenomen, hebben zelfs meer dan vijftig jaar gewerkt in de Vlaardingse machinefabriek. Zoals niet ongebruikelijk in die tijd, begonnen Willem van der Schee en Bernardus Versteege namelijk op hun veertiende als knechten bij Houdijk, en werkten zij door tot hun pensioenleeftijd van 65. De zoon van Willem, Wim van der Schee, kwam op suggestie van Joop Houdijk ook bij het familiebedrijf te werken. Net als zijn vader, zou ook Wim tot zijn pensioenleeftijd aan Houdijk verbonden blijven."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De onderlinge sfeer is bij Houdijk sinds jaar en dag gemoedelijk en familiair. 1937."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/24.1.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naast de drie Houdijk-broers zijn er vlak na de Tweede Wereldoorlog meer werknemers bijgekomen die bijdroegen aan de familiaire sfeer in het bedrijf. Een van hen is Arie Gog, die als tekenaar werd aangenomen en het al snel tot chef van de tekenkamer schopte. Wanneer er een opdracht binnenkwam, was het Arie die het eerste ontwerp uittekende en om die reden bekend raakte als de vader van de machines die Houdijk maakte. Gelijktijdig met Arie, trad ook de secretaresse Nettie Sterk aan. Dat ze een vrouw in een mannenwereld was, zat haar niet tegen. Juffrouw Nettie stond erom bekend erg vasthoudend te zijn en werd na verloop van tijd de rechterhand van Aad Houdijk. Het hondje dat steevast aan haar zijde liep, was een regelmatige verschijning op de werkvloer. Een goed deel van de familiaire sfeer op de werkvloer is volgens Barts broer Guus Houdijk te danken aan hun vader Joop. 'Hij was altijd erg op de mensen gericht en goed betrokken bij de werknemers en hun families. Hij wist eigenlijk altijd hoe het met de kinderen van zijn werknemers ging.'"])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
          "image": {
            "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veel medewerkers van Houdijk werken hun hele beroepsleven voor het Vlaardingse familiebedrijf. 1988."])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/24.2.webp"])},
            "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1989"])},
            "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1494"])}
          }
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behalve het koesteren van een informele sfeer, droeg ook het geven van eigen verantwoordelijkheid bij aan een grote betrokkenheid van werknemers. Edward van Oostende: 'Toen ik bij Houdijk begon, zaagden en bewerkten we nog onze eigen materialen. We kregen een stapel tekeningen mee en daarmee gingen we aan de slag. Ik werd al vroeg vrijgelaten om me te ontwikkelen. Door daar gebruik van te maken, wist ik van alles wat over onze machines. Doordat je aan alle stappen van een machine hebt meegewerkt, krijg je ook meer verantwoordelijkheidsgevoel.'"])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze bedrijfscultuur zet Bart bewust door. 'Aan nieuwe medewerkers geef ik altijd drie cultuurwaarden mee. De eerste is een vriendelijke, respectvolle en collegiale werksfeer. We doen het met z'n allen en praten mét elkaar, niet over elkaar. We discussiëren met elkaar op basis van argumenten en feiten, niet op basis van macht. De tweede waarde is dat de klant centraal staat. Als er iets voor een klant moet gebeuren, zie ik dat het liefst zo snel mogelijk gedaan worden. Het laatste punt is orde en netheid. We werken hier netjes, georganiseerd en professioneel. Dat werkt niet alleen efficiënter, maar het geeft ook een goede uitstraling naar onze klanten.' Ondanks het groeiende aantal medewerkers wordt de gemoedelijke sfeer die Houdijk kenmerkt ook door nieuwe collega's opgemerkt en gewaardeerd. Commissioning Engineer Sebastiaan Vijfhuizen, sinds 2018 bij Houdijk werkzaam: 'Op mijn eerste werkdag liepen we bij iedereen langs voor een voorstelrondje en ik proefde al meteen een heel gemoedelijke sfeer. Het is niet zo strak of hiërarchisch. Je kunt zo bij elkaar naar binnen lopen en de collega's gaan ook buiten werktijd goed met elkaar om.'"])}
        },
        {
          "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drie generaties van de familie Houdijk hebben gedurende honderd jaar hun stempel gedrukt op het familiebedrijf."])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["video"])},
          "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.youtube.com/embed/PXG644kJDqE"])}
        }
      ]
    },
    {
      "id": 25,
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/images/placeholder.webp"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De volgende generatie"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In het honderdjarig bestaan van Houdijk is het bedrijf ingrijpend veranderd. Van scheepsreparateurs naar machinefabriek tot de wereldwijde marktleider in de biscuithandling. Deze ontwikkelingen vonden plaats onder leiding van de familie Houdijk. Honderd jaar geleden richtte Wijnand Houdijk sr. het bedrijf op, na de Tweede Wereldoorlog traden zijn drie zoons Wijnand jr., Joop en Aad aan, en eind jaren 1980 stond Bart Houdijk aan het roer. Met Barts neefje Sander die als monteur de machines van Houdijk in bedrijf stelt, is ook de vierde generatie Houdijk actief in het familiebedrijf."])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022 en verder"])},
      "category": 1,
      "hero": {
        "image_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      "contents": [
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In het honderdjarig bestaan van Houdijk is het bedrijf ingrijpend veranderd. Van scheepsreparateurs naar machinefabriek tot de wereldwijde marktleider in de biscuithandling. Deze ontwikkelingen vonden plaats onder leiding van de familie Houdijk. Honderd jaar geleden richtte Wijnand Houdijk sr. het bedrijf op, na de Tweede Wereldoorlog traden zijn drie zoons Wijnand jr., Joop en Aad aan, en eind jaren 1980 stond Bart Houdijk aan het roer. Met Barts neefje Sander die als monteur de machines van Houdijk in bedrijf stelt, is ook de vierde generatie Houdijk actief in het familiebedrijf."])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het antwoord op de vraag of het familiebedrijf ook door een vierde generatie Houdijk zal worden bestuurd, zal de toekomst uitwijzen. Hoewel Bart de keuze bij zijn zoons Laurens en Olivier legt, probeert hij hen wel zoveel mogelijk bij het familiebedrijf te betrekken. Barts vrouw Liliana Houdijk: 'Bart gaat stapsgewijs om met Laurens en Olivier wat betreft het werken in de fabriek. Dat ging ook zo bij hemzelf en zijn broers. Het is Bart niet opgedrongen en dat doet hij nu zelf ook niet.' Om kennis te maken met het bedrijf heeft Bart zijn oudste zoon, Laurens, al meerdere keren meegenomen naar de Interpack-beurs en op zakenreis naar Italië en Mexico. Na zijn studie Engineering gaat Laurens in 2022 bij Houdijk aan de slag als junior verkoop- en binnendienstmedewerker. Zo hoopt hij zijn interesse in techniek en commercie te combineren. 'Ik vind techniek interessant en zeker wat Houdijk doet is indrukwekkend. Als ik mee ga naar de klant en daar de machines zie draaien, vind ik dat echt mooi om te zien.' Laurens jongere broer Olivier ziet na zijn studie sociologie eerder een rol in de human resources voor zich. Olivier: 'Mijn vader gaat altijd op zaterdag naar de fabriek en af en toe ga ik met mijn broer samen met hem mee. Het betekent volgens mij heel veel voor hem als wij bij Houdijk gaan werken, maar hij laat ons wel vrij om te doen wat bij ons past.' Gaandeweg krijgen ze wel alvast het een en ander van hun vader mee over het bedrijf en over hoe om te gaan met de mensen die er werken. Laurens: 'Hij leert ons dat het geen zin heeft om mensen hun werk op te dringen of voor te kauwen.' Olivier: 'Hij zegt dat we mensen vooral veel vertrouwen moeten geven om naar eigen inzicht hun werk uit te voeren.'"])}
        },
        {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paragraph"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zelf heeft Bart de uitgesproken voorkeur dat zijn zoons samen het familiebedrijf voortzetten. De één meer aan de buitenkant van het bedrijf en de ander meer intern. 'Dat avontuur gaan we samen aan. Ik denk ook dat ze het echt kunnen. Ik ben er nog een paar jaar om ze mee te nemen, op te leiden en te begeleiden.' Met het oog op de toekomst houdt Bart ook de mogelijkheid open om een nieuw pad in te kunnen slaan met het familiebedrijf. Door Houdijk meer richting robotisering te bewegen, bereidt hij het bedrijf alvast voor om ook buiten de biscuitindustrie actief te kunnen zijn. Daarvan zal het in zijn tijd niet meer komen, want hij heeft zich - zoals hij zelf verklaart - helemaal toegelegd op de biscuits. Waar hij de biscuithandlingmachines van zijn vader en ooms heeft geperfectioneerd, wil Bart zijn zoons die mogelijkheid geven met de bouw van robotlijnen."])}
        }
      ]
    }
  ],
  "memory_items": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herinneringen"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Citaten medewerkers Houdijk Holland"])},
    "items": [
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oud-Voorman Draaierij en ondernemingsraadslid - in dienst tussen 1950 en 1992"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over de familiaire sfeer bij Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Ik heb Bart en zijn broers vroeger weleens naar de gym gebracht. Dat heb ik maar één keer gedaan. Ik zei: 'Nou, dat doe ik niet meer, want straks springen ze in de haven als er wat gebeurt en ik spring er niet achteraan.' Je behoorde wel meteen tot de familie.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaap van Ree"])}
      },
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oud-Voorman Draaierij en ondernemingsraadslid - in dienst tussen 1950 en 1992"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over Wijnand Houdijk senior"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Ik was getrouwd en woonde nog in bij mijn ouders. We hadden wel twee koters. Het waren drie kamers met een keuken, maar ik wilde een eigen huis hebben. Ik had bij Shell gesolliciteerd, want zij beloofden dat ik een eigen huis zou kunnen krijgen. Toen ik dat op de zaak vertelde, werd de oude Houdijk kwaad. Hij heeft een brief naar de gemeente geschreven en is op het gemeentehuis gaan mopperen om een huis voor me te regelen. 'Maar nu ga je zeker niet weg, hè?' vroeg hij.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaap van Ree"])}
      },
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In dienst tussen 1971 en 2016"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over de samenwerking tussen Hooimeijer en Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Mijn vader heeft met Houdijk de Telinmatic ontwikkeld. Een technisch begaafde man, mijn vader. De relatie tussen Hooimeijer en Houdijk is begin jaren 1960 ontstaan. Elke zomerstop voor onderhoud aan de machines was er een ploeg van Houdijk bij mijn vader aan het werk. Ook na de branden die er bij Hooimeijer geweest zijn, stond Houdijk voorop om te helpen. Ik ben erg trots op wat Hooimeijer was en wat Houdijk nu is. Ik vind het oprecht een mooi bedrijf.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jan Joost Hooimeijer"])}
      },
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In dienst tussen 1971 en 2016"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over de werksfeer bij Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Het is een kunst om mensen voor de lange termijn aan je bedrijf te binden. Het gaat uiteindelijk om de mensen, niet om het werk. Als je de mensen hebt, dan wordt het werk wel gedaan. Eigenlijk moet je iemand niet vertellen hoe hij of zij het werk moet doen, je moet diegene jou laten vertellen hoe jij het gedaan wilt hebben. Praat over het werk dat moet gebeuren en zorg ervoor dat iedereen het gevoel krijgt daar een aandeel in te hebben. Dat is ook hoe Houdijk het gedaan heeft.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jan Joost Hooimeijer"])}
      },
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oud-medewerker Verkade/United-Biscuits"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over de samenwerking tussen Verkade en Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“De familie Verkade houdt van kwaliteit. We hadden een Duits bedrijf ingeschakeld voor nieuwe toevoersystemen voor de beschuitlijnen. Die beschuitjes braken vaak bij het verwerken. We vernamen dat Houdijk een nieuw toevoersysteem had ontwikkeld. Een luchtbedmachine en een telsysteem waarmee het aantal beschuiten exact bepaald kon worden. Puur op vertrouwen hebben we er daar toen drie van aangekocht en meegewerkt aan de verdere ontwikkeling. Die machines noemden ze de Telinmatic. Die werkten heel goed en bespaarden ons een hoop geld aan afval. Recenter heeft Verkade ook meegewerkt aan de ontwikkeling van de Skipper, een biscuitdoseersysteem van Houdijk. Ook daar hebben we er drie van gekocht en ze draaien nog steeds naar volle tevredenheid.<br><br> Bij ons kende iedereen de mensen van Houdijk. Van Tom Olsthoorn bijvoorbeeld dachten ze dat hij gewoon bij Verkade werkte, want hij was er zo vaak. Hij wist ook overal de weg. De lijntjes tussen Houdijk en Verkade waren heel kort en duidelijk. Toen ik pas kort bij Verkade werkte, had ik weleens contact met Joop en zijn broer Wijnand. Ik weet nog dat Bart als klein jongetje door de gangen van de oude machinefabriek liep.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerard Langver"])}
      },
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oud-Tekenaar - in dienst tussen 1977 en 2001"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over de familiaire sfeer bij Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“De onderlinge verstandhouding met Houdijk was altijd erg goed. Ik heb 23 jaar lang een leuke tijd gehad en ik kom hier ook ieder jaar even terug. Dan maak ik een praatje en krijg ik een fruitdoosje of een boterletter. Laatst was het een chocoladeletter. Dat is wel uniek eigenlijk. Ik hoorde van een vriend dat hij nooit meer iets van zijn baas hoorde toen hij met pensioen ging. Als iemand bij Houdijk lang voor de zaak had gewerkt en met pensioen ging, organiseerden ze een feest en werd diegene gehuldigd. Dat is een hele andere cultuur.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koos Kesseboom"])}
      },
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receptioniste - in dienst sinds 1978"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over de familiaire sfeer bij Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Meneer Joop Houdijk, Barts vader, was een hele warme en innemende man. Hij gaf altijd even een schouderklopje. Niet alleen bij mij, maar ook bij anderen. Absoluut een prettige werkgever, maar dat is Bart ook. Hij heeft dat wel van zijn vader. Ook de collega's zijn heel fijn. Vooral de 'oude garde' heeft zoveel met elkaar meegemaakt. Vervelende dingen, maar ook leuke dingen zoals bruiloften, geboortes van kinderen, feestjes… Het voelt als familie, een hechte club.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annette Dunweg"])}
      },
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receptioniste - in dienst sinds 1978"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over de familiaire sfeer bij Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Sinds een paar jaar zijn we weer sinterklaasfeesten gaan houden omdat meer collega's kinderen krijgen. Dan krijg je ook meer toenadering naar de 'jonge garde'. In 2020 hadden we vanwege corona een “Sinterklaas Mc-Drive”. Dat liep buiten helemaal rondom het gebouw. Wij bleven in de auto zitten, terwijl de Pieten buiten rondliepen en de kinderen cadeautjes gaven aan het eind van de rit. Dat vonden ze heel leuk.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annette Dunweg"])}
      },
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teamleider Assembly - in dienst sinds 1979"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over de familiaire sfeer bij Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Soms zit je op een leeftijd dat je moet beslissen wat je wilt doen voordat je oud wordt. Sommigen kiezen dan toch om te vertrekken. Ik zeg dan: 'Dat is helemaal jouw keuze, maar de deur staat altijd open.' De een is na een week weer terug en anderen na een paar maanden. Houdijk houdt toch dat familiaire karakter. Bart is erg sociaal en komt je veel tegemoet als je wat wilt. Dat voert hier wel echt de boventoon.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hugo Sluimer"])}
      },
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jongere broer van Bart Houdijk"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over de betrokkenheid van Joop Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Houdijk was altijd goed voor zijn mensen. Zij hadden geen reden om weg te gaan. Ik denk dat vooral mijn vader daarin een bindende factor is geweest. Hij was zeer betrokken bij zijn werknemers en hun families. Vooral de 'oude garde' was zo goed als onderdeel van de Houdijkfamilie. Mensen zijn veel meer bereid om zich voor een familiebedrijf in te zetten dan voor een bedrijf dat alleen gericht is op resultaat voor aandeelhouders.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guus Houdijk"])}
      },
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oudere broer van Bart Houdijk"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over Wijnand Houdijk senior"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Op de zondagen zaten we geregeld bij opa en oma Houdijk. Het was een keurig stel met een keurig huis en een houding waar gezag vanaf straalde. Ik denk dat mijn vader ook zo tegen zijn vader aankeek, als een gezaghebbende man. Tot aan zijn overlijden zat hij altijd keurig netjes in pak met stropdas achter het bureau en zag hij er tot in de puntjes verzorgd uit. Hij heeft vroeger natuurlijk wel zijn handen vies gemaakt, maar dat hebben mijn broers en ik nooit meegemaakt.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arie-Hans Houdijk"])}
      },
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oudere broer van Bart Houdijk"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over Wijnand en Aad Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“We kwamen best vaak bij oom Wijnand of oom Aad over de vloer. Het was traditie om met oud en nieuw en de feestdagen naar de oudste broer te gaan. Oom Aad was wat ziekelijk. Hij had zware astma en dat beperkte hem heel erg in zijn doen en laten. Hij liep altijd in pak en in de rechterjaszak van zijn jasje zat dan de puffer. Die zag je hem geregeld gebruiken. Het was ook wel logisch dat hij nauwelijks op de fabrieksvloer kwam. Hooguit om af en toe zijn gezicht te laten zien. Voor het personeel zijn ze altijd heel goed geweest. Ze waren erg zuinig op hun personeel.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arie-Hans Houdijk"])}
      },
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oudere broer van Bart Houdijk"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over vakantiewerk en Joop Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Toen mijn vader actief werd in het bedrijf, werd ik me ook meer bewust van de fabriek. Ik heb niet het beeld dat hij nooit thuis was, maar hij was wel veel weg. Hij moest toch regelmatig naar het buitenland. Ik ben ook wel een aantal keren met hem mee geweest op klantbezoek op dagen dat ik vrij was. Dan gingen we naar Bolletje of Verkade en keek ik mijn ogen uit in die fabrieken. Ik herinner me de zware bakkerslucht als je zo'n fabriek binnenstapte nog goed. Daar ging mijn vader dan praten en machines verkopen. Hij was echt de man van de verkoop en van het contact met de klanten.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arie-Hans Houdijk"])}
      },
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oudere broer van Bart Houdijk"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over Joop en Bart Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Bart is een heel ander type dan mijn vader. Hij is meer een denker en heeft sneller het overzicht. Mijn vader wilde eigenlijk alles zelf doen. Daar maak je het jezelf niet makkelijk mee. Bart heeft ongetwijfeld ook zorgen, maar hij delegeert meer en kan daar ook makkelijker mee omgaan. Het is bijzonder om te zien hoe Bart het bedrijf heeft opgepakt en uitgebreid. Daar ben ik trots op.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arie-Hans Houdijk"])}
      },
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oudere broer van Bart Houdijk"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over Bart en Sander Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Mijn vader heeft Bart gevraagd of hij de Machinefabriek over wilde nemen. Geen van de andere jongens had volgens mij de ambitie om ooit de fabriek in te gaan. We zagen hoe het mijn vader belastte. Hij was regelmatig weg van huis, sliep slecht en het was te zien dat hij veel zorgen had om de fabriek. Hij kreeg last van zijn hart door de stress en slapeloze nachten. Dat heeft ons er wel van weerhouden om onze vader op te volgen. Uiteindelijk heeft Bart dat wel gedaan. Hij heeft een prachtig bedrijf opgebouwd, veel uitgebreid en goed personeel rondlopen. Ik hoor nooit ontevreden geluiden van de medewerkers. Mijn eigen zoon, Sander, werkt inmiddels al jaren bij Houdijk. Hij is monteur op de werkvloer, maar gaat ook vaak naar het buitenland om daar de machines te monteren. Het was in het begin wel lastig om hier als een Houdijk binnen te stappen. Hij is het neefje van de baas en het was de vraag of hij geaccepteerd zou worden. Hij is redelijk bescheiden dus hij heeft zich daar wel in kunnen vinden.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arie-Hans Houdijk"])}
      },
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigenaar en Directeur - in dienst sinds 1987"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over zijn jeugdige betrokkenheid bij Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Ik was al als kind bij het bedrijf betrokken. Ik deed er vakantiewerk en heb dat jarenlang volgehouden. Dat was nog in het oude pand. Ook tijdens mijn studie heb ik hier gewerkt. Ik ben vaak met mijn vader op zakenreis door Europa geweest. Dan ging hij naar Duitsland of Frankrijk en ging ik mee om de klanten te bezoeken. Toen was ik 15 of 16 jaar. Ik denk dat mijn vader mij al vroeg zag als een potentiële opvolger. Ik heb nooit echt een bewuste keuze gemaakt om bij mijn vader te gaan werken. Ik ben er gewoon zo ingegroeid. Dat was niet vanwege wilde ambities of iets dergelijks, maar ik heb er nooit spijt van gehad.“"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bart Houdijk"])}
      },
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigenaar en Directeur - in dienst sinds 1987"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over Joop Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Mijn vader had er veel moeite mee dat hij niet meer kon werken en de zaak aan mij moest overlaten. Moeite om het mij aan te doen om in zo'n moeilijke tijd het bedrijf voort te zetten. Hij heeft de echte bloei niet gezien, maar wel dat we uit het dal kwamen. Hij kon eigenlijk niet echt meer met me over het bedrijf praten. Letterlijk niet, want dat gaf hem al te veel stress. Dat was ook de reden waarom hij moest stoppen. Hij kwam hier weleens koffie drinken, maar hield het nog geen halfuur uit. Kortom, als mijn vader binnenstapte, overviel hem alle spanning weer.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bart Houdijk"])}
      },
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur SNL Group Schijndel, toeleverancier Houdijk"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over de samenwerking met Bart Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Bart is van de generatie die de honderd jaar Houdijk volmaakt. Dat authentieke heeft wel iets. Hij heeft niet voor niets schilderijen van zijn vader en ooms in zijn kantoor hangen. Dat tekent wel het beeld dat Houdijk nog echt een familiebedrijf is. Met Houdijk heb ik een speciale band, maar ook met Bart zelf. We sparren regelmatig, over praktische zaken en bijvoorbeeld over zijn opvolging, dat het een familiebedrijf is en dat dat een van de krachten is van Houdijk. Ik kom weleens over de vloer bij voormalige familiebedrijven die door investeerders zijn opgekocht. Daar gaat al snel de ziel uit. Die ziel zit hier wel.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frans Lammers"])}
      },
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercieel Directeur - in dienst sinds 2005"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over de voordelen van een familiebedrijf"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Familiebedrijven zijn het meest succesvol in het soort markten waar Houdijk in zit. Bart is altijd met de langetermijnvisie bezig. Hij hoeft geen dividend uit het bedrijf te trekken, want hij is de enige aandeelhouder. Dat geld blijft binnen de onderneming, terwijl de puur op winst berekende bedrijven zoveel mogelijk binnen willen harken. Dat gaat ten koste van investeringsruimte om het bedrijf te versterken. Op de beurs kwam eens een bekende naar mij toe en vertelde dat hij altijd als eerst naar de stand van Houdijk gaat om te zien wat voor nieuwe machines er staan. Dat is leuk om te horen.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marnus Rotte"])}
      },
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["R&D Engineer - in dienst sinds 2013"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over de betrokkenheid van Bart Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Bart is erg betrokken bij de medewerkers op grote projecten. Hij is altijd op de hoogte van problemen die bij klanten spelen. Toen ik laatst in Canada was voor een project, stuurde hij me 's avonds nog een bericht om te vragen hoe het gegaan was. Ik reageerde wat later, maar realiseerde me dat het in Nederland al nacht was. Toch kreeg ik nog een berichtje terug met verdere vragen van hem.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menze Jan Buitenhuis"])}
      },
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test Engineer - in dienst sinds 2013"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over de omgang met collega's"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Met de feestcommissie organiseer ik elk jaar een aantal evenementen, zoals de 'Winter' en 'Summer Games'. In de winter zijn er dan binnenactiviteiten zoals poolen, darten, curling of tafelvoetbal. In de zomer zijn er veel buitenactiviteiten zoals basketbal, voetbal of een stormbaan. Recent is daar de pub-quiz bijgekomen. Het doel van dit soort evenementen is dat collega's van diverse afdelingen elkaar op een andere manier leren kennen. De dagen na zo'n evenement merk ik dat de collega's elkaar toch wat jovialer begroeten en dat de samenwerking vaak net wat soepeler verloopt. Het organiseren van de evenementen wordt erg gewaardeerd door de collega's en dat maakt het leuk om te doen.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dion Hofland"])}
      },
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissioning Engineer - in dienst sinds 2018"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over de familiaire sfeer bij Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“De bedrijfscultuur hier is heel open. Ik las laatst een welkomstbericht van een nieuwe software engineer en die liet meteen weten wat een fijne werksfeer er hangt. De onderlinge gezelligheid is bij ons op de afdeling wel echt een bron van motivatie. Geen vraag is te stom en je wordt gelijk meegenomen in alles wat speelt. Ook buiten werktijd gaan we met elkaar om en gaan klimmen of hardlopen. Werk en privé zijn dan niet strikt gescheiden, maar mensen willen ook echt met elkaar omgaan binnen en buiten werktijd.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sebastiaan Vijfhuizen"])}
      },
      {
        "category": 1,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissioning Engineer - in dienst sinds 2018"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over de werksfeer bij Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Bart luistert echt naar de mensen bij Houdijk. Wij zijn wel altijd welkom om een praatje te maken met Bart. Andersom ook, als hij iets interessant vindt waar je mee bezig bent, belt hij je gerust op om te vragen hoe het gaat en of je hulp van hem nodig hebt. Dat siert ook een aantal managers hier. Die zeggen het gewoon wanneer ze iets tof vinden en geven complimenten. Dat is heel waardevol en doe ik andersom ook graag. Je merkt dat mensen die complimenten over en weer erg waarderen.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sebastiaan Vijfhuizen"])}
      },
      {
        "category": 2,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oud-Voorman Draaierij en ondernemingsraadslid - in dienst tussen 1950 en 1992"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over werken aan de 1e van Leyden Gaelstraat"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Toen ik ging werken in 1950 was het natuurlijk vijf jaar na de Oorlog. Je had niet veel. Meetapparaten had je ook niet, op een duimstok na. We maakten zelf een krukje om op te zitten als we gingen schaften.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaap van Ree"])}
      },
      {
        "category": 2,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oud-Bankwerker en Monteur - in dienst tussen 1956 en 1996"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over zijn sollicitatie bij Wijnand Houdijk senior"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Na een ruzie met mijn toenmalige werkgever ging ik nog dezelfde avond langs bij Houdijk. Ik kwam terecht bij de oude baas, Wijnand senior, die vroeg wat ik kwam doen. Ik zei dat ik kwam kijken of er werk was, want ik had ruzie gehad. Hij vroeg 'van wie ben je er eentje?' Vlaardingen is natuurlijk betrekkelijk klein en er waren mensen die op een soort zwarte lijst stonden bij Wijnand. Dan had hij daar niks mee, maar er waren ook mensen met wie hij wel door één deur kon. Als je daar bij hoorde, had je mazzel. Mijn vader kwam regelmatig bij Houdijk, want hij werkte op het transport, met paard en wagen nog. Houdijk had altijd ijzer nodig en dat kwam mijn vader brengen. De oude Houdijk zag dan mijn naam en vroeg of ik daar familie van was. Ik zei natuurlijk van wel. 'Je kan meteen beginnen,' zei hij.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jan de Priëlle"])}
      },
      {
        "category": 2,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oud-Bankwerker en Monteur - in dienst tussen 1956 en 1996"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over werk in de Sovjet-Unie"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Ik zat een keer met een ploeg in het communistische Kazachstan om een hele koeklijn op te zetten. Er waren allerlei Russen, Joegoslaven en dergelijken aan het werk. Wat wij moesten doen was het eindproduct verdelen in plastic doosjes. Daar hadden we echter elektriciteit voor nodig en dat hadden we daar niet. 'Dat komt morgen,' werd er steeds gezegd. Wij zaten in de tussentijd te niksen. Op een gegeven moment moest ik op kantoor komen. De Joegoslavische bedrijfsleider vertelde dat mijn vrouw problemen had aan haar hart, maar het zou wel goed gaan met haar. Ik wilde direct naar huis. Ik had kinderen en ga daar natuurlijk niet blijven zitten, maar dat kon allemaal niet. Ze hadden mijn paspoort ingenomen en wilden het niet teruggeven. Na een aantal keer vragen kwam ik een Joegoslaaf tegen die ook naar huis moest. Hij kwam op een avond naar me toe en zei dat hij de volgende ochtend naar huis ging. Hij had een autootje geregeld, dat moest ook wel, want we zaten 80 kilometer bij het vliegveld vandaan, en als ik wilde kon ik meerijden. Ik ging naar de bedrijfsleider toe en zei dat ik mijn paspoort nu nodig had, want ik ga morgen weg. Hij zei dat het allemaal niet zo erg was en hier nog werk lag. Ik zei 'doei, ik ga weg.'”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jan de Priëlle"])}
      },
      {
        "category": 2,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oud-Bankwerker en Monteur - in dienst tussen 1956 en 1996"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over een werkincident"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“We gingen een keer naar een visboer hier in Vlaardingen om een ketel te reviseren en schoon te maken. Die ketel moest om de zoveel jaar gekeurd worden, dus alle kranen werden afgesloten. Binnenin moest het ook schoongemaakt worden, daar zaten allemaal leidingen. Het was op een zomerdag en als je daar een kwartier in had gezeten, moest je er gauw weer uit om goed adem te kunnen halen. Een van de jongens had toen bedacht dat hij er wel een beetje frisse lucht bij kon doen. Hij had een zuurstoffles met de slang in de ketel gelegd, kraantje open en dan kreeg je zuurstof daarbinnen. Hij besefte alleen niet hoe gevaarlijk dat was. Pure zuurstof gaat in je kleren zitten. Toen hij een sigaretje opstak, stond hij ineens in de brand. Naast de ketel stonden grote vaten met pekelwater voor de haring, dus wij hebben hem daar toen snel in gedonderd. Hij heeft er geen brandwonden aan overgehouden, maar zijn haar was er wel af.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jan de Priëlle"])}
      },
      {
        "category": 2,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technisch Supervisor - in dienst sinds 1971"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over zijn eerste werkjaren"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Ik was de eerste lichting op de Lagere Techniek School die officieel met een diploma van school kwam. Toen we dat diploma hadden, kwam het arbeidsbureau langs op school. Bedrijven in de omgeving hadden mensen nodig. Ze vroegen mij wat ik wilde gaan doen, maar dat wist ik eigenlijk niet. Ik was pas vijftien jaar. Toen vroegen ze waar ik de beste cijfers in had. Nou, dat was dus constructiebankwerk en toen kreeg ik twee adressen waar ik kon gaan solliciteren. Een daarvan was Houdijk en de ander ergens in Schiedam. Ik ging eerst maar eens in Vlaardingen kijken, toen zaten ze nog in de Van Leyden Gaelstraat. Ik kwam daar binnen en ging met Joop en Wijnand Houdijk aan tafel zitten. Ze vroegen mij: 'Dus, jij wilt hier komen werken? Wil je dan ook de bedrijfsopleiding tot meter doen?' Dat wilde ik wel, daar zeg ik geen nee tegen. 'Mooi, wanneer kan je beginnen?' Dat was het sollicitatiegesprek toen.<br><br> Ik werd meteen aan een oudere collega gekoppeld om het vak te leren, want ik was natuurlijk nog leerling. Pas als ze een beetje wisten wat je kon en of je wat in je mars had, kreeg je wel eigen klusjes om te doen. Ik heb het altijd prima met mijn collega's kunnen vinden, iedereen was bereid om je wat te leren. Ik ben dus als leerling begonnen en na een jaar of vijf à zes voorman geworden. Vanuit die functie ben ik projectleider geworden, daarna chef van de eindmontage en ook hoofd van de hele montageafdeling. Ik groeide in mijn werk, dat steeds moeilijker werd. Dat is nu heel anders dan vroeger. Nu moet je van tevoren al laten zien wat je in je mars hebt voordat je in de praktijk kan laten zien wat je kunt.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinus van Driel"])}
      },
      {
        "category": 2,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technisch Supervisor - in dienst sinds 1971"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over het vakantiewerk van Bart Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Samen met Tom Olsthoorn had ik een klus bij Hooimeijer in Barendrecht, waar we een beschuitlijn moesten reviseren. Dat was midden in de zomer, stikheet. Toen kwamen we terug op de zaak en deden we verslag hoe het ervoor stond en wat we nog moesten doen. Ik vertelde dat ik er een man bij moest hebben, want het lukte niet helemaal. Ze hadden nog wel een vakantiemannetje, zeiden ze. Ik had nog wel wat simpel werk voor diegene, dus dat was goed. Toen kreeg ik dus Bart mee. Die arme jongen heeft een week of wel langer onder de machine gelegen met een boormachine om gaatjes te boren. Dat deed hij zonder morren, dat moet ik hem echt nageven. Geen wanklanken of schelden, hij ging gewoon zijn gang. Af en toe moesten we hem eruit halen om even een bakkie koffie te doen en dan ging hij weer verder.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinus van Driel"])}
      },
      {
        "category": 2,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oud-Hoofd Productie - in dienst tussen 1974 en 2006"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over het werk aan de 1e van Leyden Gaelstraat"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“In het oude gebouw aan de Van Leyden Gaelstraat was het ontzettend moeilijk werken. Het waren allemaal aparte ruimtes, omdat dat stukje bij beetje werd uitgebreid. Als er dan een machine opgehaald moest worden, moesten we in een zijstraatje een kraan neerzetten die de machine op de vrachtwagen kon tillen. Dat ging allemaal zo verschrikkelijk omslachtig ten opzichte van nu.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinus Deuster"])}
      },
      {
        "category": 2,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technisch Specialist - in dienst sinds 1975"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over zijn eerste werkjaren"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Toen ik hier kwam werken, ging ik al snel aan de slag bij Rinus Bot achter in de oude touwslagerij, Telinmatics maken. Dit waren de onderdelen uiteraard, want we maakten alles zelf. Dagenlang materiaal zagen, aftekenen en boren. Dat was een heel project en liep je van begin tot eindlevering mee. Als je je eigen materiaal ging zagen, moest je dat ook allemaal afbramen, schoonmaken, aftekenen, boren en overspuiten ook vaak nog. Hamerslag Blauw werd dat dan, dat dekte altijd.<br><br>Op een gegeven moment zagen de chefs dat ik meer geschikt was voor de eindmontage, dus ben ik me ook bezig gaan houden met het bekabelen van de elektriciteit. Daarvoor heb ik heel veel cursussen gevolgd. Die betaalde Houdijk, maar volgde je natuurlijk in je eigen tijd. Dat waren allemaal avondcursussen, een of twee avonden in de week. Ik heb veel mogen leren bij Houdijk. Daar kregen we de ruimte voor.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tom Olsthoorn"])}
      },
      {
        "category": 2,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oud-Tekenaar - in dienst tussen 1977 en 2001"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over zijn eerste werkjaren"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Ik werkte voorheen bij Cincinnati Milacron, ook in Vlaardingen, op de tekenkamer. Vanwege de recessie werd er steeds minder verkocht en volgden er ontslaggolven, daar zat ik ook bij. Mijn vorige werkgever raadde me aan om bij Houdijk te informeren. Ik had een gesprek met Joop Houdijk, maar ze hadden toen niemand nodig. Hij had wel alles opgeschreven. Een paar maanden later was ik bezig mijn zolder te betimmeren en stond Joop ineens voor mijn deur. 'Ben je nog werkloos?' vroeg hij, 'Nou kom vanmiddag maar eens langs dan.' Na een gesprek zei hij dat we de maandag erop zouden beginnen. 'Als het niet goed loopt, hoor je dat, en anders hoor je niks.' Ik hoorde veertien dagen niks, dus dat was helemaal goed.<br><br>We zaten toen op de Van Leyden Gaelstraat. Dat waren een aantal oude vissershuisjes die ze aan elkaar gekoppeld hadden en de muren doorgeslagen. Beneden was de machinefabriek en boven hadden we de tekenkamer. De chef van de tekenkamer was ook de hoofdconstructeur. Die kreeg dan een opdracht via de afdeling Verkoop en maakte daar dan een grote, globale schets van. Als hij de elektronica en mechaniek had uitgetekend, riep hij ons erbij om het verder uit te werken zodat de fabriek ermee aan de slag kon.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koos Kesseboom"])}
      },
      {
        "category": 2,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oud-Tekenaar - in dienst tussen 1977 en 2001"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over nieuwe technologie op de werkvloer"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“In het begin hadden we tekenborden staan, daarna ging het over op computers. Ik was al 58 toen ik met de computer begon te tekenen. Dat was voor mij toen wel allemaal nieuw, terwijl die jongere gasten al meer ervaring hadden en het zich makkelijker eigen konden maken. We vonden het wel leuk. We hadden geen potlood of gummetje meer nodig. Het werken met de computer gaat veel sneller en je maakte veel minder fouten. Daarvoor kwamen gebreken pas aan het licht als de machine al klaar was. Dat was niet zo'n gezellige situatie, zeg maar. Dat gaat allemaal digitaal nu. Ze kunnen eigenlijk al een machine die in hun hoofd zit uittekenen en digitaal laten werken. Ze kunnen al zien hoe het werkt voordat ze het hebben gemaakt.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koos Kesseboom"])}
      },
      {
        "category": 2,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oud-Tekenaar - in dienst tussen 1977 en 2001"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over de werksfeer"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Er werd hard gewerkt en ook veel overgewerkt. Hier in dit gebouw heb ik weleens 's avonds alleen gewerkt. Ik had de sleutel. Op een gegeven moment was ik aan het tekenen, het was doodstil. Ineens zie ik het koppie van een bewaker die stilletjes naar binnen was gegaan boven mijn bord uitkomen. Ik schrok me wezenloos.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koos Kesseboom"])}
      },
      {
        "category": 2,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receptioniste - in dienst sinds 1978"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over vrouwen bij Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Het voelde gelijk warm toen ik binnenkwam, heel bijzonder eigenlijk. Dat gold ook voor de ontvangst door Barts vader en ooms. Ik stapte de wereld van techniek in. Dat was natuurlijk een mannenwereld, maar met de jongens uit de fabriek klikte het gelijk. Er was een ouderwetse boekhoudster van een jaar of zestig, Juffrouw Nettie heette ze. Samen met Bep de koffiejuffrouw waren wij de enige drie dames die er rondliepen. Wij haalden zelf ook weleens streken uit: stiekem vieze dingen op het brood van de jongens smeren of de nieuwe fiets van de directeur in de takels hangen. De geuren staan me nog wel het meest bij... Ik zou ze nog zo kunnen ruiken: van de boorolie, de draaierij en de freesmachines.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annette Dunweg"])}
      },
      {
        "category": 2,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receptioniste - in dienst sinds 1978"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over de Interpack-beurs"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Ik ging vroeger ook mee naar de Interpack, de beurs in Duitsland om de drie jaar. In het begin hadden we een klein standje. Ik zorgde dan voor de koffie en de telefoon en Bart was er met twee verkopers. Toen ik daar de eerste keer rondliep, vond ik het prachtig, zo groot dat het was. Nu hebben we dus zelf een van de mooiere en grotere stands. Daar ben ik gewoon echt trots op.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annette Dunweg"])}
      },
      {
        "category": 2,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigenaar en Directeur - in dienst sinds 1987"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over zijn houding ten opzichte van werknemers"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Ik zie mezelf ook wel als een van het team. Ik ben helemaal niet zo van het spelen van de baas. De grote knopen hak ik zelf door, maar verder wil ik het ook vooral samen met elkaar doen. Ik benader ook altijd nieuwe medewerkers, want daar heb ik dan een kennismakingsgesprek mee. Ik laat ze dan altijd eerst een maandje ploeteren, dan weten ze hoe de hazen lopen en hebben we een kennismakingsgesprek. Dan vertel ik de geschiedenis van Houdijk in vogelvlucht, over wie ik ben en sluit ik af met een aantal cultuurwaarden die ik wil meegeven: de klant centraal stellen, een collegiale werksfeer en orde en netheid!”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bart Houdijk"])}
      },
      {
        "category": 2,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oudere broer van Bart Houdijk"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over de Machinefabriek aan de 1e van Leyden Gaelstraat"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Als ik nu terugkijk was het vooral oud en vies, echt een machinefabriek waar werd gewerkt met smeerolie en vet en troep op de grond. Het zijn vooral de geuren die me zijn bijgebleven, van olie en smeermiddelen, ook de geur van een soort verbrand ijzer, want ze waren altijd bezig met lassen, frezen, boren of zagen. Toen was het echt vies en ruw werk. Mannen met vieze handen en overalls die zwart zagen van de olie en het smeer. Dat is niet te vergelijken met nu. Het is nu zo keurig, glad en netjes.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arie-Hans Houdijk"])}
      },
      {
        "category": 2,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oudere broer van Bart Houdijk"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over de Machinefabriek aan de Trawlerweg"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Het eerste pand hier aan de Trawlerweg was maar een schijntje van wat er nu staat. Het was een grote hal met frees- en draaimachines, nog echt een vuile fabriek. Lang niet zo vuil als aan de Van Leyden Gaelstraat en lang niet zo schoon als nu, maar het was wel echt een fabriek met machines die geurden en herrie maakten. Dat is gigantisch veranderd. In het vakantiewerk dat ik deed, hielp ik dan met schoonmaken en vegen. Van het frezen en draaien kwamen ijzerkrullen die op de grond vielen met een soort melkachtig smeermiddel, dus dat moest weggeveegd worden. Het helpen met de vuile klusjes op de fabrieksvloer deed ik vooral met mijn oudste broer Wijnand. Ik weet eigenlijk niet eens of we er iets mee verdienden. We hebben vast wel een kleinigheidje gekregen van juffrouw Nettie, ze was naar ons toe altijd wel lief.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arie-Hans Houdijk"])}
      },
      {
        "category": 2,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jongere broer van Bart Houdijk"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over de werksfeer aan de Trawlerweg"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Ik heb hier veel vakantiewerk gedaan. Krullen vegen, schoonmaken, een beetje met de heftruck rijden, schilderen. Het maakte niet uit dat ik de zoon van de directeur was. De oude garde hier ken ik ook allemaal goed, al meer dan veertig jaar. Dat al het maakwerk later is uitbesteed en dat dat voor veel vakmensen consequenties had, begreep ik, maar vond dat wel jammer. Ik zie nog altijd Wim van der Schee achter zijn tekenbord staan. Dat is niet meer. Ook de geintjes van toen zijn een stuk minder geworden. Vroeger stuurden ze weleens een broekie Vlaardingen in om allemaal domme, niet-bestaande dingen zoals hamerstelenvet te halen. Dan kwam diegene bij een winkelier, die het geintje meteen doorhad, en stuurde hem door naar een andere winkel aan de andere kant van Vlaardingen. Als hij dan heel Vlaardingen door geweest was en weer terugkwam, lachten ze hem uit. Dat gebeurt nu niet meer denk ik, want de jongens zijn veel bijdehanter geworden. Daar trappen ze nu niet meer in.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guus Houdijk"])}
      },
      {
        "category": 2,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["R&D Engineer - in dienst sinds 2013"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over werken bij Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Het leuke aan mijn werk is dat we alles uitdenken, tekenen, testen en laten maken. Dan zie je ook meteen op de vloer wat je bedacht hebt. Hiervoor werkte ik bij een offshorebedrijf, waar je een paar foto's toegestuurd krijgt en alles vanachter je bureau doet. Hier loop je zo de werkplaats in en zie je jouw creatie gemaakt worden. Je hebt meteen een gevoel bij je product. Ik loop 's ochtends ook altijd een rondje door de werkplaats. Dan sta ik te kijken en denk ik: 'Kijk, dat ziet er weer mooi uit.' Daar wordt ik enthousiast van. Het leuke aan Houdijk is dat we soms machines verkopen waarvan we nog helemaal niet weten of we die wel kunnen maken. Aan de ene kant wil je als engineer graag dat je zeker weet dat wat je maakt ook echt gaat werken, maar de uitdaging om meer te doen dan je denkt te kunnen is heel leuk.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menze Jan Buitenhuis"])}
      },
      {
        "category": 2,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["R&D Engineer - in dienst sinds 2013"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over werken bij Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Als engineers moeten we goed letten op wie onze machines gaan gebruiken. Soms worden de bedieners 's ochtends van straat geplukt om in de koekfabriek te werken. Daar moet je dus wel een ander soort machine voor ontwikkelen dan bijvoorbeeld voor iemand die een computerchipmachine bedient. Hij moet precies weten wat hij doet. Wij kunnen wel bedenken dat hij van alle knopjes die erop zitten, alleen op dit specifieke knopje moet drukken om de machine te starten, maar als het niet intuïtief is, dan blijft de machine uit. We schrijven wel handleidingen, maar iemand anders moet wat we maken ook logisch vinden.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menze Jan Buitenhuis"])}
      },
      {
        "category": 2,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test Engineer - in dienst sinds 2013"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over ruimte voor eigen inbreng"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“We krijgen als Test Engineer veel vrijheid en verantwoordelijkheid. Dat vind ik fijn. We hebben de mogelijkheid om te leren, onszelf te ontwikkelen en te laten zien wat we kunnen. Toen ik bij de Modelshop begon, zag ik dat er best inefficiënt gewerkt werd. Zelf vond ik het nodig om daar meer structuur en organisatie in aan te brengen. Na akkoord van mijn manager heb ik het hele testproces opnieuw uitgewerkt. Nu hebben we een hele goede basis en daar geniet ik elke dag van.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dion Hofland"])}
      },
      {
        "category": 2,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissioning Engineer - in dienst sinds 2018"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over zijn meest memorabele project"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Ik kwam Houdijk binnen als eindmonteur en ben niet lang daarna Commissioning Engineer geworden. De overstap daartussen was wel interessant. Als eindmonteur zette ik hier een machine die naar Duitsland ging in elkaar. In de tijd waarin die getest werd, werd ik Commissioning Engineer en kon ik diezelfde machine ook helemaal opleveren. Het hele ontwikkelproces en de afronding van het project vond ik heel mooi om te doen. Die machine is wel echt mijn kindje geworden.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sebastiaan Vijfhuizen"])}
      },
      {
        "category": 3,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technisch supervisor - in dienst sinds 1971"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over innovatie bij Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Het ontwikkelen en innoveren gaat via de afdeling Research & Development. Daarin is Bart een belangrijke drijfveer. Hij komt vaak met out of the box ideeën. Het zit hier vol met techneuten en dan komt Bart opeens met een idee waar wij nog niet aan gedacht hadden. Dat is zo omdat hij erbuiten staat, hij is geen techneut. Soms gooien we die ideeën ook wel in de prullenbak, maar er zitten zeker hele goede tussen waar we dan op inspelen. Dat kan ook omdat de lijntjes heel kort zijn. Als ik bij wijze van spreken Barts kantoor binnenloop omdat ik wat wil vragen, dan doe ik dat.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinus van Driel"])}
      },
      {
        "category": 3,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teamleider Assembly - in dienst sinds 1979"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over wat Houdijk onderscheidt van anderen"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Houdijk heeft denk ik weinig concurrentie omdat het zich verder heeft ontwikkeld in de wat moeilijkere projecten. Vroeger was het zo dat als er een klant kwam met een gek of nieuw idee en onze concurrenten zeiden dat ze dat niet gingen doen, dan konden ze altijd bij Houdijk terecht. Soms gingen we op onze snufferd en maakten we verlies, soms maakten we winst. Ook in landen als China hebben we niet of nauwelijks concurrentie, ook al waren er wel mensen die delen van onze machines probeerden te kopiëren. We staan natuurlijk op beurzen om te laten zien wat we maken. Wij zijn altijd open om onze machines te laten zien en vragen te beantwoorden, zolang ze er maar niet onder gaan liggen om te fotograferen. Er zitten eigenlijk ook geen geheimen in, maar het programmeren van onze machines is zo moeilijk, dat je dat niet zomaar kunt kopiëren. Een framepje en een transportband kan iedereen, dat is niet zo moeilijk. Wat daarna komt - het maken van een rolletje, vullen van een tray, het opstapelen - daarin moet je softwarematig heel veel tijd en energie in steken. Daarin hebben we echt een voorsprong en daarom ook weinig concurrentie.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hugo Sluimer"])}
      },
      {
        "category": 3,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager Operations - in dienst sinds 2015"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over innovatie bij Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“We zijn wie we zijn omdat we altijd innovatieve machines maken en nieuwe oplossingen bedenken. Dat is vooral op basis van de kennis en ervaring van Bart, Marnus en Sales. Zij weten wat er in de markt gebeurt of polsen bij de klant waar behoefte aan is. Ook zien ze nieuwe ontwikkelingen zoals het reduceren van plastic verpakkingen, wat eigenlijk door de Europese Unie opgelegd wordt. Onze klanten moeten dan overgaan op kartonnen verpakkingen en vragen ons om daar iets voor te bedenken. We hebben ook andere klanten die allerlei nieuwe producten hebben en dat wel over een en dezelfde productielijn willen laten gaan. Dan maken wij die lijn zo flexibel mogelijk. Op die manier wordt er dan door Research & Development eigenlijk een nieuw concept bedacht. Die innovaties zijn wel echt een uitdaging voor ons. Eigenlijk laten we op alle grotere beurzen wel iets nieuws zien. Dat is ook wel echt ons doel. Op de Interpack-beurs bijvoorbeeld, dat is voor ons de grootste en meest interessante beurs, komen mensen eerst bij Houdijk langs om te zien wat er weer nieuw is in de markt. Op de beurzen daarna zie je dan anderen met bijna dezelfde machines staan.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["John Kasbergen"])}
      },
      {
        "category": 3,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissioning Engineer - in dienst sinds 2018"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over innovatie bij Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Als Commissioning Engineers hebben we best wel wat invloed op de innovaties van Houdijk. Wij zijn de laatste die bij de klant zijn. Als er iets niet werkt, moeten we ter plekke een andere oplossing zien te verzinnen. Soms is die nieuwe oplossing beter dan wat in eerste instantie was bedacht en wordt dat de nieuwe standaard. Als wij denken dat iets een toegevoegde waarde heeft voor een machine, dan geven we dat door, zodat onze verbetering misschien wel gestandaardiseerd wordt.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sebastiaan Vijfhuizen"])}
      },
      {
        "category": 3,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissioning Engineer - in dienst sinds 2018"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over Bart Houdijk en innovatie"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Het is mooi om te zien hoe heel Houdijk zich op nieuwe ontwikkelingen stort en het gewoon gaat uitvoeren. We hebben ook genoeg vertrouwen in de mensen hier om dat te doen, maar Bart is ook wel zo eerlijk om wat te minderen als hij de druk te hoog opvoert. Soms denkt hij dat er meer kan dan dat wij zelf denken te kunnen. Dat zorgt er dan voor dat wij doen wat we zelf niet mogelijk dachten. Hij legt de lat steeds iets hoger. In 2020 hadden we echt een enorm druk jaar. Naast de lopende projecten hadden we ook een aantal nieuwe machines die allemaal veel aandacht eisten. De Capper mark III was nog vol in ontwikkeling, net als de Vision Selector en de nieuwe Cookiebot. Dat was toch wel iets te veel voor ons. Bart heeft toen ook gezegd dat er nog veel ontwikkelingen en ideeën waren, maar dat hij die nu toch even op de plank legde om uit te kristalliseren wat al in ontwikkeling was."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sebastiaan Vijfhuizen"])}
      },
      {
        "category": 3,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technology Advisor - in dienst sinds 1988"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over de ontwikkeling van de Capper"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Een concurrent maakte al voordat wij ermee kwamen de Cookie Capper. Op een gegeven moment bedachten we dat wij dat ook konden en waarschijnlijk ook beter. Toen hebben we de Lead Engineer uitgedaagd om een nieuw en beter systeem te ontwerpen. Die heeft toen iets bedacht dat qua hygiëne, bereikbaarheid en onderhoud op hoger niveau lag. De eerste jaren heeft de Capper alleen maar geld gekost, maar nu maken we er wel winst op. De Capper is gestandaardiseerd en daarom zijn ze makkelijk voor iedere bestelling opnieuw te maken. Maar we willen wel graag innoveren, dus veel onderdelen worden voortdurend doorontwikkeld. De meest recente en verbeterde Capper noemen we de Mark 3. Van groot belang hierbij is onze productkennis, want wij weten precies hoe je een koekje moet oppakken en wat er nodig is om ze in een tray te leggen. Op de Interpack-beurs van 2014 stonden er twee kopieën van onze machines. De Capper was gefotografeerd en nagebouwd. Aan de buitenkant leek het er inderdaad op, tot in de kleine details, maar ik heb nog niet gehoord dat die ook echt werken. Naast de mechanica en machinebouw, zit er ook in de software een hoop kennis over het product. Dat is niet zomaar te kopiëren. Daarvoor hebben wij onze hoogopgeleide mensen.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jo Brand"])}
      },
      {
        "category": 3,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercieel Directeur - in dienst sinds 2005"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over de ontwikkeling van de Capper"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Aan de Houdijk Capper is best een lang ontwikkeltraject voorafgegaan, maar daar zijn we nu qua technologie leidend in. We hadden alle verstand van biscuitjes, maar we wisten niks van crème. Dat is een hele andere wereld. Biscuitjes kan je in millimeters uitdrukken en die crèmes zijn temperatuur, vloeibaarheid, druk. Een stofje van het ene erin en het doet dit, een stofje van het andere erin en het doet wat anders. Daar hadden wij geen gevoel bij, want dat is niet berekenbaar. Zoals een kok, die heeft zijn stofjes en maakt een sausje. Daar moet nog wat bij om het dikker te maken of juist weer wat soepeler. Flexibiliteit is daarin heel belangrijk. De markt is koning, dus als ze in China Green Tea crème willen in plaats van de witte crème uit Amerika, dan krijgen ze Green Tea.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marnus Rotte"])}
      },
      {
        "category": 3,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissioning Engineer - in dienst sinds 2018"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over de nieuwste versie van de Capper"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“De bedoeling van de Capper is dat er koeken met een vulling ertussen uit de machine komen. We hebben nu de nieuwste versie van deze Capper. Dat is de mark III. Het nieuwe en innovatieve daaraan ten opzichte van de twee versies daarvoor, is vooral de keermethode van de koeken. De eerste had koekstromen om en om, waarbij de ene al gekeerd was en de andere niet. Dus moesten wij er al voor de Capper met glijgootjes voor zorgen dat de ene helft van wat uit de oven kwam omgekeerd werd en de andere helft niet. De versie daarna deed het iets anders. Daar werden de koeken met keerpinnetjes binnen in de Capper omgedraaid, dus niet meer buiten de machine om. Dat scheelt wat ruimte in de lengte van de productielijn, maar vooral tijd. Daarmee hadden we een grote tekortkoming van de eerste versie opgelost. Het keren van producten bleef alleen nog een beperking, dat duurde nog steeds te lang. Bij de Capper mark III nemen we de koeken mee met een lineaire aandrijving en worden ze in één proces om-en-om gekeerd en uitgelijnd. Daarmee is de machine weer 30 procent sneller geworden dan de vorige versie.“"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sebastiaan Vijfhuizen"])}
      },
      {
        "category": 3,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technology Advisor - in dienst sinds 1988"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over de Vision Selector"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Bij de efficiëntie van een lijn gaat het eigenlijk om het voorkomen van verstoringen in het productieproces. De Vision Selector beoordeelt de koekjes van de klant en gooit de slechte weg om te voorkomen dat het systeem kan vastlopen. Het is natuurlijk jammer dat je producten weggooit, maar als je dit niet doet, moeten mensen letterlijk graven om de machine onder de koekjes vandaan te halen, en daarna alles weer schoonmaken. De koekjesproductie gaat in onvoorstelbare hoeveelheden. Als het even misgaat, kun je bijna van de koekjesberg af skiën. Om de efficiëntie van zo'n lijn te verbeteren en afval te voorkomen, is de Vision Selector ontwikkeld. In het begin bleek het lastig om met een camerasysteem heel nauwkeurig te beoordelen of een individueel koekje goed of niet goed was. Gelukkig is dat ook niet altijd nodig. Een koekje smaakt niet slechter als het een beetje scheef is. De Vision Selector zorgt er in eerste instantie voor dat de lijn niet vastloopt. We garanderen dat wat er doorheen gaat, niet voor een opstopping zorgt. Als een koekje aan een ander vastgebakken zit, dat gebeurt soms, dan zou het hoe dan ook ergens vastlopen. Al die andere koekjes willen er ook doorheen en dat wordt een grote puinhoop. Met de Vision Selector zorgen wij dan voor het uitfilteren van slechte aanvoer. Als koekjes van een bepaald formaat er opeens veel groter uitkomen, zegt de Selector 'hup, in de vuilnisbak' en loopt het hele systeem niet vast.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jo Brand"])}
      },
      {
        "category": 3,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["R&D Engineer - in dienst sinds 2013"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over de Cookie-Dozer"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“De meest recente innovatie waar ik de laatste tijd veel aan gewerkt heb, is de Cookie-Dozer. Dat is een nieuwe machine, waarvan ik hoop dat het een succes in de markt gaat worden. Met een camera kijken we waar de koekjes op een transportband liggen en we pakken ze op met een Cookiebot. Die legt de koekjes netjes in het juiste patroon op een afvoerband die naar de Cookie-Dozer gaat. De Cookie-Dozer veegt al die koekjes op een kunststof plaat en voegt alles samen om er een rolletje, stapeltje of traytje van te maken. Daarvan zijn er nu twee lijnen in de maak op de vloer van de werkplaats.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menze Jan Buitenhuis"])}
      },
      {
        "category": 4,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigenaar en Directeur - in dienst sinds 1987"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over de reorganisaties bij Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Toen ik bij Houdijk kwam in de jaren 80 ging het niet al te goed met het bedrijf. Er was te weinig werk. Om dan toch maar werk te krijgen, deden we opdrachten die eigenlijk te moeilijk voor ons waren of voor een bedrag dat eigenlijk te laag was. We zaten toen echt in een neerwaartse spiraal. Ik heb toen een reorganisatie aangekondigd en besloten om ons alleen nog maar te richten op de biscuitactiviteiten en af te stappen van elke vorm van eigen fabricage. Het denkproces daaromheen heeft wel een aantal jaartjes geduurd. Mijn gedachten vormden zich gaandeweg en uiteindelijk heb ik drie keer gereorganiseerd. Eerst twintig man eruit, toen nog eens een keer dertig man eruit en daarna nog tien. Uiteindelijk heb ik jammer genoeg wel ruim vijftig tot zestig man moeten ontslaan. Dat was zeker een emotionele tijd. Ondanks de omstandigheden hebben veel mensen me bedankt voor hun tijd bij Houdijk. Het waren allemaal vakmensen en die hebben allemaal weer werk gevonden. De meesten konden voortijdig al elders aan de slag. Op het kleinst waren we met nog maar 35 man. Dat was na de laatste reorganisatie, rond 2000. Ik kan het me haast niet meer voorstellen. Dat waren alles bij elkaar een man of tien à vijftien in de engineering, tien in de werkplaats en de rest was verkoop en administratieve taken. Daar kijk ik wel met weemoed op terug.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bart Houdijk"])}
      },
      {
        "category": 4,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales Assistent en Directie Secretaresse - in dienst sinds 1999"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over de reorganisaties bij Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Er zijn dagen geweest dat ik naar de zaak ging en niet wist of er een rood-wit lintje op de deur zat. Dat was op het moment dat het heel slecht ging. Bart riep iedereen bij elkaar in de kantine en vertelde dat het erop of eronder was. Ik had gehoord dat er mensen uit gingen en ik was er als laatste bijgekomen, dus ik was bang dat ik eruit zou moeten. Heel spannend. Ik vraag me nog steeds af of iedereen zich dat goed heeft gerealiseerd toen. Dat was een heel kritiek punt. Ik denk dat we toen allemaal wel wat moeite hadden met de vasthoudendheid van Bart aan de koekjes. Hij wilde geen zijsprongetjes maken naar machines voor bijvoorbeeld hondenbrokken of hamburgers, terwijl we daarvoor wel werden gevraagd. Maar als je dan de achterliggende redenen hoorde, zoals de schoonmaakbaarheid van machines met vlees, dan heb je met hele andere hygiëne-eisen te maken dan met het stof van droge koekjes. Toen waren we daar nog niet mee bezig. Bart bleef vasthouden aan zijn koers en is met Wim van der Schee naar Amerika gegaan. Daar hebben ze kort na elkaar twee grote opdrachten binnengehaald. Ik denk dat iedereen uiteindelijk de ideeën van Bart wel omarmd heeft. Er zijn zelfs in de moeilijke tijden géén mensen weggegaan omdat ze hem niet meer vertrouwden.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marlies Overbeek"])}
      },
      {
        "category": 4,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teamleider Assembly - in dienst sinds 1979"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over de reorganisaties bij Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“We hebben een paar reorganisaties gehad en dat is natuurlijk wel vervelend geweest. Ik heb er nu drie meegemaakt en dat waren niet de leukste tijden. Bart wachtte zo lang mogelijk, tot het water hem aan de lippen stond. Toen het echt niet meer ging, was het een kwestie van last in, first out. Daar hadden wij niet heel veel invloed op. Nu gaat het gelukkig allemaal goed en ik hoop dat het zo door mag gaan.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hugo Sluimer"])}
      },
      {
        "category": 4,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In dienst tussen 1971 en 2016"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over de reorganisaties bij Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Door een samenloop van omstandigheden was er een reorganisatie nodig. Na de reorganisaties werd Houdijk een heel ander bedrijf. De mechanische afdeling is toen afgestoten. Ontwerpen en samenstellen deden we nog wel, maar vroeger maakten we alles zelf. Het plaatwerk, constructiewerk, frees- en draaiwerk, de hele machine werd hier in huis gefabriceerd. De collega's die moesten vertrekken, hebben we zoveel mogelijk onder kunnen brengen bij collega-bedrijven. Ook in moeilijke periodes daarna hebben we onze mensen ergens anders onder kunnen brengen.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jan Joost Hooimeijer"])}
      },
      {
        "category": 4,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigenaar en Directeur - in dienst sinds 1987"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over de naam Houdijk Holland"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Ik vond 'Machinefabriek Houdijk' zo oubollig klinken, dus op een gegeven moment heb ik de handelsnaam veranderd. Ik weet nog dat er in Vlaardingen in de jaren 90 het bedrijf Fontein Holland bestond, dat klonk altijd wel goed en had iets internationaals. Toen werden wij dus Houdijk Holland en dat allitereert ook nog.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bart Houdijk"])}
      },
      {
        "category": 4,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teamleider Commissioning - in dienst sinds 1987"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over de eerste opdracht in Noord-Amerika"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Toen ik net begon, waren we nog vooral in Europa bezig. Ik weet nog goed dat ze toen ik nog maar net een half jaar hier werkte aan me vroegen: 'Heb je weleens gevlogen?' Nee, dat had ik niet. 'Nou, dan kan je morgen vliegen.' Toen ging ik voor een aantal projecten naar Engeland. In 1989 gingen we voor de eerste keer overzee naar Amerika. Ik werkte hier toen eigenlijk ook nog maar net, maar was toch een van de gelukkigen die daarheen mocht. Ik ging toen naar Chicago. Wij werden daar welkom geheten door de klant, een echt familiebedrijf. Ze namen ons mee, zorgden dat we een huurauto kregen en regelden het hotel en het werk in de fabriek. Uiteindelijk hebben we daar vijf weken gezeten, van het plaatsen tot gereed maken. Dat was wel echt een ervaring.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edward van Oostende"])}
      },
      {
        "category": 4,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercieel Directeur - in dienst sinds 2005"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over opdrachten in Amerika"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“We zijn struikelend gestart, maar toen kwam een grote Amerikaanse biscuitfabrikant met een herinvesteringsprogramma waarmee ze wereldwijd hun productielijnen wilden optimaliseren. Daarvoor waren ze vooral in gesprek met de wat grotere verpakkingsbedrijven waarvan ze dachten dat die dat waar konden maken, maar dat konden ze niet. Op een gegeven moment werden wij uitgenodigd en ben ik met Bart naar Zwitserland gegaan. Daar zaten we met een delegatie van mensen die intussen weer weggingen en het idee heerste dat wij te klein waren om het te kunnen. Daar waren Bart en ik ook al op ingesteld en we hebben geconcludeerd dat we zeker niet klein waren. We zijn de grootste op ons gebied. Daarvan hebben we ze na vele gesprekken uiteindelijk toch overtuigd. Zo hebben we daar onder andere de Oreo-lijnen binnengehaald. Door die opdracht hebben we veel kracht gekregen om te investeren, wat we ook gedaan hebben. Door dat succes is een soort sneeuwbaleffect ontstaan. Als we vertellen dat wij aan de grote partijen leveren, dan werkt dat wel bij andere fabrikanten. In dat hele traject heb ik in één jaar misschien wel vier of vijf keer tussen China en Mexico moeten vliegen. Mijn vrouw gaf me dan op de luchthaven een koffertje met wat nieuwe kleding en dan kon ik weer verder. Dat is ook verkopen.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marnus Rotte"])}
      },
      {
        "category": 4,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oud-medewerker Verkade/United-Biscuits"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over de relatie tussen Verkade en Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Ik moet even diep in mijn geheugen graven of we ooit een concurrent van Houdijk hebben ingehuurd. Volgens mij weleens, maar de waarheid is dat dat nooit zo goed bevallen is. Bij Verkade hebben we hele slechte financiële periodes gehad en moesten we op de centen letten. Dan ging je maar voor het goedkoopste, maar dat was dan niet het beste. We hebben ook weleens wat gekocht bij anderen, puur vanwege de levertijden van Houdijk. Omdat het de afgelopen jaren zo goed ging, waren de levertijden ook lang en daar konden wij niet op wachten. Daarover hadden we wel de pest in, want we wilden graag met Houdijk in zee. Als het kon, had er overal Houdijk gestaan, daar ben ik van overtuigd.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerard Langver"])}
      },
      {
        "category": 4,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur SNL Group Schijndel, toeleverancier Houdijk"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over de kwaliteiten van Bart Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Bart haalt volgens mij vooral de voldoening uit het professioneler worden, het neerzetten van een goed resultaat, niet alleen in geld maar ook in een mooie lijn en het goed bedienen van zijn klant. Hij is iemand die continu verbetert, dat deel ik met hem. We halen er voldoening uit als je ziet wat er in één jaar tijd bijgekomen is en hoe de zaak zich ontwikkeld heeft. Het is het zaadje planten en vervolgens zien groeien. Ik zeg altijd 'als je je te veel bemoeit met het plantje, gaat het kapot, ga je er met een paraplu boven staan, groeit het ook niet.' Dat doet Bart altijd wel goed. Hij is heel resultaatgericht, maar wel redelijk en menselijk.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frans Lammers"])}
      },
      {
        "category": 4,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigenaar en Directeur - in dienst sinds 1987"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over de ontwikkeling van Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Mijn vader is begonnen met de productielijnen voor veel koekjes per minuut, maar wel allemaal van één type. Rond 2010 is daar de Capper bij gekomen, de sandwichmachines. Dat was ook voor de biscuitindustrie, dus dezelfde klanten, dezelfde markt. Sinds 2017 zijn daar ook de robotsystemen bij gekomen. Het voordeel van die robots is veel meer flexibiliteit natuurlijk, maar de aantallen per minuut liggen wel veel lager. De kracht zit juist in de flexibiliteit. Klanten hoeven soms niet eens snellere machines. Die willen bij wijze van spreken 's ochtends gevulde koeken, 's middags roze koeken en 's avonds kano's produceren. Dat kan met dezelfde robots zonder veel ombouwtijd. Zeker in de robotlijnen zie ik de komende jaren veel potentieel. Wij maakten al robotachtige machines, geen robots in de pure zin van het woord, maar robotachtig. De stap van robotachtig naar robot is betrekkelijk klein en we zijn een slim bedrijf, dus wij kunnen vrij snel schakelen. In de hoog-volumemarkt en met de Capper zitten we echt aan de top van de markt en kunnen we wel stellen dat dit het beste is dat er is. Bij de robots kunnen we dat niet doen, want daar hebben we stevige concurrentie. Concurrentie waar wij nog niet aan kunnen tippen, maar dat is een kwestie tijd.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bart Houdijk"])}
      },
      {
        "category": 4,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercieel Directeur - in dienst sinds 2005"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over de ontwikkeling van Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Ik ben technisch van achtergrond, maar dat wil niet zeggen dat ik daarom technisch slim ben. Jo Brand toetst alle engineers hier. Ik zou faliekant falen, maar ik heb wel een boerengevoel bij wat moet kunnen. Bart is eigenlijk jurist, maar ik kan je wel vertellen dat hij zich technisch geen stoom laat blazen. Hij kan het misschien niet berekenen, maar hij ziet mogelijkheden in wat moet kunnen. Sommigen verzinnen zó tien redenen waarom het niet zou kunnen werken en Bart stelt dan dat dat een wel heel snel antwoord is. 'Denk er nog maar eens twee dagen over na en dan kijken we of er toch een kans ligt.' De markt vraagt je grensverleggend te zijn. De rode lijn in ons programma zijn de Telinmatic en Gradomatic geweest. De eerste Telinmatic draaide op ongeveer driehonderd beschuitjes per minuut. Wij draaien nu drieduizend Oreo'tjes per minuut. Dus als je gebleven was bij dat eerste beschuitje en daar niks aan had veranderd, had je nu een T-Ford die niemand meer wil.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marnus Rotte"])}
      },
      {
        "category": 4,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercieel Directeur - in dienst sinds 2005"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over de ontwikkeling van Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Geluk ontstaat door heel hard te werken. Je wordt beloond voor het harde werken, het valt nooit zomaar bij iemand op schoot. Bart is heel bedachtzaam bezig met het bedrijf én met de mensen. Dat is ook wel een kwaliteit die hij heeft. Hij weet de juiste mensen om zich heen te verzamelen die hem trouw zijn en graag met hem werken. Ik werk ook graag met hem, want hij is een hele goede ambassadeur van zijn eigen bedrijf. Een aimabele man om het maar zo te zeggen, maar wel met een hele heldere visie. Toen we nog klein waren en weinig financiële speelruimte hadden, konden we weinig doen en moest alles in kleine stapjes. Op het moment dat het in grotere stappen gaat lopen, kunnen we ook grotere sprongen vooruit maken. Dat gaat de laatste vijf tot tien jaar heel hard.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marnus Rotte"])}
      },
      {
        "category": 4,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oud-medewerker Verkade/United-Biscuits"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over de ontwikkeling van Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het is heel moeilijk om je te specialiseren in de koekjesmarkt en biscuithandling. Het klinkt misschien stom, koekjes transporteren, maar om daar wereldwijd een van de belangrijkste spelers in te worden is natuurlijk hartstikke knap. Er zal heus weleens aan Bart zijn getwijfeld, maar het is wel echt knap van hem en de mensen van Houdijk. Ze hebben nu zo'n mooi en groot pand. Het ziet er keurig uit. Ik heb vlak voordat ik met pensioen ging nog een groot project met Houdijk gedaan. Dat is hartstikke goed verlopen, dus dat was voor mij een prachtige afsluiting.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerard Langver"])}
      },
      {
        "category": 4,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ICT Manager - in dienst sinds 2015"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over de flexibiliteit van Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Het leuke van dit bedrijf vind ik dat het nog steeds wordt aangestuurd als een klein bedrijf. Dat heeft soms voordelen en soms nadelen, maar het is wel heel flexibel en de lijntjes zijn kort. Als we besluiten andere technieken toe te passen, dan doen we dat gewoon. In een heel groot bedrijf gaat het allemaal wat star en is het niet makkelijk om je aan te passen aan iets anders. Ik denk dat iedereen hier daar innovatief genoeg voor is. Ik hoop dat we de komende honderd jaar nog zullen blijven bestaan.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaap Tempelman"])}
      },
      {
        "category": 4,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technology Advisor - in dienst sinds 1988"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over wat Houdijk onderscheidt van anderen"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wij ontwerpen mooie machines met een enorm hoge capaciteit en die kunnen daardoor best groot worden. De klant moet die grote systemen ook schoonmaken. Natuurlijk doen wij ons best om ieder onderdeel zo te ontwerpen dat het zo goed mogelijk schoon te maken is, maar wanneer dat tijdens de productie moet gebeuren, kan er paniek ontstaan. De chef staat te brullen dat de productie door moet gaan en dat de operator dit snel moet oplossen. Je ziet dan soms dat mensen over de machine heen lopen om bij een bepaald punt te komen. Dat is natuurlijk niet de bedoeling, maar wel de praktijk. Doordat we juist daar rekening mee houden, onderscheiden we ons van veel concurrenten. <br>Wij denken niet alleen na over hoe een koekje kan worden verwerkt of voor problemen zou kunnen zorgen, maar ook over hoe het systeem wordt gebruikt en soms wordt misbruikt door het bedienend personeel. Dat is iets waar we ons echt in onderscheiden. Daar doen we veel moeite voor en die kwaliteit mag geld kosten. We willen dat de klant een goed product koopt en later bij ons terugkomt.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jo Brand"])}
      },
      {
        "category": 4,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technology Advisor - in dienst sinds 1988"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over de toekomst van Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Ik denk dat we zeker een toekomst hebben zoals we nu bezig zijn. We leveren goede kwaliteit toegespitst op de bakkerijwereld en we denken na over het schoonmaken, het onderhoud, kosten op langere termijn, verdere ontwikkelingen, noem maar op. Dat zijn punten waarop we de bakker aanvullen en er zijn weinig bedrijven die dat als specialisme hebben. Daar zit nog genoeg in voor de toekomst. Er ontstaan ook steeds nieuwe markten in landen waar het welvaartsniveau stijgt en er wordt geautomatiseerd.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jo Brand"])}
      },
      {
        "category": 4,
        "job_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager Operations - in dienst sinds 2015"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over de toekomst van Houdijk"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“De robots die we nu maken zijn de toekomst. Dat is misschien een breuk met het conventionele, maar dat is wel waar de klant behoefte aan heeft. Vooral voor de kleinere bakkerijen bieden ze veel flexibiliteit. Uiteindelijk maken we er ook hoog-volumelijnen van door gewoon meerdere rijen met robots neer te zetten, maar dat kan qua verwerkingsaantallen niet echt concurreren met de dedicated lijnen. Ik geloof dat we wel meer kunnen met robots. Het is ook niet ondenkbaar dat we daar ook zelfs een andere markt mee aan kunnen boren, want het is een bepaalde techniek die je op veel andere producten kunt toepassen. Het flexibele ervan, dat is de toekomst.”"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["John Kasbergen"])}
      }
    ]
  }
}