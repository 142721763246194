
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

import DefaultLayout from '@/layouts/Default.vue';

export default defineComponent({
    name: 'PrivacyView',
    components: {
        DefaultLayout,
    },
    setup() {
        const { t } = useI18n();

        return {
            t,
        };
    },
});
