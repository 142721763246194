
import { defineComponent, onMounted, onUnmounted } from 'vue';
import Pusher, { Channel } from 'pusher-js';
import { useI18n } from 'vue-i18n';

import NoNavigationLayout from '@/layouts/NoNavigation.vue';

export default defineComponent({
    name: 'RemoteControl',
    components: {
        NoNavigationLayout,
    },
    setup() {
        const { t } = useI18n();

        let pusher: Pusher | null = null;
        let channel: Channel | null = null;

        onMounted(() => {
            if (!process.env.VUE_APP_PUSHER_KEY || !process.env.VUE_APP_PUSHER_CLUSTER) return;

            pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
                cluster: process.env.VUE_APP_PUSHER_CLUSTER,
                authEndpoint: '/broadcasting/authenticate',
            });

            channel = pusher.subscribe('private-remote');

            setTimeout(() => {
                if (channel) {
                    channel.trigger('client-start-presentation', 'start');
                }
            }, 1000);
        });

        onUnmounted(() => {
            if (channel) {
                channel.unbind();
                channel.unsubscribe();
                channel = null;
            }

            if (pusher) {
                pusher.disconnect();
                pusher = null;
            }
        });

        const trigger = (name: string) => {
            if (!channel) return;

            channel.trigger('client-keypress', name);
        };

        return {
            t,
            trigger,
        };
    },
});
