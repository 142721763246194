
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import useTimeline from '@/composables/timeline';

export default defineComponent({
    name: 'TimelineFilter',
    setup() {
        const { categories, activeCategoryId, activeCategory } = useTimeline();
        const { t, rt } = useI18n();
        const isFilterOpen = ref<boolean>(false);

        const handleClick = (categoryId: number) => {
            activeCategoryId.value = categoryId;
            isFilterOpen.value = false;
        };

        return {
            activeCategory,
            activeCategoryId,
            categories,
            handleClick,
            isFilterOpen,
            rt,
            t,
        };
    },
});
