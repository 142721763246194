
import { defineComponent, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import useTimeline, { ITimelineItem } from '@/composables/timeline';

export default defineComponent({
    name: 'TimelineItemDetail',
    setup() {
        const { categories } = useTimeline();
        const { currentRoute } = useRouter();
        const { tm, rt, t } = useI18n();

        const item = computed(() => {
            const items = tm('items') as ITimelineItem[];

            return items.find(({ id }) => id === +currentRoute.value.params.id);
        });

        const category = computed(() => {
            const categoryId = item.value?.category;

            return categories.value.find(({ id }) => id === categoryId);
        });

        return {
            category,
            item,
            rt,
            t,
        };
    },
});
