import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "max-w-[764px] p-8 w-full" }
const _hoisted_2 = { class: "md:aspect-w-2 md:aspect-h-[1.1] relative pt-48 bg-white shadow-xl md:pt-0" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "!left-auto md:!w-1/2 z-40 right-0 flex flex-col p-4 h-full md:p-6 lg:p-8" }
const _hoisted_5 = {
  key: 0,
  class: "line-clamp-1 block mb-2 text-secondary text-base"
}
const _hoisted_6 = { class: "line-clamp-3 mb-3" }
const _hoisted_7 = { class: "!overflow-hidden mb-3 sr-only md:not-sr-only line-clamp-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("figure", {
        class: _normalizeClass([
                    { '!h-48 md:!h-full md:!w-1/2': _ctx.isActive },
                    'absolute top-0 left-0 w-full h-full transition-all z-50 bg-gray-500',
                ])
      }, [
        (_ctx.rt(_ctx.item.image))
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.rt(_ctx.item.image),
              width: "700",
              height: "350",
              loading: "lazy",
              class: "w-full h-full object-cover",
              alt: ""
            }, null, 8, _hoisted_3))
          : _createCommentVNode("", true)
      ], 2),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.category)
          ? (_openBlock(), _createElementBlock("small", _hoisted_5, _toDisplayString(_ctx.rt(_ctx.category.title)), 1))
          : _createCommentVNode("", true),
        _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.rt(_ctx.item.title)), 1),
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.rt(_ctx.item.description)), 1),
        (!_ctx.hideButton)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 1,
              to: { name: 'TimelineItemDetail', params: { id: _ctx.item.id } },
              class: "inline-flex items-center self-start justify-center mt-auto px-6 h-9 hover:text-dark text-white bg-secondary hover:bg-tertiary rounded transition lg:h-11"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('read_more')), 1)
              ]),
              _: 1
            }, 8, ["to"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}