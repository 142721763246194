import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

interface IReturnProps {
    switchLocale: (locale: string) => string;
}

const useLocale = (): IReturnProps => {
    const { locale: currentLocale } = useI18n();
    const { currentRoute } = useRouter();

    const switchLocale = (locale: string): string => {
        const { path } = currentRoute.value;

        return path.replace(`/${currentLocale.value}`, `/${locale}`);
    };

    return {
        switchLocale,
    };
};

export default useLocale;
