import { createApp } from 'vue';
import VueClickAway from 'vue3-click-away';

import '@/assets/scss/app.scss';
import App from '@/App.vue';
import Icon from '@/components/Icon.vue';
import router from '@/router';
import i18n from '@/i18n';

const app = createApp(App);

app.use(i18n);
app.use(router);
app.use(VueClickAway);

app.component('UiIcon', Icon);

app.mount('#app');
