import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cc1d83ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "max-w-[412px] z-[60] fixed bottom-0 right-0 top-0 flex flex-col w-full bg-tertiary"
}
const _hoisted_2 = { class: "header-height flex items-center px-4 lg:px-8" }
const _hoisted_3 = { class: "flex flex-col flex-grow p-6 px-12 lg:p-20" }
const _hoisted_4 = {
  key: 0,
  class: "text-[10px] absolute -left-6 top-1/2 block transform -translate-y-1/2"
}
const _hoisted_5 = { class: "flex items-center p-6 pt-0 px-12 lg:p-20 lg:pt-0" }
const _hoisted_6 = { class: "flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_icon = _resolveComponent("ui-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_Transition, {
      name: "navigation",
      appear: ""
    }, {
      default: _withCtx(() => [
        (_ctx.isMenuOpen)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("button", {
                  class: "flex items-center ml-auto h-10 text-xs font-bold uppercase",
                  type: "button",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleMenu && _ctx.toggleMenu(...args)))
                }, [
                  _createTextVNode(_toDisplayString(_ctx.t('close')) + " ", 1),
                  _createVNode(_component_ui_icon, {
                    name: "close",
                    class: "ml-2 text-2xl"
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (menuItem) => {
                  return (_openBlock(), _createBlock(_component_router_link, {
                    key: menuItem.to,
                    to: menuItem.to,
                    class: "relative block mb-6 text-2xl",
                    "exact-active-class": "font-bold",
                    onClick: _ctx.toggleMenu
                  }, {
                    default: _withCtx(({ isExactActive }) => [
                      isExactActive
                        ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                            _createVNode(_component_ui_icon, { name: "arrow-right" })
                          ]))
                        : _createCommentVNode("", true),
                      _createTextVNode(" " + _toDisplayString(_ctx.t(menuItem.title)), 1)
                    ]),
                    _: 2
                  }, 1032, ["to", "onClick"]))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("ul", _hoisted_6, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableLocales, (locale) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: locale,
                      class: "language-switch-item after:flex flex after:items-center items-center after:justify-center after:w-4"
                    }, [
                      _createVNode(_component_router_link, {
                        to: _ctx.switchLocale(locale),
                        class: "uppercase",
                        "active-class": "font-bold",
                        onClick: _ctx.toggleMenu
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(locale), 1)
                        ]),
                        _: 2
                      }, 1032, ["to", "onClick"])
                    ]))
                  }), 128))
                ]),
                _createVNode(_component_router_link, {
                  to: { name: 'Disclaimer' },
                  "exact-active-class": "font-bold",
                  class: "ml-auto",
                  onClick: _ctx.toggleMenu
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('disclaimer')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, {
      name: "fade",
      appear: ""
    }, {
      default: _withCtx(() => [
        (_ctx.isMenuOpen)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "fixed z-50 inset-0 bg-black bg-opacity-90",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleMenu && _ctx.toggleMenu(...args)))
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}