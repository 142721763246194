
import { defineComponent, PropType, computed } from 'vue';
import { useI18n } from 'vue-i18n';

import useTimeline, { ITimelineItem } from '@/composables/timeline';

export default defineComponent({
    name: 'TimelineItem',
    props: {
        item: {
            type: Object as PropType<ITimelineItem>,
            required: true,
        },
        isActive: {
            type: Boolean as PropType<boolean>,
            required: true,
        },
        hideButton: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
    },
    setup(props) {
        const { rt, t } = useI18n();
        const { categories } = useTimeline();

        const category = computed(() => {
            const categoryId = props.item.category;

            return categories.value.find(({ id }) => id === categoryId);
        });

        return {
            category,
            rt,
            t,
        };
    },
});
