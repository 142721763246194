
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

import useLocale from '@/composables/locale';
import useNavigation from '@/composables/navigation';

export default defineComponent({
    name: 'NavigationView',
    setup() {
        const { t, availableLocales } = useI18n();
        const { toggleMenu, isMenuOpen } = useNavigation();
        const { switchLocale } = useLocale();

        const menuItems = [
            {
                title: 'timeline',
                to: { name: 'Timeline' },
            },
            {
                title: 'memories',
                to: { name: 'Memories' },
            },
            {
                title: 'about',
                to: { name: 'About' },
            },
        ];

        return {
            availableLocales,
            isMenuOpen,
            menuItems,
            switchLocale,
            t,
            toggleMenu,
        };
    },
});
