
import { defineComponent } from 'vue';

import Navbar from '@/components/Navbar.vue';

export default defineComponent({
    name: 'NoNavigationLayout',
    components: {
        Navbar,
    },
});
