import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  tabindex: "-1",
  class: "absolute z-50 left-0 right-0 top-0 flex flex-col justify-center 2xl:p-12 p-3 w-full min-h-screen lg:p-6"
}
const _hoisted_2 = { class: "ui-modal-box max-w-[700px] relative z-10 flex flex-col flex-grow mx-auto w-full rounded lg:flex-grow-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_icon = _resolveComponent("ui-icon")!

  return (_openBlock(), _createBlock(_Teleport, { to: "#modals" }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        class: "fixed left-0 top-0 w-full h-full bg-black bg-opacity-80",
        type: "button",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setModal('')))
      }),
      (_ctx.closable)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            "aria-label": "Close",
            class: "absolute z-20 right-6 top-6 flex items-center justify-center w-11 h-11 hover:text-dark text-white bg-secondary hover:bg-tertiary rounded transition lg:right-10 lg:top-10",
            type: "button",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setModal('')))
          }, [
            _createVNode(_component_ui_icon, {
              name: "close",
              class: "text-2xl"
            })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ])
  ]))
}