import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "min-h-[calc(100vh-76px)] md:min-h-[calc(100vh-96px)] bg-gradient-to-r from-primary to-secondary" }
const _hoisted_2 = { class: "max-w-[900px] mx-auto p-4 text-white md:px-6 md:py-8 lg:py-16" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_navbar = _resolveComponent("navbar")!
  const _component_navigation = _resolveComponent("navigation")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_navbar),
    _createVNode(_component_navigation),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ])
  ], 64))
}