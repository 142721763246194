import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f74b58ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-swiper-parallax"]
const _hoisted_2 = { class: "absolute bottom-6 left-0 w-full" }
const _hoisted_3 = { class: "line-clamp-3 !overflow-hidden block text-center text-xl sr-only lg:not-sr-only" }
const _hoisted_4 = { class: "line-clamp-1 block mt-auto text-center font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_navbar = _resolveComponent("navbar")!
  const _component_navigation = _resolveComponent("navigation")!
  const _component_timeline_filter = _resolveComponent("timeline-filter")!
  const _component_TimelineItem = _resolveComponent("TimelineItem")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_navbar, { "hide-menu": _ctx.isPresentationMode }, null, 8, ["hide-menu"]),
    _createVNode(_component_navigation),
    _createElementVNode("div", {
      class: _normalizeClass([
                'min-h-[calc(100vh-76px)] md:min-h-[calc(100vh-96px)] flex items-center',
                _ctx.currentBackgroundColor,
            ])
    }, [
      (!_ctx.isPresentationMode)
        ? (_openBlock(), _createBlock(_component_timeline_filter, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_swiper, {
        controller: {
                    control: _ctx.timelineSwiper,
                },
        "slides-per-view": 1,
        breakpoints: {
                    768: {
                        slidesPerView: 1.25,
                    },
                    1280: {
                        slidesPerView: 1.5,
                    },
                    1920: {
                        slidesPerView: 2,
                    },
                },
        threshold: 5,
        "centered-slides": true,
        "slide-to-clicked-slide": true,
        parallax: true,
        class: "flex-grow mb-8 md:mb-12",
        onSwiper: _cache[0] || (_cache[0] = ($event: any) => (_ctx.slidesSwiper = $event)),
        onSlideChange: _ctx.handleSlideChange
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: "/images/bg.jpg",
            width: "5590",
            height: "1200",
            class: "fixed left-0 top-0 w-auto max-w-none h-screen",
            alt: "",
            "data-swiper-parallax": -_ctx.parallaxDistance
          }, null, 8, _hoisted_1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredItems, (item) => {
            return (_openBlock(), _createBlock(_component_swiper_slide, {
              key: `slide-${item.id}-${_ctx.locale}`,
              class: "flex items-center justify-center"
            }, {
              default: _withCtx((slideProps) => [
                _createVNode(_component_TimelineItem, {
                  item: item,
                  "is-active": slideProps.isActive,
                  "hide-button": _ctx.isPresentationMode
                }, null, 8, ["item", "is-active", "hide-button"])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["controller", "breakpoints", "onSlideChange"])
    ], 2),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_swiper, {
        controller: {
                    control: _ctx.slidesSwiper,
                },
        "slides-per-view": 2,
        breakpoints: {
                    768: {
                        slidesPerView: 3,
                    },
                },
        "centered-slides": true,
        "slide-to-clicked-slide": true,
        threshold: 5,
        onSwiper: _cache[1] || (_cache[1] = ($event: any) => (_ctx.timelineSwiper = $event))
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredItems, (item, index) => {
            return (_openBlock(), _createBlock(_component_swiper_slide, {
              key: `slide-${index}`,
              class: "swiper-slide-timeline flex justify-center"
            }, {
              default: _withCtx((slideProps) => [
                _createElementVNode("div", {
                  class: _normalizeClass([
                            'relative text-center h-20 md:h-24 flex flex-col items-center',
                            slideProps.isActive ? 'text-tertiary' : 'text-white',
                        ])
                }, [
                  _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.rt(item.title)), 1),
                  _createElementVNode("span", {
                    class: _normalizeClass([
                                'absolute top-[calc(50%+4px)] left-1/2 z-10 -translate-y-1/2 -translate-x-1/2 block w-5 h-5 rounded-full',
                                slideProps.isActive ? 'bg-tertiary' : 'bg-light',
                            ])
                  }, null, 2),
                  _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.rt(item.year)), 1)
                ], 2)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["controller"])
    ])
  ]))
}