import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import About from '@/views/About.vue';
import Disclaimer from '@/views/Disclaimer.vue';
import Memories from '@/views/Memories.vue';
import Privacy from '@/views/Privacy.vue';
import RemoteControl from '@/views/RemoteControl.vue';
import RouterView from '@/views/RouterView.vue';
import Timeline from '@/views/Timeline.vue';
import TimelineItemDetail from '@/views/TimelineItemDetail.vue';
import TimelinePresentation from '@/views/TimelinePresentation.vue';

import i18n from '@/i18n';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: `/${process.env.VUE_APP_I18N_LOCALE || 'nl'}`,
    },
    {
        path: '/:lang(en|nl)',
        component: RouterView,
        children: [
            {
                path: '',
                name: 'Timeline',
                component: Timeline,
                meta: {
                    keepAlive: true,
                },
            },
            {
                path: 'presentation',
                name: 'TimelinePresentation',
                component: TimelinePresentation,
            },
            {
                path: 'timeline/:id',
                name: 'TimelineItemDetail',
                component: TimelineItemDetail,
            },
            {
                path: 'about',
                name: 'About',
                component: About,
            },
            {
                path: 'disclaimer',
                name: 'Disclaimer',
                component: Disclaimer,
            },
            {
                component: Memories,
                name: 'Memories',
                path: 'memories',
            },
            {
                path: 'privacy',
                name: 'Privacy',
                component: Privacy,
            },
            {
                component: RemoteControl,
                name: 'RemoteControl',
                path: 'remote',
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    const currentLocale = to.params.lang;

    if (currentLocale && typeof currentLocale === 'string') {
        i18n.global.locale.value = currentLocale;

        document.querySelector('html')?.setAttribute('lang', currentLocale);
    }

    return next();
});

export default router;
