
import { defineComponent, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { IMemoryItem } from '@/types/memories.interfaces';

import useTimeline from '@/composables/timeline';

import ContentWideLayout from '@/layouts/ContentWide.vue';
import TimelineFilter from '@/components/TimelineFilter.vue';
import UiModal from '@/components/Modal.vue';
import useModal from '@/composables/modal';

export default defineComponent({
    name: 'MemoriesView',
    components: {
        ContentWideLayout,
        TimelineFilter,
        UiModal,
    },
    setup() {
        const { filteredMemories } = useTimeline();
        const { setModal, modalName } = useModal();
        const { t, rt } = useI18n();
        const activeItem = ref<IMemoryItem[] | null>(null);
        const activeIndex = ref(0);

        const changeActiveModalItem = (delta: number) => {
            if (activeItem.value) {
                const newIndex: number = activeIndex.value + delta;

                activeIndex.value = newIndex;
                activeItem.value = Object.values(filteredMemories.value)[newIndex];
            }
        };

        const triggerKeyboardNavigation = (e: KeyboardEvent) => {
            if (e.key === 'ArrowLeft') {
                if (activeIndex.value > 0) {
                    changeActiveModalItem(-1);
                }
            }

            if (e.key === 'ArrowRight') {
                if (activeIndex.value < Object.values(filteredMemories.value).length - 1) {
                    changeActiveModalItem(+1);
                }
            }
        };

        onMounted(() => {
            document.addEventListener('keyup', triggerKeyboardNavigation);
        });

        return {
            activeItem,
            activeIndex,
            changeActiveModalItem,
            filteredMemories,
            modalName,
            rt,
            setModal,
            t,
        };
    },
});
