
import { defineComponent, onMounted, onUnmounted, PropType, ref } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue'; // eslint-disable-line import/no-unresolved
import { useI18n } from 'vue-i18n';
import SwiperCore, { Parallax, Controller } from 'swiper';

import useTimeline from '@/composables/timeline';

import Navbar from '@/components/Navbar.vue';
import Navigation from '@/components/Navigation.vue';
import TimelineFilter from '@/components/TimelineFilter.vue';
import TimelineItem from '@/components/TimelineItem.vue';

SwiperCore.use([Parallax, Controller]);

export default defineComponent({
    name: 'TimelinePage',
    components: {
        Navbar,
        Navigation,
        Swiper,
        SwiperSlide,
        TimelineFilter,
        TimelineItem,
    },
    props: {
        isPresentationMode: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
    },
    setup() {
        const { filteredItems, slidesSwiper, timelineSwiper } = useTimeline();
        const { rt, t, locale } = useI18n();
        const currentBackgroundColor = ref<string>('bg-primary');
        const parallaxDistance = ref(0);

        const updateParallaxDistance = () => {
            parallaxDistance.value = (8386 / 1800) * window.innerHeight - window.innerWidth;
        };

        const handleSlideChange = () => {
            if (!slidesSwiper.value) return;

            currentBackgroundColor.value =
                slidesSwiper.value.activeIndex > 0 ? 'bg-secondary' : 'bg-primary';
        };

        const resetControllers = () => {
            if (!slidesSwiper.value || !timelineSwiper.value) return;

            slidesSwiper.value.controller.control = timelineSwiper.value;
            timelineSwiper.value.controller.control = slidesSwiper.value;
        };

        onMounted(() => {
            window.addEventListener('resize', updateParallaxDistance);

            resetControllers();
            updateParallaxDistance();
            handleSlideChange();
        });

        onUnmounted(() => {
            window.removeEventListener('resize', updateParallaxDistance);
        });

        return {
            currentBackgroundColor,
            filteredItems,
            handleSlideChange,
            locale,
            parallaxDistance,
            rt,
            slidesSwiper,
            t,
            timelineSwiper,
        };
    },
});
