import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "absolute z-10 top-[96px] left-0 md:top-0 w-full" }
const _hoisted_2 = { class: "underline" }
const _hoisted_3 = {
  key: 0,
  class: "md:top-[96px] md:absolute left-0 z-10 flex flex-col items-center justify-center w-full px-4 lg:px-8 md:mt-9"
}
const _hoisted_4 = { class: "text-[20px] text-white leading-normal font-bold" }
const _hoisted_5 = {
  key: 0,
  class: "top-[40px] md:top-[96px] absolute z-20 left-0 flex px-4 py-4 w-full bg-tertiary lg:px-8 lg:py-9"
}
const _hoisted_6 = { class: "flex-shrink-0 mr-2 font-bold leading-6 md:mr-6 xl:mr-8" }
const _hoisted_7 = { class: "flex flex-wrap gap-x-6 gap-y-1 xl:gap-x-8" }
const _hoisted_8 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_icon = _resolveComponent("ui-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "group md:top-[96px] md:absolute z-20 left-0 w-full flex items-center justify-center md:justify-end px-4 lg:px-8 md:mt-9 text-tertiary font-bold leading-normal",
      type: "button",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isFilterOpen = true))
    }, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.t('filter')), 1),
      _createVNode(_component_ui_icon, {
        name: "arrow-down",
        class: "group-hover:translate-y-[2px] ml-2 pt-0.5 text-sm transition-transform"
      })
    ]),
    (_ctx.activeCategoryId)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.rt(_ctx.activeCategory?.title)), 1),
          (_ctx.$route.name !== 'Memories')
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: { name: 'Memories' },
                class: "mt-3 leading-normal text-tertiary underline"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('memories_navigate')), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.isFilterOpen)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.t('show')) + ": ", 1),
              _createElementVNode("div", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category) => {
                  return (_openBlock(), _createElementBlock("button", {
                    key: category.id,
                    class: "flex gap-1 items-center md:gap-2 text-left",
                    type: "button",
                    onClick: ($event: any) => (_ctx.handleClick(category.id))
                  }, [
                    _createElementVNode("span", {
                      class: _normalizeClass([
                                'w-6 h-6 rounded-full border-4 flex-shrink-0 border-white',
                                `${_ctx.activeCategoryId === category.id ? 'bg-secondary' : 'bg-white'}`,
                            ])
                    }, null, 2),
                    _createTextVNode(" " + _toDisplayString(_ctx.rt(category.title)), 1)
                  ], 8, _hoisted_8))
                }), 128))
              ]),
              _createElementVNode("button", {
                class: "flex flex-shrink-0 items-center justify-center ml-auto w-6 h-6 text-2xl",
                type: "button",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isFilterOpen = false))
              }, [
                _createVNode(_component_ui_icon, { name: "close" })
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}