
import { defineComponent } from 'vue';

import Navbar from '@/components/Navbar.vue';
import Navigation from '@/components/Navigation.vue';

export default defineComponent({
    name: 'ContentWideLayout',
    components: {
        Navbar,
        Navigation,
    },
});
