
import { defineComponent, onMounted, onUnmounted } from 'vue';

import useModal from '@/composables/modal';

export default defineComponent({
    name: 'UiModal',
    props: {
        closable: {
            type: Boolean,
            default: true,
        },
    },
    setup() {
        const { setModal } = useModal();
        const siteContent = document.querySelector<HTMLElement>('.site-content');

        onMounted(() => {
            if (siteContent) {
                siteContent.style.top = `${-window.pageYOffset}px`;
            }

            document.body.classList.add('ui-modal-open');

            window.scrollTo(0, 0);

            document.addEventListener('keyup', (e) => {
                if (e.key === 'Escape') {
                    setModal('');
                }
            });
        });

        onUnmounted(() => {
            document.body.classList.remove('ui-modal-open');
            document.removeEventListener('keyup', () => setModal(''));

            if (siteContent) {
                const scrollOffset = Math.abs(parseFloat(siteContent.style.top));

                siteContent.style.removeProperty('top');
                window.scrollTo(0, scrollOffset);
            }
        });

        return {
            setModal,
        };
    },
});
