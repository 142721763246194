
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';
import Pusher, { Channel } from 'pusher-js';
import { useI18n } from 'vue-i18n';

import Timeline from '@/components/Timeline.vue';
import useTimeline from '@/composables/timeline';

export default defineComponent({
    name: 'TimelinePresentationPage',
    components: {
        Timeline,
    },
    setup() {
        const { slidesSwiper } = useTimeline();
        const { t } = useI18n();
        const showQrCode = ref(true);

        let debouncer = 0;
        let pusher: Pusher | null = null;
        let channel: Channel | null = null;

        const startTimeout = () => {
            debouncer = window.setTimeout(() => {
                window.location.reload();
            }, 30000);
        };

        const resetTimeout = () => {
            clearTimeout(debouncer);
            startTimeout();
        };

        onMounted(() => {
            if (!process.env.VUE_APP_PUSHER_KEY || !process.env.VUE_APP_PUSHER_CLUSTER) return;

            pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
                cluster: process.env.VUE_APP_PUSHER_CLUSTER,
                authEndpoint: '/broadcasting/authenticate',
            });

            channel = pusher.subscribe('private-remote');

            channel.bind('client-start-presentation', () => {
                showQrCode.value = false;
                startTimeout();
            });

            channel.bind('client-keypress', (data: string) => {
                if (!slidesSwiper.value) return;

                showQrCode.value = false;
                resetTimeout();

                switch (data) {
                    case 'next': {
                        slidesSwiper.value.slideNext();
                        break;
                    }

                    case 'previous': {
                        slidesSwiper.value.slidePrev();
                        break;
                    }

                    case 'refresh': {
                        window.location.reload();
                        break;
                    }

                    default: {
                        break;
                    }
                }
            });
        });

        onUnmounted(() => {
            if (channel) {
                channel.unbind();
                channel.unsubscribe();
                channel = null;
            }

            if (pusher) {
                pusher.disconnect();
                pusher = null;
            }
        });

        return {
            showQrCode,
            t,
        };
    },
});
